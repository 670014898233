export const LOAD_ALL = 'investment-account/LOAD_ALL';
export const LOAD_ALL_SUCCESS = 'investment-account/LOAD_ALL_SUCCESS';
export const LOAD_ALL_FAIL = 'investment-account/LOAD_ALL_FAIL';
export const CHECK_INVESTMENT = 'investment-account/CHECK_INVESTMENT';
export const CHECK_INVESTMENT_SUCCESS =
  'investment-account/CHECK_INVESTMENT_SUCCESS';
export const CHECK_INVESTMENT_FAIL = 'investment-account/CHECK_INVESTMENT_FAIL';

export function loadInvestmentAccounts() {
  return {
    types: [LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_FAIL],
    promise: client => client.get('/mkt/accounts')
  };
}

export function loadCompletedAccounts() {
  return {
    types: [LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_FAIL],
    promise: client => client.get('/mkt/accounts/completed')
  };
}

export function checkIfCanInvest(closingId) {
  return {
    types: [CHECK_INVESTMENT, CHECK_INVESTMENT_SUCCESS, CHECK_INVESTMENT_FAIL],
    promise: client => client.get(`/mkt/closings/${closingId}/can_invest`)
  };
}
