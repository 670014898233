import { Alert } from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';
import { Closing } from 'types/api/closing';
import { Investment } from 'types/api/investment';

interface Props {
  closing: Closing;
  investment: Investment;
}

const InvestmentStatusMessage = ({ closing, investment }: Props) => {
  const [message, setMessage] = useState<string | React.ReactNode>('');

  const updateMessage = () => {
    const closingInInterest = closing?.stage === 'interest';
    const closingInWaitlist = closing?.stage === 'waitlist';
    const investmentInPledge = investment?.status === 'interest';
    const investmentInCommitment = investment?.status === 'signed';

    let newMessage: string | React.ReactNode = '';

    if (closingInInterest)
      newMessage = 'Indicate interest to get priority access to invest.';

    if (investment && (closingInInterest || closingInWaitlist)) {
      if (closingInInterest) {
        if (investmentInPledge || investmentInCommitment) {
          newMessage =
            'You are now able to complete your investment. Please sign the subscription agreement and fund your investment to complete the investment process.';
        }
      } else if (closingInWaitlist) {
        newMessage = (
          <div>
            Your investment in the offering is reserved. It is{' '}
            <strong>not</strong> on the waitlist. Please complete your
            investment by funding before the deadline.
          </div>
        );
      }
    }

    setMessage(newMessage);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    updateMessage();
  }, [investment?.status]); // eslint-disable-line react-hooks/exhaustive-deps

  return message ? <Alert>{message}</Alert> : null;
};

export default InvestmentStatusMessage;
