import { AnyAction } from 'redux';

import * as actions from '../actions/user-update-alert';
const initialState = {
  loadingUpdateAlerts: false,
  loadedUpdateAlerts: false
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.LOAD_UPDATE_ALERT:
      return {
        ...state,
        loadingUpdateAlerts: true,
        loadedUpdateAlerts: false
      };
    case actions.LOAD_UPDATE_ALERT_SUCCESS:
      return {
        ...state,
        loadingUpdateAlerts: false,
        loadedUpdateAlerts: true,
        userUpdateAlerts: action.result.update_alerts
      };
    case actions.LOAD_UPDATE_ALERT_FAIL:
      return {
        ...state,
        loadingUpdateAlerts: false,
        loadedUpdateAlerts: false,
        updateAlertError: action.error
      };
    case actions.SUBMIT_USER_UPDATE_ALERT:
    case actions.SUBMIT_USER_UPDATE_ALERT_SUCCESS:
    case actions.SUBMIT_USER_UPDATE_ALERT_FAIL:
    default:
      return state;
  }
};
