import { getFirstIncompleteStepRoute } from 'containers/Accounts/helpers';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { User } from 'types/api/user';
import { hasCompletedSignup } from 'utilities/user';

interface Props {
  user: User;
}

const RequireCompletedSignup = ({ user }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !hasCompletedSignup(user)) {
      const nextSignUpRoute = getFirstIncompleteStepRoute(user);
      navigate(nextSignUpRoute);
    }
  }, [user, navigate]);

  return <Outlet />;
};

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

export default connect(mapStateToProps)(RequireCompletedSignup);
