import { ChartDataPoint } from 'containers/Portfolio/types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import LegendDataPoint from '../LegendDataPoint/LegendDataPoint';
import * as styles from './ChartLegend.module.scss';

interface Props {
  dataPoints?: ChartDataPoint[];
  heading: string;
  loading?: boolean;
  showNullState?: boolean;
}

const ChartLegend = ({
  dataPoints,
  heading,
  loading,
  showNullState
}: Props) => {
  return (
    <div className={styles.chartLegend}>
      <div className="text-label margin-x">{heading}</div>
      {loading ? (
        <>
          <Skeleton className="margin-x" />
          <Skeleton className="margin-x" />
          <Skeleton className="margin-x" />
        </>
      ) : (
        dataPoints?.map(dataPoint => (
          <LegendDataPoint
            dataPoint={dataPoint}
            key={dataPoint.name}
            showNullState={showNullState}
          />
        ))
      )}
    </div>
  );
};

export default ChartLegend;
