import { AnyAction } from 'redux';

import * as actions from '../actions/modals';
const initialState = {
  inviteShowLearnMoreModal: false,
  showEmailInviteModal: false,
  adBlockerModalContent: ''
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.INVITE_SHOW_LEARN_MORE_MODAL:
      return {
        ...state,
        inviteShowLearnMoreModal: action.showModal
      };

    case actions.SHOW_EMAIL_INVITE_MODAL:
      return {
        ...state,
        showEmailInviteModal: action.showEmailModal
      };

    case actions.POST_EMAIL_NOTIFY_MODAL:
      return {
        ...state,
        showPostEmailNotifyModal: action.showModal,
        postId: action.postId,
        membersCount: action.membersCount
      };

    case actions.SET_AD_BLOCKER_MODAL_CONTENT:
      return {
        ...state,
        adBlockerModalContent: action.content
      };

    default:
      return state;
  }
};
