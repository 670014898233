import { Button, EMLoadingIcon, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddressFormFields } from 'containers/UserUpdateAlerts/types';
import done from 'images/done.svg?url';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { loadInvestmentAccount } from 'redux/actions/account';
import { InvestmentAccount } from 'types/api/account';
import { Dispatch } from 'types/redux';
import { setFieldProps } from 'utilities/formHelpers';

import AddressForm from '../AddressForm';
import { entityAddressVerificationSchema } from '../validation';
import * as styles from './EntityAddressVerificationForm.module.scss';

interface Props {
  accountSelectedForUpdate?: number;
  dispatch: Dispatch;
  investmentAccount: InvestmentAccount;
  loading?: boolean;
  noteUserInterestInUpdatingEntityName?: (accountId: number) => void;
  onSubmit: (values: AddressFormFields) => void;
  previousWizard?: () => void;
  previouslyEnteredAccountDetails: InvestmentAccount;
  userElectedToUpdateInvestmentAccountNamePreviously?: boolean;
}

const EntityAddressVerificationForm = ({
  dispatch,
  previousWizard,
  accountSelectedForUpdate,
  investmentAccount,
  loading,
  noteUserInterestInUpdatingEntityName,
  userElectedToUpdateInvestmentAccountNamePreviously,
  previouslyEnteredAccountDetails,
  onSubmit
}: Props) => {
  const [hoveringField, setHoveringField] = useState(false);

  const accountDetails = previouslyEnteredAccountDetails || investmentAccount;

  const defaultValues = {
    investment_account: {
      ...accountDetails
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<AddressFormFields>({
    values: defaultValues,
    resolver: yupResolver(entityAddressVerificationSchema)
  });

  const entityName = watch('investment_account.entity_name');

  useEffect(() => {
    dispatch(loadInvestmentAccount(accountSelectedForUpdate));
  }, [dispatch, accountSelectedForUpdate]);

  return !accountSelectedForUpdate && !entityName && loading ? (
    <EMLoadingIcon />
  ) : (
    <>
      <div>
        <h4 className="margin-x">{entityName} Contact Information</h4>
        <div className="margin-xx size-16">
          To comply with securities regulations and to prepare your tax
          documents, we are required to gather up-to-date information on your{' '}
          {entityName} Investment Account.
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h6 className="margin-xx">Update:</h6>
        <Row className="margin-row">
          <Col md={6}>
            <Controller
              name="investment_account.entity_name"
              control={control}
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Entity Name"
                  disabled
                  helperText={
                    userElectedToUpdateInvestmentAccountNamePreviously ? (
                      <div className={styles.updatedText}>
                        <img src={done} alt="Success" />
                        Request Sent
                      </div>
                    ) : (
                      <button
                        onMouseEnter={() => setHoveringField(true)}
                        onMouseLeave={() => setHoveringField(false)}
                        className="text-link"
                        onClick={() => {
                          noteUserInterestInUpdatingEntityName(
                            accountSelectedForUpdate
                          );
                        }}
                        type="button"
                      >
                        Request Change
                      </button>
                    )
                  }
                  helperTextAlwaysVisible
                />
              )}
            />

            {!userElectedToUpdateInvestmentAccountNamePreviously && (
              <Button
                className={styles.requestChangeMobile}
                onClick={() => {
                  noteUserInterestInUpdatingEntityName(
                    accountSelectedForUpdate
                  );
                }}
              >
                Request Change
              </Button>
            )}
          </Col>
          <Col md={6}>
            {(userElectedToUpdateInvestmentAccountNamePreviously ||
              hoveringField) && (
              <div className={styles.updatedDetails}>
                Your request will be managed by a member from our Investor
                Relations team. They will be in touch with you via email.
              </div>
            )}
          </Col>
        </Row>
        <AddressForm
          previousWizard={previousWizard}
          formName="investment_account"
          control={control}
          errors={errors}
        />
      </form>
    </>
  );
};

function mapStateToProps(state) {
  return {
    investmentAccount: state.account.investmentAccount,
    loading: state.account.loading
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(EntityAddressVerificationForm);
