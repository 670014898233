import { Button, DateSelect, Input, Select } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import FormError from 'components/FormError/FormError';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import { setFieldProps } from 'utilities/formHelpers';
import { ssn, ssnMaskOptions } from 'utilities/masks';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import {
  countryOptions,
  occupationOptions,
  residenceStatusOptions
} from '../content';
import { getUserAttributes, profileFormValues } from '../helpers';
import { ProfileValues, SharedProfileValues } from '../types';
import { identitySchema } from '../validation';

const Identity = ({ dispatch, user }: SharedProfileValues) => {
  const navigate = useNavigate();

  const defaultValues = getUserAttributes(user);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    watch
  } = useForm<ProfileValues>({
    resolver: yupResolver(identitySchema),
    values: defaultValues
  });

  const residenceStatus = watch('investor_profile_attributes.residence_status');
  const countryOfCitizenship = watch(
    'investor_profile_attributes.country_of_citizenship'
  );

  const showCountryOfCitizenshipField =
    residenceStatus !== 'U.S. Citizen' ||
    (residenceStatus === 'U.S. Citizen' &&
      countryOfCitizenship !== 'United States');

  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  const onSubmit = (values: object) => {
    const submitValues = profileFormValues(values, [
      'investor_profile_attributes.dob',
      'investor_profile_attributes.residence_status',
      'investor_profile_attributes.country_of_citizenship',
      'investor_profile_attributes.occupation',
      'investor_profile_attributes.ssn'
    ]);

    return dispatch(updateUserProfile(submitValues))
      .then(() => {
        dispatch(loadAuth()).then(() => {
          navigate('/users/profile/address');
        });
      })
      .catch(err => {
        throwReactHookFormSubmissionErrors(err, setError);
      });
  };

  return (
    <AccountProgressContainer signupStage="profile" showSkipLink>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-x">Let's verify your identity</h3>
        <p className="margin-xx">
          We collect this information to comply with federal regulation such as
          know-your-customer (KYC) and anti-money laundering (AML) laws.
        </p>
        <Controller
          name="investor_profile_attributes.dob"
          control={control}
          render={({ field }) => (
            <DateSelect
              {...setFieldProps(field, errors)}
              label="Date of Birth"
            />
          )}
        />

        <Controller
          name="investor_profile_attributes.ssn"
          control={control}
          render={({ field }) => (
            <Input
              {...setFieldProps(field, errors)}
              label="Social Security Number"
              mask={ssn}
              inputMaskOptions={ssnMaskOptions}
            />
          )}
        />
        <Controller
          name="investor_profile_attributes.residence_status"
          control={control}
          render={({ field }) => (
            <Select
              {...setFieldProps(field, errors)}
              label="Residence Status"
              options={residenceStatusOptions}
            />
          )}
        />

        {showCountryOfCitizenshipField && (
          <Controller
            name="investor_profile_attributes.country_of_citizenship"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Country of Citizenship"
                options={countryOptions}
              />
            )}
          />
        )}
        <Controller
          name="investor_profile_attributes.occupation"
          control={control}
          render={({ field }) => (
            <Select
              {...setFieldProps(field, errors)}
              label="Occupation"
              options={occupationOptions}
            />
          )}
        />

        <FormError errors={errors} />

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            loading={isSubmitting}
            variant="orange"
            className="button-fixed-width margin-top-xx"
          >
            Continue
          </Button>
          <Button
            disabled={isSubmitting}
            variant="outlined"
            data-testid="backLink"
            className="button-fixed-width margin-top-xx arrowBackButton"
            wrapper={<Link to="/users/profile/name" />}
          >
            <Back />
          </Button>
        </div>
      </form>
    </AccountProgressContainer>
  );
};

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Identity);
