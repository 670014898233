import React from 'react';
import { Col, Row } from 'react-grid-system';

import utils from '../../../utilities/utils';
import * as tableStyles from './TableShared.module.scss';

interface RowType {
  amount: number;
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  name: string;
}

interface Props {
  data: {
    sources_rows: RowType[];
    sources_summary: boolean;
    uses_rows: RowType[];
    uses_summary: boolean;
  };
}

const SourcesAndUses = ({
  data: {
    sources_rows: sourcesRows,
    sources_summary: sourcesSummary,
    uses_rows: usesRows,
    uses_summary: usesSummary
  }
}: Props) => {
  const renderTable = table => {
    const rows = table === 'sources' ? sourcesRows : usesRows;
    const hasSummary = table === 'sources' ? sourcesSummary : usesSummary;
    let total = 0;
    const rowsOutput = rows.map(row => {
      let classString = '';
      if (row.isBold) classString += ' bold';
      if (row.isItalic) classString += ' italic';
      if (row.isUnderline) classString += ' underline';
      if (hasSummary) total += row.amount;
      return (
        <tr key={row.name} className={classString}>
          <td>{row.name}</td>
          <td>${utils.numberWithCommas(row.amount)}</td>
        </tr>
      );
    });
    const footer = (
      <tr className={tableStyles.footer}>
        <td>Total {table === 'sources' ? 'Sources' : 'Uses'}</td>
        <td>${utils.numberWithCommas(total)}</td>
      </tr>
    );
    return (
      <tbody>
        {rowsOutput}
        {hasSummary && footer}
      </tbody>
    );
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <div className={tableStyles.tableWrap}>
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>Sources</th>
                </tr>
              </thead>
              {sourcesRows && renderTable('sources')}
            </table>
          </div>
        </Col>
        <Col md={6}>
          <div className={tableStyles.tableWrap}>
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>Uses</th>
                </tr>
              </thead>
              {usesRows && renderTable('uses')}
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SourcesAndUses;
