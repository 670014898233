import { DateSelect, Input, Select } from '@equitymultiple/react-eui';
import PhoneFields from 'components/forms/PhoneFields/PhoneFields';
import {
  allResidencyStatuses,
  countryOptions
} from 'containers/Accounts/contents';
import { occupationOptions } from 'containers/Profile/content';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { UpdateUserProfilePayload } from 'types/actions/user-settings';
import { setFieldProps } from 'utilities/formHelpers';
import { ssn, ssnMaskOptions } from 'utilities/masks';

interface Props {
  control: Control<UpdateUserProfilePayload>;
  disableSsn?: boolean;
  errors: FieldErrors<UpdateUserProfilePayload>;
  residenceStatus: string;
}

const ProfileForm = ({
  control,
  errors,
  disableSsn,
  residenceStatus
}: Props) => {
  return (
    <div className="clearfix">
      <Row>
        <Col md={6}>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Legal First Name"
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Legal Last Name"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <PhoneFields<UpdateUserProfilePayload>
            control={control}
            errors={errors}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Email"
                disabled
                placeholder="email@example.com"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="overflow-visible">
          <Controller
            name="investor_profile_attributes.residence_status"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Residence Status"
                options={allResidencyStatuses.map(key => ({
                  label: key,
                  value: key
                }))}
              />
            )}
          />
        </Col>
        <Col md={6} className="overflow-visible">
          <Controller
            name="investor_profile_attributes.country_of_citizenship"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Country of Citizenship"
                options={countryOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="overflow-visible">
          <Controller
            name="investor_profile_attributes.dob"
            control={control}
            render={({ field }) => (
              <DateSelect
                {...setFieldProps(field, errors)}
                label="Date of Birth"
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="investor_profile_attributes.ssn"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label={`${
                  residenceStatus === 'U.S. Permanent Resident' ||
                  residenceStatus === 'U.S. Resident'
                    ? 'SSN/ITIN'
                    : 'Social Security Number'
                }`}
                mask={ssn}
                disabled={disableSsn}
                inputMaskOptions={ssnMaskOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            name="investor_profile_attributes.occupation"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="Occupation"
                options={occupationOptions}
              />
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ProfileForm;
