import { User } from 'types/api/user';

import { SignupStep, SignupValues } from './types';

export const setBackgroundColor = () => {
  document.querySelector('body').style.backgroundColor = '#fff';
};

export const redirectIfPreviousStepsIncomplete = (
  navigate,
  signUpValues: SignupValues,
  step: SignupStep,
  user?: User
) => {
  if (!user && !signUpValues.email) {
    navigate('/users/sign_in');
  } else if (!user && step > 2 && !signUpValues.password) {
    navigate('/users/signup/password');
  } else if (step > 3 && !signUpValues.first_name) {
    navigate('/users/signup/name');
  }
};

export const emailVerifyChannel = window.BroadcastChannel
  ? new BroadcastChannel('emailVerify')
  : {
      postMessage: () => {},
      onmessage: () => {}
    };
