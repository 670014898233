import utils from '../../utilities/utils';

export const getComponentId = component => {
  let id = 'component-' + component.id;

  // Set to string for top level sections for user readable nav anchors
  if (
    (component.type === 'section' || component.type === 'section-parallel') &&
    !component.parent_id
  )
    id = utils.kebabCase(component.title);

  return id;
};

export const getImageUrl = image => {
  let imageUrl;
  if (image && image.versions) {
    // Use large or medium by default, depending on screen width
    imageUrl =
      window.innerWidth > 600 ? image.versions.large : image.versions.medium;
    if (image.custom_width) {
      // If a custom width exists and is less than 600, use the appropriate size instead
      if (image.custom_width < 160) imageUrl = image.versions.thumb;
      else if (image.custom_width < 300) imageUrl = image.versions.small;
      else if (image.custom_width < 600) imageUrl = image.versions.medium;
    }
  }
  return imageUrl;
};

// Get the page on which returns appear in investor packet PDFs
// Defaults to 3, can be overidden for osme offerings
const getInvestorPacketReturnsPage = offeringId => {
  let page = 3;
  if (offeringId === 562) page = 116;
  return page;
};

export const getInvestorPacketUrlWithPage = (investorPacketUrl, offeringId) => {
  let urlWithPage = null;
  if (investorPacketUrl) {
    const investorPacketReturnsPage = getInvestorPacketReturnsPage(offeringId);
    const isIOS = utils.isIOS();
    const pageParameter =
      `#page${isIOS ? '' : '='}` + investorPacketReturnsPage;
    urlWithPage = investorPacketUrl + pageParameter;
  }
  return urlWithPage;
};

export const isRemFund = offering => offering?.id === 646;
