import { EMLoadingIcon } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUserAttributes } from 'containers/Profile/helpers';
import React, { useCallback } from 'react';
import { Col, Row } from 'react-grid-system';
import { SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import { UpdateUserProfilePayload } from 'types/actions/user-settings';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import humane from 'utilities/humane';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import * as sharedStyles from '../../SettingsShared.module.scss';
import AddressForm from '../forms/AddressForm';
import InvestorProfileForm from '../forms/InvestorProfileForm';
import ProfileForm from '../forms/ProfileForm/ProfileForm';
import PersonalIdentification from '../PersonalIdentification/PersonalIdentification';
import * as styles from '../Profile/Profile.module.scss';
import { profileSchema } from '../validation';

interface Props {
  currentUser?: User;
  disableSsn?: boolean;
  dispatch: Dispatch;
  loading?: boolean;
}

const Profile = ({ currentUser, disableSsn, dispatch, loading }: Props) => {
  const defaultValues = getUserAttributes(currentUser);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    watch
  } = useForm<UpdateUserProfilePayload>({
    resolver: yupResolver(profileSchema),
    defaultValues
  });

  const residenceStatus = watch('investor_profile_attributes.residence_status');

  const onSubmit: SubmitHandler<UpdateUserProfilePayload> = useCallback(
    values => {
      return dispatch(updateUserProfile(values))
        .then(() => {
          humane.notice('Profile updated successfully');
          dispatch(loadAuth());
        })
        .catch(res => throwReactHookFormSubmissionErrors(res, setError));
    },
    [dispatch, setError]
  );

  return loading ? (
    <EMLoadingIcon />
  ) : (
    <div className="margin-top-xxx" data-testid="profileTab">
      <form data-testid="profileSettings" onSubmit={handleSubmit(onSubmit)}>
        <Row className="margin-xxx">
          <Col lg={3}>
            <h3 className={sharedStyles.h3}>Personal Information</h3>
          </Col>
          <Col lg={9}>
            <p>
              The Patriot Act requires us to verify the identity of every
              EquityMultiple investor as part of the mandatory &quot;Know Your
              Customer&quot; process.
            </p>

            <p>
              Your account information is securely maintained in accordance with
              our{' '}
              <a
                href="https://www.equitymultiple.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
            <ProfileForm
              residenceStatus={residenceStatus}
              disableSsn={disableSsn}
              control={control}
              errors={errors}
            />
          </Col>
        </Row>

        <Row className="margin-xxxx">
          <Col lg={3}>
            <h3 className={sharedStyles.h3}>Investor Profile</h3>
          </Col>
          <Col lg={9}>
            <InvestorProfileForm
              control={control}
              errors={errors}
              userStage={currentUser.investor_profile?.stage}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3}>
            <h3 className={sharedStyles.h3}>Personal Address</h3>
          </Col>
          <Col lg={9}>
            <AddressForm
              control={control}
              errors={errors}
              residenceStatus={residenceStatus}
              isSubmitting={isSubmitting}
            />
          </Col>
        </Row>
      </form>
      <Row
        id="personalIdentification"
        className={styles.personalIdentification}
      >
        <Col lg={3}>
          <h3 className={sharedStyles.h3}>Personal Identification</h3>
        </Col>
        <Col lg={9}>
          <PersonalIdentification />
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    disableSsn: !!state.auth.user?.investor_profile?.ssn
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const connector = connect(mapStateToProps)(Profile);

export default connector;
