import { Button } from '@equitymultiple/react-eui';
import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import logo from '../../../images/logos/full-logo-white.svg?url';
import { User } from '../../../types/api/user';
import EmAnalytics from '../../../utilities/em_analytics';
import { canAccessPage, canInvest } from '../../../utilities/user';
import utils from '../../../utilities/utils';
import Banner from './Banner/Banner';
import * as styles from './Header.module.scss';
import ResourceLinks from './ResourceLinks';

type Props = {
  currentUser: User;
  location: string;
};

const Header = (props: Props) => {
  const resourcesDropdownRef = useRef<HTMLDivElement>();
  const resourcesDropdownToggleRef = useRef<HTMLButtonElement>();
  const userDropdownRef = useRef<HTMLDivElement>();
  const userDropdownToggleRef = useRef<HTMLButtonElement>();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileSubMenuOpen, setMobileSubMenuOpen] = useState(false);
  const [mobileResourcesMenuOpen, setMobileResourcesMenuOpen] = useState(false);
  const [mobileSettingsMenuOpen, setMobileSettingsMenuOpen] = useState(false);
  const [resourcesDropdownOpen, setResourcesDropdownOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    // If the sub menu is open then close it after the slide animation completes
    if (mobileMenuOpen) {
      setTimeout(() => {
        setMobileMenuOpen(false);
        setMobileResourcesMenuOpen(false);
        setMobileSettingsMenuOpen(false);
      }, 400);
    }
  };

  const closeOpenDropdownMenus = event => {
    const clickOutsideOpenResourcesDropdown =
      resourcesDropdownOpen &&
      resourcesDropdownRef &&
      resourcesDropdownRef?.current &&
      !resourcesDropdownRef.current.contains(event.target) &&
      !resourcesDropdownToggleRef.current.contains(event.target);
    const clickOutsideOpenUserDropdown =
      userDropdownOpen &&
      userDropdownRef &&
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target) &&
      !userDropdownToggleRef.current.contains(event.target);

    if (clickOutsideOpenResourcesDropdown) setResourcesDropdownOpen(false);
    if (clickOutsideOpenUserDropdown) setUserDropdownOpen(false);
  };

  const hideMobileMenuOnResizeToDesktop = utils.debounce(() => {
    const windowWidth = window.innerWidth;
    if (mobileMenuOpen && windowWidth > 991) toggleMobileMenu();
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', hideMobileMenuOnResizeToDesktop);
    document.addEventListener('click', closeOpenDropdownMenus);

    return () => {
      window.removeEventListener('resize', hideMobileMenuOnResizeToDesktop);
      document.removeEventListener('click', closeOpenDropdownMenus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourcesDropdownOpen, userDropdownOpen, mobileMenuOpen]);

  const sendSegmentEvent = (link: string, isSecondary = false) => {
    const {
      currentUser: { track }
    } = props;
    EmAnalytics.track(
      `Clicked on ${isSecondary ? 'Secondary' : 'Primary'} Navigation`,
      'Navigation',
      {
        navigationLink: link,
        stage: track.stage,
        session_count: track.session_count,
        investment_count: track.investment_count
      }
    );
  };

  const toggleMobileSubMenu = (action: string) => {
    if (action === 'back') {
      // Use separate states for the sub menu and individual menus and a timeout
      // so we can hide the sub menus only after the slide animation completes
      setMobileSubMenuOpen(false);
      setTimeout(() => {
        setMobileResourcesMenuOpen(false);
        setMobileSettingsMenuOpen(false);
      }, 250);
    }
    if (action === 'resources') {
      setMobileSubMenuOpen(true);
      setMobileResourcesMenuOpen(true);
    }
    if (action === 'settings') {
      setMobileSubMenuOpen(true);
      setMobileSettingsMenuOpen(true);
    }
  };

  const toggleResourcesDropdown = () => {
    setResourcesDropdownOpen(!resourcesDropdownOpen);
  };

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  const handleLinkClick = (segmentTrackName: string, isSecondary = false) => {
    if (mobileMenuOpen) toggleMobileMenu();
    if (segmentTrackName) sendSegmentEvent(segmentTrackName, isSecondary);
  };

  const handleLogout = () => {
    if (window.Intercom) window.Intercom('shutdown');
    handleLinkClick('Log Out', true);
  };

  const renderLink = (link: string) => {
    const { currentUser } = props;
    const taxTrackerEnabled = currentUser?.tax_tracker_enabled;
    switch (link) {
      case 'accounts':
        return (
          canAccessPage('accounts', currentUser) && (
            <NavLink
              to="/accounts"
              onClick={() => handleLinkClick('My Accounts', true)}
              className={({ isActive }) => (isActive ? styles.active : '')}
            >
              <span className={styles.linkText}>My Accounts</span>
            </NavLink>
          )
        );
      case 'activity':
        return (
          canAccessPage('activity', currentUser) &&
          canInvest(currentUser) && (
            <NavLink
              to="/activity"
              onClick={() => handleLinkClick('My Activity')}
              className={({ isActive }) => (isActive ? styles.active : '')}
            >
              <span className={styles.linkText}>My Activity</span>
            </NavLink>
          )
        );
      case 'documents':
        return (
          canAccessPage('documents', currentUser) && (
            <NavLink
              to="/documents"
              onClick={() => handleLinkClick('My Documents', true)}
              className={({ isActive }) => (isActive ? styles.active : '')}
            >
              <span className={styles.linkText}>My Documents</span>
            </NavLink>
          )
        );
      case 'help center':
        return (
          <a
            href="//help.equitymultiple.com/en/"
            onClick={() => handleLinkClick('Help Center', true)}
          >
            <span className="link-text">Help Center</span>
          </a>
        );
      case 'invest':
        return (
          <NavLink
            to="/"
            onClick={() => handleLinkClick('Invest')}
            className={({ isActive }) => (isActive ? styles.active : '')}
          >
            <span className={styles.linkText}>Invest</span>
          </NavLink>
        );
      case 'invite':
        return (
          <NavLink
            to="/users/invitation/new"
            onClick={() => handleLinkClick('Invite a Friend')}
            className={({ isActive }) => (isActive ? styles.active : '')}
          >
            <span className={styles.linkText}>Invite a Friend</span>
          </NavLink>
        );
      case 'portfolio':
        return (
          canAccessPage('portfolio', currentUser) && (
            <NavLink
              to="/portfolio"
              onClick={() => handleLinkClick('My Portfolio')}
              className={({ isActive }) => (isActive ? styles.active : '')}
            >
              <span className={styles.linkText}>My Portfolio</span>
            </NavLink>
          )
        );
      case 'settings':
        return (
          canAccessPage('settings', currentUser) && (
            <NavLink
              to="/settings"
              onClick={() => handleLinkClick('Settings', true)}
              className={({ isActive }) => (isActive ? styles.active : '')}
            >
              <span className={styles.linkText}>Settings</span>
            </NavLink>
          )
        );
      case 'tax tracker':
        return (
          taxTrackerEnabled && (
            <NavLink
              to="/tax_tracker"
              onClick={() => handleLinkClick('Tax Tracker', true)}
              className={({ isActive }) => (isActive ? styles.active : '')}
            >
              <span className={styles.linkText}>Tax Tracker</span>
            </NavLink>
          )
        );
    }
    return null;
  };

  const handleResourcesClick = () => {
    toggleResourcesDropdown();
    sendSegmentEvent('Resources');
  };

  const { currentUser, location } = props;

  const taxTrackerEnabled = currentUser?.tax_tracker_enabled;

  return (
    <>
      <div data-testid="Header" className={styles.mainNav} role="navigation">
        <div className={styles.navContainer}>
          <Link className={styles.headerLogo} to="/">
            <img alt="EquityMultiple" src={logo} />
          </Link>

          <nav className={styles.desktopMenu}>
            {renderLink('activity')}
            {renderLink('portfolio')}
            {renderLink('invest')}
            <button
              ref={resourcesDropdownToggleRef}
              className={`${styles.emDropdownToggle} text-link ${
                resourcesDropdownOpen ? styles.open : ''
              }`}
              type="button"
              onClick={handleResourcesClick}
            >
              <div className={styles.toggleButton}>
                Resources
                <span className={`caret ${styles.caret}`} />
              </div>
              <div
                ref={resourcesDropdownRef}
                className={`${styles.emDropdownMenu} ${
                  resourcesDropdownOpen ? styles.open : ''
                }`}
              >
                <ResourceLinks
                  handleLinkClick={handleLinkClick}
                  user={currentUser}
                />
              </div>
            </button>
            {renderLink('invite')}
          </nav>

          <button
            ref={userDropdownToggleRef}
            className={`${styles.emDropdownToggle} text-link ${
              styles.userDropdown
            } ${userDropdownOpen ? styles.open : ''}`}
            type="button"
            onClick={toggleUserDropdown}
          >
            <div className={styles.toggleButton}>
              {currentUser.first_name}
              <span className={`${styles.caret} caret`} />
            </div>
            <div
              ref={userDropdownRef}
              className={`${styles.emDropdownMenu} ${
                userDropdownOpen ? styles.open : ''
              }`}
            >
              {renderLink('settings')}
              {renderLink('accounts')}
              {renderLink('documents')}
              {taxTrackerEnabled && renderLink('tax tracker')}
              {renderLink('help center')}
              <a onClick={handleLogout} href="/mkt/users/sign_out">
                Log Out
              </a>
            </div>
          </button>

          <button
            className={styles.mobileMenuToggle}
            type="button"
            onClick={toggleMobileMenu}
          >
            <span className={styles.iconBar} />
            <span className={styles.iconBar} />
            <span className={styles.iconBar} />
          </button>

          <div
            className={`${styles.mobileMenu} ${
              mobileMenuOpen ? styles.open : ''
            }`}
          >
            <button
              className={`${styles.backButton} ${
                mobileSubMenuOpen ? styles.show : ''
              }`}
              type="button"
              onClick={() => toggleMobileSubMenu('back')}
            >
              Back
            </button>
            <button
              className={styles.closeButton}
              type="button"
              aria-label="Close"
              onClick={toggleMobileMenu}
            />
            <div className="overflow-hidden">
              <nav
                className={`${styles.menu} ${
                  mobileSubMenuOpen ? styles.subMenuOpen : ''
                }`}
              >
                <div className={styles.top}>
                  {canAccessPage('settings', currentUser) ? (
                    <button
                      data-testid="setings-sub-menu-toggle"
                      className={styles.subMenuToggle}
                      onClick={() => toggleMobileSubMenu('settings')}
                      type="button"
                    >
                      <div className={styles.name}>
                        {currentUser.first_name}
                      </div>
                      <div className={styles.linkText}>Settings</div>
                      <div className={styles.caretRight} />
                    </button>
                  ) : (
                    <div className={`${styles.name} ${styles.noLink}`}>
                      {currentUser.first_name}
                    </div>
                  )}
                  <div className={styles.border} />
                </div>
                {renderLink('activity')}
                {renderLink('portfolio')}
                {renderLink('invest')}
                <button
                  className={styles.subMenuToggle}
                  type="button"
                  onClick={() => toggleMobileSubMenu('resources')}
                >
                  Resources
                  <div className={styles.caretRight} />
                </button>
                {renderLink('invite')}
                <div className={`${styles.border} ${styles.bottomBorder}`} />
                <Button
                  onClick={handleLogout}
                  className={styles.logOutButton}
                  variant="outlined"
                  href="/mkt/users/sign_out"
                >
                  Log Out
                </Button>
                <nav className={styles.subMenu}>
                  {mobileResourcesMenuOpen && (
                    <ResourceLinks
                      handleLinkClick={handleLinkClick}
                      user={currentUser}
                    />
                  )}
                  {mobileSettingsMenuOpen && (
                    <>
                      {renderLink('settings')}
                      {renderLink('accounts')}
                      {renderLink('documents')}
                      {renderLink('help center')}
                      {taxTrackerEnabled && renderLink('tax tracker')}
                    </>
                  )}
                </nav>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Banner user={currentUser} location={location} />
    </>
  );
};

export default Header;
