import { Button } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import SetPasswordFields from 'components/forms/SetPasswordFields/SetPasswordFields';
import { setPasswordSchema } from 'components/forms/SetPasswordFields/validation';
import phone from 'images/signup-phone.png';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { submitSignupForm, updateSignupValues } from 'redux/actions/onboarding';
import utils from 'utilities/utils';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import SignUpWrap from '../components/SignUpWrap/SignUpWrap';
import { redirectIfPreviousStepsIncomplete } from '../helpers';
import * as sharedStyles from '../SignUpShared.module.scss';
import { SharedSignupProps } from '../types';
import * as styles from './Password.module.scss';

interface FormFields {
  confirmPassword: string;
  password: string;
}

const Password = ({ dispatch, defaultValues }: SharedSignupProps) => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError
  } = useForm<FormFields>({
    resolver: yupResolver(setPasswordSchema),
    values: defaultValues as FormFields
  });

  useEffect(() => {
    document.title = 'Start Your Account | EquityMultiple';

    redirectIfPreviousStepsIncomplete(navigate, defaultValues, 2);
  }, [defaultValues, navigate]);

  const isInvite = !!defaultValues.invitation_token;

  const onSubmit = (values: FormFields) => {
    const submitValues = utils.cloneObject(values);

    delete submitValues.confirmPassword;

    return dispatch(
      submitSignupForm({
        step: 2,
        user: {
          ...submitValues,
          email: defaultValues.email
        }
      })
    )
      .then(() => {
        dispatch(updateSignupValues(values));

        navigate('/users/signup/name');
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  return (
    <SignUpWrap
      introBackgroundColor="blue"
      introContent={
        <>
          <img
            src={phone}
            className={styles.phone}
            alt="EquityMultiple on your phone"
          />
          <p className={sharedStyles.quote}>“</p>
          <p className={styles.quoteText}>
            It's an incredible opportunity to be able to invest in cities across
            the country without leaving our home. At this point I'm 100%
            allocated to alternatives. I sleep easy at night.
          </p>
        </>
      }
      step={isInvite ? 1 : 2}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-xx">Set your password...</h3>
        <SetPasswordFields control={control} errors={errors} />
        <FormError errors={errors} />
        <Button
          type="submit"
          loading={isSubmitting}
          variant="orange"
          className="button-fixed-width margin-top-xx"
        >
          Continue
        </Button>
      </form>
    </SignUpWrap>
  );
};

function mapStateToProps(store) {
  return {
    defaultValues: store.auth.signupValues
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Password);
