import abby from 'images/ir-abby-blumenfeld.png';
import daniel from 'images/ir-daniel-brereton.png';
import marious from 'images/ir-marious-sjulsen.png';
import React from 'react';

import * as styles from './IrImages.module.scss';

interface Props {
  className?: string;
}

const IrImages = ({ className = '' }: Props) => (
  <div className={`${styles.images} ${className}`} data-testid="irImages">
    <img src={abby} alt="Abby Blumenfeld" />
    <img src={daniel} alt="Daniel Brereton" />
    <img src={marious} alt="Marious Sjulsen" />
  </div>
);

export default IrImages;
