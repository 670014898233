import React from 'react';
import { Route, Routes } from 'react-router-dom';

import NotFound from '../NotFound/NotFound';
import {
  AccountDocuments,
  Accounts,
  AccountStatus,
  BankAccounts,
  BeneficialEdit,
  BeneficialNew,
  BeneficialOwners,
  EntityAccountUpdate,
  InvestmentAccount,
  JointSecondaryHolderIdentification,
  JointSecondaryHolderUpdate,
  NewAccount,
  NewEntityAccount,
  NewIraAccount,
  NewJointSecondaryHolder,
  OrganizationalDocument,
  PlaidLinkOAuth,
  PoaDocumentUpdate,
  TaxDocument,
  W9DocumentUpdate
} from '.';

const accountRoutes = (
  <Routes>
    <Route path="*" element={<NotFound />} />
    <Route path="" element={<Accounts />} />
    <Route path="/new" element={<NewAccount />} />
    <Route path="/:type/affordances" element={<NewAccount />} />
    {/* Routes for creating new Investment Account */}
    <Route path="/wizard/ira" element={<NewIraAccount />} />
    <Route path="/wizard/:account_type/new" element={<NewAccount />} />
    <Route
      path="/wizard/:account_type/:reference_id"
      element={<InvestmentAccount />}
    />
    <Route
      path="/wizard/entity/information/new"
      element={<NewEntityAccount />}
    />
    <Route
      path="/wizard/entity/information/:reference_id"
      element={<EntityAccountUpdate />}
    />
    <Route
      path="/wizard/joint/secondary_account/new"
      element={<NewJointSecondaryHolder />}
    />
    <Route
      path="/wizard/joint/secondary_account/:reference_id"
      element={<JointSecondaryHolderUpdate />}
    />
    <Route
      path="/update/joint/secondary_account/identification_document/:reference_id"
      element={<JointSecondaryHolderIdentification />}
    />
    <Route
      path="/wizard/joint/secondary_account/identification_document/:reference_id"
      element={<JointSecondaryHolderIdentification />}
    />
    <Route
      path="/wizard/:account_type/organizational_document/:reference_id"
      element={<OrganizationalDocument />}
    />
    <Route
      path="/wizard/:account_type/tax_document/:reference_id"
      element={<TaxDocument />}
    />
    <Route
      path="/wizard/:account_type/w9/:reference_id"
      element={<W9DocumentUpdate />}
    />
    <Route
      path="/wizard/:account_type/poa/:reference_id"
      element={<PoaDocumentUpdate />}
    />
    <Route
      path="/wizard/:account_type/thanks/:reference_id"
      element={<AccountStatus />}
    />
    <Route
      path="/wizard/:account_type/link_bank/:reference_id"
      element={<BankAccounts />}
    />
    <Route
      path="/wizard/:account_type/beneficial_owners/:reference_id"
      element={<BeneficialOwners />}
    />
    <Route
      path="/wizard/:account_type/beneficial_owners/new/:reference_id"
      element={<BeneficialNew />}
    />
    <Route
      path="/wizard/:account_type/beneficial_owners/:id/:reference_id"
      element={<BeneficialEdit />}
    />
    <Route
      path="/update/:account_type/:reference_id"
      element={<InvestmentAccount />}
    />
    <Route
      path="/update/entity/information/:reference_id"
      element={<EntityAccountUpdate />}
    />
    <Route
      path="/update/entity/rep/new/:reference_id"
      element={<BeneficialNew />}
    />
    <Route
      path="/update/entity/rep/:id/:reference_id"
      element={<BeneficialEdit />}
    />
    <Route
      path="/update/:account_type/linkedbanks/:reference_id"
      element={<BankAccounts />}
    />
    <Route
      path="/update/:account_type/documents/:reference_id"
      element={<AccountDocuments />}
    />
    <Route
      path="/update/:account_type/documents/organizational_document/:reference_id"
      element={<OrganizationalDocument />}
    />
    <Route
      path="/update/:account_type/documents/tax_document/:reference_id"
      element={<TaxDocument />}
    />
    <Route
      path="/update/:account_type/documents/poa/:reference_id"
      element={<PoaDocumentUpdate />}
    />
    <Route
      path="/update/:account_type/documents/poa/update/:reference_id"
      element={<PoaDocumentUpdate />}
    />
    <Route
      path="/update/:account_type/documents/w9/:reference_id"
      element={<W9DocumentUpdate />}
    />
    <Route
      path="/update/:account_type/documents/w9/update/:reference_id"
      element={<W9DocumentUpdate />}
    />
    <Route path="/bank-linking/oauth" element={<PlaidLinkOAuth />} />
  </Routes>
);

export default accountRoutes;
