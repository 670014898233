import { Button, FileUploader } from '@equitymultiple/react-eui';
import FormError from 'components/FormError/FormError';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  loadInvestmentAccount,
  loadInvestmentAccountDocuments,
  uploadInvestmentAccountDocuments
} from 'redux/actions/account';
import { AccountDocuments, SingleInvestmentAccount } from 'types/api/account';
import { Dispatch } from 'types/redux';
import { maxUploadSize } from 'utilities/constants';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { isComplete } from '../helpers';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';
import DocumentPreview from './DocumentPreview/DocumentPreview';

type Params = {
  reference_id: string;
};

interface Props {
  dispatch: Dispatch;
  documents: AccountDocuments;
  investmentAccount: SingleInvestmentAccount;
  uploading: boolean;
}

const OrganizationalDocument = ({
  dispatch,
  documents,
  investmentAccount,
  uploading
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const {
    formState: { errors },
    setError
  } = useForm();

  useEffect(() => {
    document.title = 'My Accounts | Organizational Document';
    dispatch(loadInvestmentAccount(params.reference_id))
      .then(() =>
        dispatch(loadInvestmentAccountDocuments(params.reference_id)).catch(
          error => handleErrorResponse(navigate, error)
        )
      )
      .catch(error => handleErrorResponse(navigate, error));
  }, [dispatch, params, navigate]);

  const handleUpload = file => {
    const values = {
      investment_account: {
        id: investmentAccount.id,
        id_type: 'entity_evidence_attachment',
        file
      }
    };

    return dispatch(uploadInvestmentAccountDocuments(values))
      .then(res => {
        if (res.documents.entity_evidence_attachment?.name)
          dispatch(loadInvestmentAccountDocuments(params.reference_id));
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  const completeAccount =
    investmentAccount &&
    isComplete(investmentAccount) &&
    !location.pathname.includes('/accounts/wizard');
  const documentUploaded = documents?.entity_evidence_attachment?.name;
  const buttonDisabled = uploading || !documentUploaded;

  return (
    <AccountWrap loading={!investmentAccount} account={investmentAccount}>
      {investmentAccount && (
        <>
          <h4 className="margin-top-0">
            Let&apos;s Upload Your Organizational Document
          </h4>
          <p>
            Please upload (i) the Partnership or LLC Agreement; (ii) Trust
            Instrument; (iii) Articles of Incorporation OR (iv) other
            document(s) demonstrating the valid formation and ownership of your
            entity.
          </p>
          {documents?.entity_evidence_attachment?.name && (
            <DocumentPreview
              document={documents.entity_evidence_attachment}
              documentType="Organizational Document"
            />
          )}
          <h5>Upload New Organizational Document</h5>

          <form className="margin-xx">
            <FileUploader
              uploading={uploading}
              upload={handleUpload}
              onRemove={() => {}}
              maxSize={maxUploadSize}
            />
            <FormError errors={errors} />
          </form>

          <div className="forwardBackButtonWrapCompact">
            <Button
              disabled={buttonDisabled}
              onClick={() => {
                EmAnalytics.track(
                  'Submits Entity Organizational Document',
                  'Onboarding',
                  {
                    account_type: 'entity'
                  }
                );
              }}
              wrapper={
                <Link
                  to={`/accounts/wizard/entity/tax_document/${params.reference_id}`}
                />
              }
              style={{ width: 160 }}
              variant="orange"
            >
              {completeAccount ? 'Update' : 'Continue'}
            </Button>
            <BackLink
              investmentAccount={investmentAccount}
              page="Organizational Document"
            />
          </div>
        </>
      )}
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    documents: state.account.documents,
    investmentAccount: state.account.investmentAccount,
    uploading: state.account.uploading
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(OrganizationalDocument);
