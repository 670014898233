import { Table, Tooltip } from '@equitymultiple/react-eui';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { OfferingTaxStatus } from 'types/api/offeringTaxStatus';
import { Post } from 'types/api/post';

import { tableColumnLabels } from '../constants';
import { getTaxDocStepInfo } from '../helpers';
import * as styles from './TaxDocumentTable.module.scss';

const getDocStatus = (doc, hasUnreadPosts) => {
  let status = doc.status;

  if (hasUnreadPosts)
    status = <span className={styles.notificationSpan}>{status}</span>;

  if (status === 'Available') {
    status = (
      <Link key="documents" to={`/documents?offering_id=${doc.offering_id}`}>
        Available
      </Link>
    );
  }

  if ((status === 'Delayed' || doc.under_review) && !hasUnreadPosts) {
    const activityLink = (
      <span className={styles.activityLink}>
        <Link key="activity" to={`/activity?oid=${doc.offering_id}&label=tax`}>
          View Activity to learn more.
        </Link>{' '}
      </span>
    );
    // Processing & under review
    let tooltipText = (
      <span>
        An issue was identified when processing tax documents for this
        investment. We are working with the Sponsor and our accountants to
        correct the issue. {activityLink}
      </span>
    );
    // Delayed and no estimate
    if (
      status === 'Delayed' &&
      doc.estimate_status === 'no_estimate_anticipated'
    ) {
      tooltipText = (
        <span>
          Due to late delivery of tax documents and financials from the Sponsor,
          we cannot ensure delivery of your tax documents or an estimate before
          May 17th. Please consider filing for an extension. {activityLink}
        </span>
      );
    }
    // Delayed and estimate
    if (
      status === 'Delayed' &&
      doc.estimate_status === 'estimate_anticipated'
    ) {
      tooltipText = (
        <span>
          Due to late tax documents from the Sponsor, we cannot ensure delivery
          of your tax documents before May 17th. Please consider filing for an
          extension. We anticipate providing an estimate no later than May 14th.{' '}
          {activityLink}
        </span>
      );
    }
    status = (
      <Tooltip className="info-icon-margin-left" tooltipContent={tooltipText}>
        <span className={styles.alertSpan}>{status}</span>
      </Tooltip>
    );
  }

  return status;
};

const getEstimatedCompletionDate = doc => {
  const estimatedCompletionDate = doc.estimated_completion_date;
  let date = '';

  if (estimatedCompletionDate && doc.status !== 'Available')
    date = moment(estimatedCompletionDate).format('MM/DD/YYYY');

  return <span data-testid="estimatedCompletionDate">{date}</span>;
};

interface Props {
  documents: OfferingTaxStatus[];
  unreadPosts: Post[];
  visibleStatus: string;
}

const TaxDocumentTable = ({ documents, unreadPosts, visibleStatus }: Props) => {
  const getRows = () => {
    const stnDocs = documents.filter(doc => doc.is_stn);
    const nonStnDocs = documents.filter(doc => !doc.is_stn);

    const rows = [];

    nonStnDocs.forEach(doc => {
      const status = doc.status;
      if (visibleStatus === 'All' || visibleStatus === status) {
        let unreadPostCount = 0;
        if (unreadPosts.length > 0)
          unreadPostCount = unreadPosts.filter(
            post => post.offering.id === doc.offering_id
          ).length;

        const activityLinkText =
          unreadPostCount > 0
            ? `${unreadPostCount} New Activity Post${
                unreadPostCount > 1 ? 's' : ''
              }`
            : 'View Activity to learn more';

        rows.push({
          cells: [
            {
              value: (
                <div key="offering">
                  <div className={styles.offeringName}>
                    {doc.offering_title}
                  </div>
                  <div className={styles.entityName}>{doc.entity_name}</div>
                </div>
              ),
              sortableValue: doc.offering_title
            },
            doc.document_type,
            getDocStatus(doc, unreadPostCount > 0),
            {
              value: (
                <div className={styles.stepWrap} key="step">
                  {doc.document_type === 'K-1' && (
                    <div className="step-number">
                      Step {getTaxDocStepInfo(doc)} of 5
                    </div>
                  )}
                  <div className={styles.stepMessage}>
                    {doc.estimate_uploaded && doc.status !== 'Available'
                      ? 'Estimate Available'
                      : getTaxDocStepInfo(doc, 'message')}
                  </div>
                  <Link
                    className={`${styles.activityLink} ${
                      unreadPostCount > 0 ? 'strong' : ''
                    }`}
                    key="activity"
                    to={`/activity?oid=${doc.offering_id}&label=tax`}
                  >
                    {activityLinkText}
                  </Link>
                </div>
              ),
              sortableValue: getTaxDocStepInfo(doc)
            },
            getEstimatedCompletionDate(doc)
          ]
        });
      }
    });

    if (stnDocs.length > 0) {
      const stnDoc = stnDocs.find(doc => doc.first_stn_offering) || stnDocs[0];

      if (visibleStatus === 'All' || visibleStatus === stnDoc.status) {
        rows.push({
          cells: [
            {
              value: (
                <div key="offering">
                  <div className={styles.offeringName}>Alpine Notes</div>
                </div>
              ),
              sortableValue: 'Alpine Notes'
            },
            stnDoc.document_type,
            getDocStatus(stnDoc, false),
            {
              value: (
                <div className={styles.stepWrap} key="step">
                  <div className={styles.stepNumber}>
                    Step {getTaxDocStepInfo(stnDoc)} of 2
                  </div>
                  <div className={styles.stepMessage}>
                    {getTaxDocStepInfo(stnDoc, 'message')}
                  </div>
                </div>
              )
            },
            getEstimatedCompletionDate(stnDoc)
          ],
          collapsibleCell: (
            <div className="margin-top-x" data-testid="stnCopy">
              All 1099-INT documents are now available. For your convenience,
              we've consolidated your Alpine Note tax documents by the entity in
              which you invested. To view your available tax documents, you may
              click the “<strong>Available</strong>” link above, or navigate to
              your <strong>My Documents</strong> section of your investor
              portal.
            </div>
          )
        });
      }
    }

    return rows;
  };

  return (
    <Table
      className={styles.table}
      columnHeaders={tableColumnLabels}
      rows={getRows()}
      allowSorting
    />
  );
};

export default TaxDocumentTable;
