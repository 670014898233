import { AnyAction } from 'redux';

import * as actions from '../actions/investment-account';

const initialState = {
  loading: false,
  account: [],
  loadingInvestmentAccount: false
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.LOAD_ALL:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case actions.LOAD_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        account: action.result
      };

    case actions.LOAD_ALL_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        loadErrors: action.error
      };

    case actions.CHECK_INVESTMENT_FAIL:
      return {
        ...state,
        isForbidden: action.error.body.message === 'Forbidden',
        errors: action.error.body.errors
      };

    default:
      return state;
  }
};
