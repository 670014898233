import { Button, Card } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';

import * as styles from './NotFound.module.scss';

const NotFound: React.FC = () => {
  useEffect(() => {
    document.title = 'Not Found | EquityMultiple';
  }, []);

  return (
    <div>
      <Container className={styles.notFound}>
        <Card>
          <h3 className="margin-top-0">
            The page you are looking for doesn't exist.
          </h3>
          <p>
            You might have typed the wrong address or the page has moved. In the
            meantime, try again or return to the home page.
          </p>
          <Button href="/">Back to Home</Button>
        </Card>
      </Container>
    </div>
  );
};

export default NotFound;
