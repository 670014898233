import {
  validations,
  yupString,
  yupStringArray
} from 'utilities/yupValidations';
import * as yup from 'yup';

const { fields } = validations;

export const startSchema = yup.object().shape({
  accepted_tos_and_pp: fields.requireTrue,
  email: fields.email
});

export const nameSchema = yup.object().shape({
  first_name: fields.firstName,
  last_name: fields.lastName
});

export const accreditationSchema = yup.object().shape({
  accreditation: fields.accreditation
});

export const phoneSchema = yup.object().shape({
  country_code: yupString.required('Country code is required'),
  phone: fields.phone
});

export const discoverSchema = yup.object().shape({
  attribution: yupString.required('Source is required'),
  attribution_additional_influences: yupString.notRequired(),
  attribution_advertisement_source: yupStringArray.when('attribution', {
    is: val => val === 'Advertisements',
    then: () => yupStringArray.min(1, 'Advertisement source is required')
  }),
  attribution_detail_source: yupStringArray.when('attribution', {
    is: val => val === 'Marcus and Millichap Relationship',
    then: () => yupString.required('Description is required')
  }),
  attribution_research_source: yupStringArray.when('attribution', {
    is: val => val === 'Online Research',
    then: () => yupStringArray.min(1, 'Research source is required')
  })
});

export const emailVerifySchema = yup.object().shape({
  unconfirmed_email: fields.email
});
