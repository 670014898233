import React from 'react';
import { Link } from 'react-router-dom';

import * as sharedStyles from '../../InvestShared.module.scss';
import * as styles from './NoDealsCard.module.scss';

type Props = {
  approach: string;
};

const NoDealsCard = ({ approach }: Props) => {
  let heading = approach;
  if (approach === 'Funds') heading = 'Fund';
  else if (approach === 'Alternative to Savings')
    heading = 'Alternative to Savings';

  return (
    <div className={`${sharedStyles.investPageCard} ${styles.noDealsCard}`}>
      <h4 className="margin-top-0">
        Why are there no active {heading} offerings?
      </h4>
      <p>
        You&apos;ll notice there are no {approach} offerings currently available
        for investment.
        {approach === 'Alternative to Savings'
          ? ' These investments tend to fill up quickly, and we are hoping to bring more to the platform soon.'
          : ' As you know, we conduct extensive underwriting on each potential offering. We remain committed to quality over quantity as we grow our portfolio and continue to source compelling opportunities for you.'}
      </p>
      <p>We hope to have one or more exciting offerings live very soon.</p>
      <p>
        We will notify you when new offerings go live. To stay up-to-date, make
        sure you have reviewed and updated your{' '}
        <Link className="underline" to="/settings/notification/">
          notification settings
        </Link>
        .
      </p>
    </div>
  );
};

export default NoDealsCard;
