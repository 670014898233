import { StatusLabel } from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';

import { PendingProps } from '../types';
import * as styles from './PendingInvites.module.scss';

type Props = {
  pending: PendingProps[];
  resendInvite: (
    arg1: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    arg2: PendingProps
  ) => void;
  resending: boolean;
};

const PendingInvites = ({ pending, resending, resendInvite }: Props) => {
  const pendingHtml = pending.map(invitationDetails => (
    <Row key={invitationDetails.id} align="center">
      <Col md={2} sm={2}>
        <div className={`${styles.pendingInviteImg} text-center`}>
          <span>{invitationDetails.email[0]}</span>
        </div>
      </Col>
      <Col sm={6}>
        <div className={styles.smallText}>
          {' '}
          Invitation sent to {invitationDetails.email},
          {invitationDetails.invitation_sent_at}
        </div>
        <button
          disabled={resending}
          onClick={e => resendInvite(e, invitationDetails)}
          type="button"
          className={`text-link ${styles.resendButton}`}
        >
          Resend
        </button>
      </Col>
      <Col md={4} sm={4}>
        <StatusLabel color="#ef5b09" label="Pending" />
      </Col>
    </Row>
  ));

  return (
    <section className={`${styles.inviteMetaDetails} ${styles.pendingInvites}`}>
      <Row>
        <Col xs={12}>
          <div className={styles.metaBlock}>
            <p className={styles.metaTitle}>
              Pending - {pending.length} of my invites{' '}
            </p>
          </div>
        </Col>
      </Row>

      {pending.length > 0 ? (
        <div>{pendingHtml}</div>
      ) : (
        <p>No Pending Invites</p>
      )}
    </section>
  );
};

export default PendingInvites;
