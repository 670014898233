export const FETCH_TAX_OFFERING_STATUSES =
  'taxOfferingStatuess/FETCH_TAX_OFFERING_STATUSES';
export const FETCH_TAX_OFFERING_STATUSES_SUCCESS =
  'taxOfferingStatuess/FETCH_TAX_OFFERING_STATUSES_SUCCESS';
export const FETCH_TAX_OFFERING_STATUSES_FAIL =
  'taxOfferingStatuess/FETCH_TAX_OFFERING_STATUSES_FAIL';

export function loadTaxOfferingStatuses() {
  return {
    types: [
      FETCH_TAX_OFFERING_STATUSES,
      FETCH_TAX_OFFERING_STATUSES_SUCCESS,
      FETCH_TAX_OFFERING_STATUSES_FAIL
    ],
    promise: client => client.get('/mkt/offering_tax_statuses')
  };
}
