import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { applyMiddleware, createStore as _createStore } from 'redux';

import ApiClient from './helpers/ApiClient';
import clientMiddleware from './middleware/clientMiddleware';
import reducer from './reducers';

export default function createStore(initialState) {
  const client = new ApiClient();
  const middleware = [clientMiddleware(client)];

  const enhancer = composeWithDevToolsDevelopmentOnly(
    applyMiddleware(...middleware)
  );

  const store = _createStore(reducer, initialState, enhancer);

  const next = store.dispatch;
  store.dispatch = function dispatchAndLog(action) {
    return next(action);
  };

  return store;
}
