import { Tab, TabPanel, Tabs, TabsList } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ClosingStage } from 'types/api/closing';
import { Post } from 'types/api/post';
import { Dispatch } from 'types/redux';

import {
  clearAccount,
  clearInvestment,
  getAccount,
  getInvestment
} from '../../../redux/actions/portfolio';
import { loadPosts } from '../../../redux/actions/posts';
import InvestmentDocuments from '../components/InvestmentDocuments/InvestmentDocuments';
import InvestmentOM from '../components/InvestmentOM/InvestmentOM';
import InvestmentPayments from '../components/InvestmentPayments/InvestmentPayments';
import InvestmentPosts from '../components/InvestmentPosts/InvestmentPosts';
import Overview from '../components/Overview/Overview';
import { Account, SingleInvestment } from '../types';
import * as styles from './Investment.module.scss';

type Params = {
  account_id: string;
  investment_id: string;
};

interface Props {
  account: Account;
  dispatch: Dispatch;
  investment: SingleInvestment;
  loadingAccount: boolean;
  loadingInvestment: boolean;
  loadingPosts: boolean;
  posts: Post[];
}

const Investment = ({
  account,
  dispatch,
  investment,
  loadingAccount,
  loadingInvestment,
  loadingPosts,
  posts
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  useEffect(() => {
    document.title = 'Investment | EquityMultiple';
    const investmentId = params.investment_id;
    const accountId = params.account_id;

    dispatch(getAccount(accountId));

    dispatch(getInvestment(investmentId))
      .then(res => {
        const offeringId = res.offering.id;
        const loadPostParams = {
          offeringIds: [offeringId],
          pageSize: 999
        };

        dispatch(loadPosts(loadPostParams));
      })
      .catch(() => navigate('/portfolio'));

    return () => {
      dispatch(clearInvestment());
      dispatch(clearAccount());
    };
  }, [dispatch, navigate, params]);

  const closingStage = investment?.offering?.closing_stage as ClosingStage;

  return (
    <div>
      <Overview
        account={account}
        closingStage={closingStage}
        heading={investment?.offering?.title}
        investmentMetrics={investment?.metrics}
        loading={loadingInvestment || loadingAccount}
        page="investment"
        className={styles.overview}
      />
      <Container>
        <Tabs defaultValue={0}>
          <TabsList className={styles.tabsList} collapseBreakpoint="md">
            <Tab value={0} disabled={loadingInvestment}>
              Investment Activity
            </Tab>
            <Tab value={1} disabled={loadingInvestment}>
              Payment Activity
            </Tab>
            <Tab value={2} disabled={loadingInvestment}>
              Offering Memorandum
            </Tab>
            <Tab value={3} disabled={loadingInvestment}>
              Investment Documents
            </Tab>
          </TabsList>
          <TabPanel value={0}>
            <InvestmentPosts
              loading={loadingInvestment || loadingPosts}
              posts={posts}
              key="posts"
            />
          </TabPanel>
          <TabPanel value={1}>
            <InvestmentPayments investment={investment} key="payments" />
          </TabPanel>
          <TabPanel value={2}>
            <InvestmentOM offering={investment?.offering} key="om" />
          </TabPanel>
          <TabPanel value={3}>
            <InvestmentDocuments investment={investment} key="documents" />
          </TabPanel>
        </Tabs>
      </Container>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    account: store.portfolio.account,
    investment: store.portfolio.investment,
    posts: store.posts.posts,
    loadingAccount: store.portfolio.loadingAccount,
    loadingInvestment: store.portfolio.loadingInvestment,
    loadingPosts: store.posts.loading
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Investment);
