import {
  Button,
  ErrorLabel,
  RadioButton,
  Tooltip
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createInvestmentAccount,
  loadInvestmentAccounts
} from 'redux/actions/account';
import { loadAuth } from 'redux/actions/auth';
import { SingleInvestmentAccount } from 'types/api/account';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { setRadioFieldProps } from 'utilities/formHelpers';
import { mustCompleteProfile } from 'utilities/user';
import utils from 'utilities/utils';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { accountStatus } from '../../contents';
import { hasShellInvestmentAccount, newAccountRoute } from '../../helpers';
import { newAccountSchema } from '../../validation';
import AccountWrap from '../AccountWrap/AccountWrap';
import BackLink from '../BackLink/BackLink';

interface Props {
  dispatch: Dispatch;
  investmentAccounts: SingleInvestmentAccount[];
  loading: boolean;
  user: User;
}

interface FormFields {
  investment_account: {
    type: 'individual' | 'entity' | 'joint account' | 'ira';
  };
}

const NewAccount = ({ investmentAccounts, loading, dispatch, user }: Props) => {
  const navigate = useNavigate();
  const [incompleteIndividual, setIncompleteIndividual] = useState(false);
  const [incompleteEntity, setIncompleteEntity] = useState(false);
  const [shellEntity, setShellEntity] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    watch
  } = useForm<FormFields>({
    resolver: yupResolver(newAccountSchema)
  });

  const selectedType = watch('investment_account.type');

  useEffect(() => {
    document.title = 'My Accounts | Account Options';
    dispatch(loadAuth())
      .then(userRes => {
        if (mustCompleteProfile(userRes)) navigate('/');
      })
      .catch(error => handleErrorResponse(navigate, error));
    dispatch(loadInvestmentAccounts())
      .then(accounts => {
        if (accounts) {
          const hasIncompleteEntity = accounts.some(
            accountToCheck =>
              accountToCheck.type === 'entity' &&
              accountToCheck.status !== accountStatus.created
          );
          if (hasShellInvestmentAccount(accounts, 'individual'))
            setIncompleteIndividual(true);
          if (hasIncompleteEntity) setIncompleteEntity(true);
          if (hasShellInvestmentAccount(accounts, 'entity'))
            setShellEntity(true);
        }
      })
      .catch(error => handleErrorResponse(navigate, error));
  }, [dispatch, navigate]);

  const onSubmit = (values: FormFields) => {
    const accountType = values.investment_account.type;
    if (
      accountType === 'individual' ||
      (accountType === 'entity' && shellEntity)
    ) {
      return dispatch(createInvestmentAccount(values))
        .then(res => {
          EmAnalytics.track('Opens Investment Account', 'Onboarding', {
            account_type: utils.startCase(accountType)
          });

          const referenceId = res.investment_account.reference_id;
          navigate(newAccountRoute(accountType, referenceId));
        })
        .catch(res => throwReactHookFormSubmissionErrors(res, setError));
    } else {
      navigate(newAccountRoute(accountType));
    }
  };

  const isUsResident = user?.investor_profile?.residence_status !== 'Other';
  const backLinkRoute =
    investmentAccounts?.length < 2 &&
    hasShellInvestmentAccount(investmentAccounts)
      ? '/'
      : '/accounts';

  let canOpenIra = true;
  if (
    selectedType === 'ira' &&
    !investmentAccounts?.some(
      account =>
        account.status === accountStatus.created &&
        (account.type === 'individual' || account.type === 'entity')
    )
  )
    canOpenIra = false;

  let entityCount = 0;

  if (investmentAccounts)
    entityCount = investmentAccounts.filter(
      account => account.type === 'entity'
    ).length;

  let canOpenEntity = true;
  if (
    selectedType === 'entity' &&
    ((incompleteEntity && isUsResident) ||
      (incompleteEntity && entityCount > 1 && !isUsResident))
  )
    canOpenEntity = false;

  return (
    <AccountWrap hideHeader loading={loading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="margin-top-0">Select your account type</h4>
        <p>
          Open an Investment Account to easily make investments when you
          identify an Offering that aligns with your investing strategy. Please
          note that under SEC rules, each Investment Account must qualify as an
          accredited investor.
          <Tooltip
            tooltipContent={
              <span>
                The federal securities laws define an accredited investor as any
                of the following: (i) a natural person with income exceeding
                $200,000 in each of the two most recent years or joint income
                with a spouse exceeding $300,000 for those years and a
                reasonable expectation of the same income level in the current
                year; (ii) a […]
                <a
                  href="https://www.investor.gov/additional-resources/news-alerts/alerts-bulletins/investor-bulletin-accredited-investors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </span>
            }
            infoIcon
            className="info-icon-margin-left"
          />
        </p>
        {!isUsResident && (
          <p>
            Foreign nationals can only invest with us through an accredited U.S.
            entity.
          </p>
        )}
        <div className="margin-x">
          <Controller
            name="investment_account.type"
            control={control}
            render={({ field }) => (
              <>
                {incompleteIndividual && isUsResident && (
                  <RadioButton
                    {...setRadioFieldProps(field, errors, 'individual')}
                    label="Individual Account"
                  />
                )}
                <RadioButton
                  {...setRadioFieldProps(field, errors, 'entity')}
                  label="Entity Account"
                />
                {isUsResident && (
                  <div>
                    <RadioButton
                      {...setRadioFieldProps(field, errors, 'joint account')}
                      label="Joint Account"
                    />
                    <RadioButton
                      {...setRadioFieldProps(field, errors, 'ira')}
                      label="Individual Retirement Account (IRA)"
                    />
                  </div>
                )}
              </>
            )}
          />
        </div>
        {!canOpenIra && (
          <ErrorLabel message="You must open an individual or entity account before opening an IRA account." />
        )}
        {!canOpenEntity && (
          <ErrorLabel message="You can only link one entity account at a time.  Please verify that all current accounts are in good standing prior to adding another." />
        )}

        <FormError errors={errors} />

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            disabled={!selectedType || !canOpenIra || !canOpenEntity}
            loading={isSubmitting}
            variant="orange"
            className="button-fixed-width"
            data-testid="newAccountButton"
          >
            Continue
          </Button>
          <BackLink route={backLinkRoute} />
        </div>
      </form>
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.account.loading,
    investmentAccounts: state.account.investmentAccounts,
    user: state.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(NewAccount);
