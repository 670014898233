import { addressValidation } from 'containers/Profile/validation';
import { validations, yupString } from 'utilities/yupValidations';
import * as yup from 'yup';

const { fields, tests } = validations;

export const profileSchema = yup.object().shape({
  address_attributes: addressValidation,
  country_code: fields.countryCode,
  first_name: fields.firstName,
  investor_profile_attributes: yup.object().shape({
    accreditation: fields.accreditation,
    country_of_citizenship: fields.countryOfCitizenship,
    dob: fields.dob.test(tests.ageOver18),
    investment_experience: fields.investmentExperience,
    investment_objectives: fields.investmentObjectives,
    investment_reason: fields.investmentReason,
    occupation: fields.occupation,
    residence_status: fields.residenceStatus,
    risk_tolerance: fields.riskTolerance,
    ssn: fields.ssn
  }),
  last_name: fields.lastName,
  phone: fields.phone
});

export const personalIdSchema = yup.object().shape({
  id_document: fields.idDocument,
  id_expiration: fields.idExpiration,
  id_type: fields.idType,
  passport: fields.passport
});

export const updateEmailSchema = yup.object().shape({
  unconfirmed_email: yupString
    .email('Must be a valid email address')
    .required('New email address is required')
});

export const updatePasswordSchema = yup.object().shape({
  confirmPassword: fields.confirmPassword,
  current_password: yupString.required('Current password is required'),
  password: fields.password
});

export const phoneSchema = yup.object().shape({
  country_code: yupString.required('Country code is required'),
  phone: fields.phone
});
