import { AnyAction } from 'redux';

import * as actions from '../actions/capital-call';

const initialState = {
  loading: false,
  sending: false,
  capitalCalls: []
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.OPT_IN:
    case actions.OPT_OUT:
    case actions.RUN_CAPITAL_CALL:
    case actions.CREATE_ADDITIONAL_INTEREST:
      return {
        ...state,
        sending: true
      };

    case actions.OPT_IN_SUCCESS:
    case actions.OPT_OUT_SUCCESS:
    case actions.RUN_CAPITAL_CALL_SUCCESS:
    case actions.CREATE_ADDITIONAL_INTEREST_SUCCESS:
      return {
        ...state,
        sending: false
      };

    case actions.OPT_IN_FAIL:
    case actions.OPT_OUT_FAIL:
    case actions.RUN_CAPITAL_CALL_FAIL:
    case actions.CREATE_ADDITIONAL_INTEREST_FAIL:
      return {
        ...state,
        sending: false
      };

    case actions.GET_ADDITIONAL_ALLOCATION:
    case actions.GET_BY_USER:
    case actions.GET_BY_INVESTMENT:
      return {
        ...state,
        loading: true
      };

    case actions.GET_BY_USER_SUCCESS:
      return {
        ...state,
        capitalCalls: action.result,
        loading: false
      };

    case actions.GET_ADDITIONAL_ALLOCATION_SUCCESS:
    case actions.GET_BY_INVESTMENT_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case actions.GET_ADDITIONAL_ALLOCATION_FAIL:
    case actions.GET_BY_USER_FAIL:
    case actions.GET_BY_INVESTMENT_FAIL:
      return {
        ...state,
        loading: false
      };

    default:
      return {
        ...state
      };
  }
};
