import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SignUpRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const newPathname = location.pathname.replace('/sign_up', '/signup/start');
    navigate(newPathname, { replace: true });
  }, [location, navigate]);

  return null;
};

export default SignUpRedirect;
