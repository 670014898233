import { IntroBackgroundColor } from 'containers/SignUp/types';
import Logo from 'images/logos/full-logo-blue.svg';
import React from 'react';

import * as styles from './IntroColumn.module.scss';

interface Props {
  backgroundColor: IntroBackgroundColor;
  children: React.ReactNode;
}

const IntroColumn = ({ backgroundColor, children }: Props) => {
  let backgroundClass = '';
  switch (backgroundColor) {
    case 'gray':
      backgroundClass = styles.grayBackground;
      break;
    case 'blue':
      backgroundClass = styles.blueBackground;
      break;
    case 'bark':
      backgroundClass = styles.barkBackground;
  }

  return (
    <div className={`${styles.wrap} ${backgroundClass}`}>
      <a href="//equitymultiple.com">
        <Logo />
      </a>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default IntroColumn;
