import countries from 'config/countries';
import occupations from 'config/occupations';
import states from 'config/states';

const ResidenceStatuses = [
  'U.S. Citizen',
  'U.S. Permanent Resident',
  'U.S. Resident',
  'Other'
];

export const experienceOptions = [
  {
    value: 'Rely on advisor/managed service',
    label: 'I rely extensively on a financial advisor or other managed service'
  },
  {
    value: 'Managed public assets',
    label:
      'I have experience managing public assets (stocks/bonds/treasuries) on my own behalf'
  },
  {
    value: 'Managed illiquid alternatives',
    label:
      'I have experience picking illiquid alternatives (passive investments) on my own behalf'
  },
  {
    value: 'Managed real estate',
    label:
      'I have experience transacting and managing real estate investments on my own behalf'
  },
  { value: 'Professional investor', label: 'I am a professional investor' }
];

export const objectivesOptions = [
  { value: 'Income', label: 'Income' },
  { value: 'Wealth', label: 'Wealth' }
];

export const reasonOptions = [
  {
    value: 'Alternative income/wealth sources',
    label:
      'To establish alternative income/wealth sources and achieve early financial freedom'
  },
  {
    value: 'Save for large purchase',
    label: 'To save for a large purchase (house, vacation home, etc.)'
  },
  { value: 'Retirement', label: 'To build a strong retirement portfolio' },
  {
    value: "Child's trust or education",
    label: "Capital appreciation for a child's trust or education"
  },
  {
    value: 'De-correlation from public assets',
    label: 'To achieve de-correlation from public assets'
  },
  { value: 'Tax advantages', label: 'For potential tax advantages' }
];

export const riskToleranceOptions = [
  {
    value: 'Moderate',
    label:
      'Moderate: I am willing to accept a moderate amount of risk including the potential loss of all capital invested for the potential of long or short term capital gain'
  },
  {
    value: 'High',
    label:
      'High (Risk Tolerant): I am willing to accept a high amount of risk including the potential loss of all capital invested for the potential of long or short term capital gain'
  },
  {
    value: 'Somewhat Risk Averse',
    label:
      'Somewhat Risk Averse: I am primarily concerned with preservation of capital, but willing to accept risk for certain compelling investment theses'
  },
  {
    value: 'Very Risk Averse',
    label: 'Very Risk Averse: I am solely focused on preservation of capital'
  }
];

export const residenceStatusOptions = ResidenceStatuses.map(
  residenceStatus => ({
    label: residenceStatus,
    value: residenceStatus
  })
);

export const countryOptions = countries.map(country => ({
  label: country.name,
  value: country.name
}));

export const occupationOptions = occupations.map(occupation => ({
  label: occupation,
  value: occupation
}));

export const stateOptions = Object.keys(states).map(state => ({
  label: state,
  value: state
}));

export const identificationDocumentOptions = [
  { label: 'Driver License', value: 'license' },
  { label: 'Passport', value: 'passport' },
  { label: 'Government Issued Identification', value: 'idCard' }
];
