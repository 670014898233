import logo from 'images/logos/full-logo-blue.svg?url';
import logoMark from 'images/logos/logomark-blue.svg?url';
import React from 'react';
import { Link } from 'react-router-dom';

import * as styles from './HeaderSimple.module.scss';

interface Props {
  className?: string;
  firstPage?: boolean;
}

const HeaderSimple = ({ className, firstPage }: Props) => (
  <div
    data-testid="HeaderSimple"
    className={`${styles.headerNav} ${className || ''}`}
  >
    <a href="https://www.equitymultiple.com">
      <img className={styles.defaultLogo} alt="EquityMultiple" src={logo} />
      <img className={styles.smallLogo} alt="EquityMultiple" src={logoMark} />
    </a>
    {firstPage && (
      <Link to="/users/sign_in" className={`text-link ${styles.signinLink}`}>
        Already Signed Up? Click here to login
      </Link>
    )}
  </div>
);

export default HeaderSimple;
