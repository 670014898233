/*
  IRR values are saved in various format e.g x, x% , x-x%
  This function takes original irr value and will return rounded value
*/
export const formatIrrForOfferingCard = irr => {
  let roundedValue = '';

  if (irr === 0) roundedValue = '0%';

  if (irr) {
    let irrString = irr.toString().trim();

    if (irrString.length) {
      irrString = irrString.replaceAll('%', '');
      const irrRange = irrString.split('-');

      if (irrRange?.length <= 2) {
        const roundedIRRRange = irrRange.map(val =>
          Math.round(parseFloat(val))
        );
        roundedValue = roundedIRRRange.join('-') + '%';
      }
    }
  }

  return roundedValue;
};
