import { Card, Tooltip } from '@equitymultiple/react-eui';
import queryString from 'query-string';
import React, { ReactElement, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { loadOffering } from '../../redux/actions/offerings';
import { Dispatch } from '../../types/redux';
import { adBlockerIntercomTooltip } from '../../utilities/constants';
import * as styles from './OfferingSignUp.module.scss';

interface Props {
  dispatch?: Dispatch;
  loading?: boolean;
  offering?: {
    card_image?: string;
    title?: string;
  };
}

const OfferingSignUp = ({ dispatch, loading, offering }: Props) => {
  const location = useLocation();

  let offeringId = null;

  const query = location.search ? queryString.parse(location.search) : null;
  if (query?.offering_sign_up_id) offeringId = query.offering_sign_up_id;

  const linkToSignIn = location.pathname === '/users/signup/start';

  useEffect(() => {
    if (offeringId) dispatch(loadOffering(offeringId));
  }, [dispatch, offeringId]);

  let signUpText: string | ReactElement = (
    <Link to="/users/signup/start">sign up</Link>
  );
  let signInText: string | ReactElement = 'sign in below';
  if (linkToSignIn) {
    signUpText = 'sign up below';
    signInText = <Link to="/users/sign_in">sign in</Link>;
  }

  const loadingFailed = !loading && !offering;

  return offeringId && !loadingFailed ? (
    <Card className={styles.offeringSignUp} data-testid="offering-sign-up">
      <div className={styles.flexWrap}>
        <div className={styles.imageWrap}>
          {!loading && <img src={offering.card_image} alt={offering.title} />}
        </div>
        <div className={styles.textWrap}>
          <h3>
            {loading ? (
              <Skeleton />
            ) : (
              <>
                Please {signUpText} or {signInText} to view all details of{' '}
                {offering.title}
              </>
            )}
          </h3>

          {loading ? (
            <Skeleton />
          ) : (
            <p>
              Signing up only takes a few minutes. Please contact our Investor
              Relations Team via the chat icon{' '}
              <Tooltip tooltipContent={adBlockerIntercomTooltip} infoIcon /> in
              the bottom right with any questions.
            </p>
          )}
        </div>
      </div>
    </Card>
  ) : null;
};

function mapStateToProps(state) {
  return {
    loading: state.offerings.loadingOffering,
    offering: state.offerings.offeringData
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(OfferingSignUp);
