import { Button, Select } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import Illustration from 'images/signup-illustration.svg';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { submitSignupForm } from 'redux/actions/onboarding';
import { updateUserProfile } from 'redux/actions/user-settings';
import { setFieldProps } from 'utilities/formHelpers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import SignUpWrap from '../components/SignUpWrap/SignUpWrap';
import { accreditationOptions } from '../content';
import { redirectIfPreviousStepsIncomplete } from '../helpers';
import { SharedSignupProps } from '../types';
import { accreditationSchema } from '../validation';
import * as styles from './Accreditation.module.scss';

interface FormFields {
  accreditation: string[];
  last_name: string;
}

const Accreditation = ({
  dispatch,
  loadingAuth,
  defaultValues,
  user
}: SharedSignupProps) => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError
  } = useForm<FormFields>({
    resolver: yupResolver(accreditationSchema),
    values: defaultValues as FormFields
  });

  useEffect(() => {
    document.title = 'Start Your Account | EquityMultiple';

    if (!loadingAuth)
      redirectIfPreviousStepsIncomplete(navigate, defaultValues, 4, user);
  }, [defaultValues, loadingAuth, user, navigate]);

  const onSubmit = values => {
    return dispatch(
      user
        ? updateUserProfile({
            investor_profile_attributes: {
              accreditation: values.accreditation
            }
          })
        : submitSignupForm({
            step: 4,
            user: {
              ...values,
              email: defaultValues.email
            }
          })
    )
      .then(() => {
        dispatch(loadAuth()).then(() => {
          navigate('/users/signup/continue');
        });
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  return (
    <SignUpWrap
      introBackgroundColor="blue"
      introContent={
        <>
          <Illustration className={styles.illustration} />
          <h1 className={styles.headingWithIllustration}>
            Helping You Climb Above Uncertainty.
          </h1>
          <p>
            Streamlined access to a range of real estate strategies. Easily
            diversify away from public markets. Invest in minutes, then relax
            and enjoy.
          </p>
        </>
      }
      step={4}
      user={user}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-xx">Are you an Accredited Investor?</h3>
        <p className="margin-xx">
          EquitMultiple accepts investments from Accredited Investors only. To
          help us understand which investment opportunities are best for you,
          please provide this information about your accredited status.
        </p>

        <Controller
          name="accreditation"
          control={control}
          render={({ field }) => (
            <Select
              {...setFieldProps(field, errors)}
              label="Select what describes you best"
              options={accreditationOptions}
              multi
              showMultiLabelsBelow
              data-testid="accreditation"
            />
          )}
        />

        <FormError errors={errors} />

        <Button
          type="submit"
          loading={isSubmitting}
          variant="orange"
          className="button-fixed-width margin-top-xx"
        >
          Continue
        </Button>
      </form>
    </SignUpWrap>
  );
};

function mapStateToProps(store) {
  const signupValues = store.auth.signupValues;
  const user = store.auth.user;
  let defaultValues = signupValues;
  if (!store.auth.loading && user) {
    defaultValues = {
      email: user.email,
      accreditation: user.investor_profile.accreditation,
      first_name: user.first_name || signupValues.first_name,
      last_name: user.last_name || signupValues.last_name
    };
  }

  return {
    defaultValues,
    loadingAuth: store.auth.loading,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Accreditation);
