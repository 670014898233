import { Button } from '@equitymultiple/react-eui';
import React from 'react';
import { Container } from 'react-grid-system';

import * as styles from './DownloadPopUp.module.scss';

interface Props {
  downloadDocuments: () => void;
  loading: boolean;
  selectedCount: number;
}

const DownloadPopUp = ({
  selectedCount,
  downloadDocuments,
  loading
}: Props) => {
  return (
    <div className={styles.downloadBar} data-testid="downloadBar">
      <Container className="clearfix-after">
        <div className={styles.flexWrap}>
          <div>
            <i className="fa fa-download" />
            <span className={styles.selectedText}>
              {selectedCount} {selectedCount > 1 ? 'items' : 'item'} selected
            </span>
          </div>

          <Button
            className={styles.selectDownloadDocument}
            loading={loading}
            onClick={downloadDocuments}
          >
            Download
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default DownloadPopUp;
