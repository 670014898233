import { Card } from '@equitymultiple/react-eui';
import { InvestmentOffering } from 'containers/Portfolio/types';
import React from 'react';

import utils from '../../../../utilities/utils';
import { getSimplifiedOfferingType } from '../../helpers';
import * as styles from './OMDetails.module.scss';

interface Props {
  offering: InvestmentOffering;
}

const getOfferingMetrics = offering => {
  const type = getSimplifiedOfferingType(offering.offering_type);
  const isDebt = type === 'Debt';
  const isPrefEquity = type === 'Preferred Equity';
  const isFund = offering.perpetual_fund;
  const metrics = [];

  if (!isFund)
    metrics.push([
      'Investment Amount',
      utils.formatCurrency(offering.capital_request, 0)
    ]);

  metrics.push(['Asset Type', offering.asset_type]);

  if (isFund) metrics.push(['Redemption Period', offering.redemption_period]);
  else
    metrics.push([
      isDebt ? 'Term' : 'Target Hold',
      offering.term + ' ' + offering.term_period
    ]);

  // Equity has 3 metrics, debt & pref equity have 6

  if (isPrefEquity) {
    metrics.push(['Current Preferred Return*', `${offering.current_return}%`]);
    metrics.push([
      'Total Preferred Return*',
      `${offering.total_preferred_return}%`
    ]);
    metrics.push([
      offering.show_ltc ? 'Loan-to-Cost Ratio' : 'LTV',
      offering.ltv
    ]);
  }

  if (isDebt) {
    metrics.push(['Rate', `${offering.rate}%`]);
    metrics.push([
      offering.show_ltc ? 'Loan-to-Cost Ratio' : 'LTV',
      offering.ltv
    ]);
    metrics.push(['Security Position', offering.lien_position]);
  }

  return metrics;
};

const OMDetails = ({ offering }: Props) => {
  const metrics = getOfferingMetrics(offering);
  const type = getSimplifiedOfferingType(offering.offering_type);
  const isPrefEquity = type === 'Preferred Equity';

  return (
    <div className={styles.omDetails}>
      <Card>
        <div className={`text-label ${styles.textLabel}`}>Offering Terms</div>
        {metrics.map(metric => (
          <div className={styles.metric} key={metric[0]}>
            <div className={styles.name}>{metric[0]}</div>
            <div className={styles.value}>{metric[1]}</div>
          </div>
        ))}
        {offering.investor_packet_url && (
          <div className={styles.metric}>
            <a
              href={offering.investor_packet_url}
              target="_blank"
              rel="noreferrer"
            >
              View Investor Packet
            </a>
          </div>
        )}
      </Card>
      {isPrefEquity && (
        <div className={styles.disclaimer}>
          *Preferred returns are not guaranteed
        </div>
      )}
    </div>
  );
};

export default OMDetails;
