// extracted by mini-css-extract-plugin
var _1 = "N45D3Lfa1MUIcSmlpHpX";
var _2 = "vk8prPn47hIi9uNGt7NJ";
var _3 = "iYh4Fs9OizTT_apeYLKU";
var _4 = "TlzNoRwCN4pWdtcSH_Rv";
var _5 = "X2NyuWyb49cKIFpdgygR";
var _6 = "JMoNGwQe8RMWz_JS7niD";
var _7 = "Mg7b1njFJAgbqmj4Zf6w";
var _8 = "Hd0MFFynKI11X8VbdS6c";
export { _1 as "inviteMetaDetails", _2 as "labelYellow", _3 as "metaBlock", _4 as "metaTitle", _5 as "pendingInviteImg", _6 as "pendingInvites", _7 as "resendButton", _8 as "smallText" }
