import { Button, Tooltip } from '@equitymultiple/react-eui';
import { SignUpStage } from 'components/AccountProgressContainer/types';
import {
  getAccountCompletionPercentage,
  getFirstIncompleteStepRoute,
  getIncompleteAccountLink,
  profileSteps,
  signupSteps
} from 'containers/Accounts/helpers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadInvestmentAccounts } from 'redux/actions/account';
import { SingleInvestmentAccount } from 'types/api/account';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import {
  getLastCompletedStep,
  hasCompletedProfile,
  hasCompletedSignup,
  isAccredited
} from 'utilities/user';

import * as styles from './AccountProgress.module.scss';

const signupStepCount = signupSteps.length;
const profileStepCount = profileSteps.length;

const signupStepsForCompletionPercentage = [...signupSteps, ...profileSteps];

const getTotalCompletionPercentage = (
  user: User,
  investmentAccountCompletionPercentage: number
) => {
  if (investmentAccountCompletionPercentage) {
    return Math.ceil(66.66 + investmentAccountCompletionPercentage * 0.33);
  } else {
    const totalStepCount = signupStepCount + profileStepCount;
    const lastCompletedStep = getLastCompletedStep(user);
    const completedStepCount =
      signupStepsForCompletionPercentage.indexOf(lastCompletedStep) + 1;

    return Math.floor(66.66 * (completedStepCount / totalStepCount));
  }
};

const getSignupCompletionPercentage = (user: User) => {
  const lastCompletedStep = getLastCompletedStep(user);
  const completedStepCount = signupSteps.indexOf(lastCompletedStep) + 1;

  return Math.floor((completedStepCount / signupStepCount) * 100);
};

const getProfileCompletionPercentage = (user: User) => {
  const lastCompletedStep = getLastCompletedStep(user);
  const completedStepCount = profileSteps.indexOf(lastCompletedStep) + 1;

  return Math.floor((completedStepCount / profileStepCount) * 100);
};

interface Props {
  account?: SingleInvestmentAccount;
  dispatch: Dispatch;
  isComplete?: boolean;
  showSkipLink?: boolean;
  signupStage?: SignUpStage | 'invest';
  user: User;
}

const AccountProgress = ({
  account,
  dispatch,
  isComplete,
  signupStage,
  showSkipLink,
  user
}: Props) => {
  const onInvestPage = signupStage === 'invest';
  const notAccredited = !isAccredited(user);
  const [accountInProgress, setAccountInProgress] = useState(null);

  // Checking for the accreditation field on both of these to account for legacy users
  const signupComplete = hasCompletedSignup(user);
  const profileComplete = hasCompletedProfile(user);

  useEffect(() => {
    // If the user is on the invest page and has one account in progress then use that account to determine
    // their completion percentage. If they have more than one then we can't determine which account to use
    // and are unable to show account completion percentage.
    if (onInvestPage && profileComplete) {
      dispatch(loadInvestmentAccounts()).then(accounts => {
        if (accounts.length === 1) setAccountInProgress(accounts[0]);
      });
    }
  }, [dispatch, onInvestPage, profileComplete]);

  useEffect(() => {
    if (account) setAccountInProgress(account);
  }, [account]);

  let investmentAccountCompletionPercentage = 0;

  if (accountInProgress)
    investmentAccountCompletionPercentage = isComplete
      ? 100
      : getAccountCompletionPercentage(accountInProgress);
  const investmentAccountProgressBarWidth =
    investmentAccountCompletionPercentage;

  const totalCompletionPercentage = getTotalCompletionPercentage(
    user,
    investmentAccountCompletionPercentage
  );

  const signupProgressBarWidth = signupComplete
    ? 100
    : getSignupCompletionPercentage(user);

  let profileProgressBarWidth = 0;
  if (signupComplete) {
    profileProgressBarWidth = profileComplete
      ? 100
      : getProfileCompletionPercentage(user);
  }

  let progressCircleColor = '#e25201';
  if (signupComplete) progressCircleColor = '#169dca';
  if (profileComplete) progressCircleColor = '#4caf50';

  const getButtonDetails = () => {
    let route = '/';
    let text = 'Finish Later';

    if (notAccredited) {
      route = '/users/signup/accreditation';
      text = 'Update Accreditation Status';
    } else {
      if (onInvestPage) {
        route = getFirstIncompleteStepRoute(user);

        if (profileComplete) {
          // User has multiple accounts, send them to the accounts page
          route = '/accounts';

          if (accountInProgress) {
            route = getIncompleteAccountLink(accountInProgress);

            // User has not started their individual account yet, send them to the new account page
            if (
              accountInProgress.type === 'individual' &&
              route.indexOf('w9') > -1
            ) {
              route = '/accounts/new';
            }
          }
          text = 'Complete My Account';
        } else if (signupComplete) {
          text = 'Complete My Profile';
        } else {
          text = 'Finish Signup';
        }
      }
    }

    return {
      route,
      text
    };
  };

  const buttonDetails = getButtonDetails();
  const showButton = (!isComplete && showSkipLink) || onInvestPage;

  return (
    <div
      className={`${styles.wrap} ${onInvestPage ? styles.invest : ''} ${notAccredited ? styles.notAccredited : ''}`}
      data-testid="accountProgress"
    >
      <div className={styles.top}>
        <div className={styles.circle}>
          <svg viewBox="-1 -1 34 34">
            <circle
              className={styles.progressTrack}
              cx="16"
              cy="16"
              r="15.9155"
            />
            <circle
              data-testid="progressCircle"
              className={styles.progress}
              cx="16"
              cy="16"
              r="15.9155"
              style={{
                strokeDashoffset: 100 - totalCompletionPercentage,
                stroke: progressCircleColor
              }}
            />
          </svg>
          <div className={styles.percentage}>{totalCompletionPercentage}%</div>
        </div>
        <div className={styles.topText}>
          <div className={styles.account}>Account</div>
          <div className={styles.inProgress}>
            {notAccredited ? (
              <>
                Not Accredited
                <Tooltip
                  tooltipContent="EquityMultiple is a platform for Accredited investors. If you believe you are accredited update your status to proceed."
                  infoIcon
                  infoIconColor="#eb5424"
                  className="info-icon-margin-left"
                />
              </>
            ) : isComplete ? (
              'Complete'
            ) : (
              'In Progress'
            )}
          </div>
          <div className={styles.description}>
            It takes about 10-15 minutes to complete a profile and begin
            investing.
          </div>
        </div>
      </div>

      <div className={styles.steps}>
        <div
          className={`${styles.step} ${signupStage === 'sign up' ? styles.activeStep : ''}`}
        >
          <div className={styles.stepTitle}>Sign Up</div>
          <div className={styles.stepDescription}>
            Finish this step to begin viewing investment opportunities
          </div>
          <div className={styles.stepProgressTrack}>
            <div
              data-testid="signupProgress"
              className={`${styles.stepProgress} ${signupComplete ? styles.stepProgressComplete : ''}`}
              style={{ width: `${signupProgressBarWidth}%` }}
            />
          </div>
        </div>

        <div
          className={`${styles.step} ${styles.profileStep} ${signupStage === 'profile' ? styles.activeStep : ''}`}
        >
          <div className={styles.stepTitle}>Profile</div>
          <div className={styles.stepDescription}>
            Tell us more about yourself so we can verify your identity
          </div>
          <div className={styles.stepProgressTrack}>
            <div
              data-testid="profileProgress"
              className={`${styles.stepProgress} ${profileComplete ? styles.stepProgressComplete : ''}`}
              style={{ width: `${profileProgressBarWidth}%` }}
            />
          </div>
        </div>

        <div
          className={`${styles.step} ${styles.accountStep} ${signupStage === 'investment account' ? styles.activeStep : ''}`}
        >
          <div className={styles.stepTitle}>Investment Account</div>
          <div className={styles.stepDescription}>
            Create an investment account to begin investing
          </div>
          <div className={styles.stepProgressTrack}>
            <div
              data-testid="investmentAccountProgress"
              className={`${styles.stepProgress} ${isComplete ? styles.stepProgressComplete : ''}`}
              style={{ width: `${investmentAccountProgressBarWidth}%` }}
            />
          </div>
        </div>
      </div>

      {showButton && (
        <Button
          className={styles.button}
          wrapper={<Link to={buttonDetails.route} />}
          variant={notAccredited ? 'orange' : 'outlined'}
          data-testid="actionButton"
        >
          {buttonDetails.text}
        </Button>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect()(AccountProgress);
