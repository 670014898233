import { Card, Select } from '@equitymultiple/react-eui';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Post } from 'types/api/post';

import PostCard from '../../../../components/PostCard/PostCard';
import utils from '../../../../utilities/utils';
import * as styles from './InvestmentPosts.module.scss';

interface Props {
  loading?: boolean;
  posts?: Post[];
}

const InvestmentPosts = ({ loading, posts }: Props) => {
  const postsRef = useRef<HTMLDivElement>(null);
  const [postTypeOptions, setPostTypeOptions] = useState([]);
  const [visiblePostType, setVisiblePostType] = useState('all');

  const setPostTypes = () => {
    const postTypes = [];

    posts.forEach(post => {
      const postType = post.label;
      if (!postTypes.includes(postType)) postTypes.push(postType);
    });

    const newPostTypeOptions = postTypes.map(postType => ({
      label: utils.snakeCaseToTitleCase(postType),
      value: postType
    }));

    newPostTypeOptions.unshift({
      label: 'All Post Types',
      value: 'all'
    });

    setPostTypeOptions(newPostTypeOptions);
  };

  useEffect(() => {
    if (posts?.length > 0) setPostTypes();
  }, [posts]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPostDescription = post => {
    const label = post.label;
    const author = post.creator.name;
    const description = author + ' posted ';
    let descriptionLabel = '';
    switch (label) {
      case 'investment_update':
        descriptionLabel = 'an investment';
        break;
      case 'general':
        descriptionLabel = 'a general';
        break;
      case 'tax':
        descriptionLabel = 'a tax';
        break;
    }

    return description + descriptionLabel + ' update';
  };

  const renderMonthSection = section => (
    <div
      className={styles.monthSection}
      key={`${section.month}-${section.year}`}
      data-testid="monthSection"
    >
      <div className={styles.monthYear}>
        <div className={styles.month} data-testid="month">
          {section.month}
        </div>
        <div data-testid="year">{section.year}</div>
      </div>
      <div className={styles.posts}>
        {section.posts.map(post => (
          <div className={styles.postWrap} key={post.id}>
            <div className={styles.timeline}>
              <div className={styles.line} />
              <div className={styles.dot} />
            </div>
            <div className={styles.postRight}>
              <div className={styles.postMeta}>
                <div className={styles.description}>
                  {getPostDescription(post)}
                </div>
                <div className="margin-x">
                  {moment(post.post_date).format('MM/DD/YYYY h:mm A')}
                </div>
              </div>
              <PostCard post={post} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderPosts = () => {
    if (posts?.length) {
      const sections = [];

      posts.forEach(post => {
        if (['all', post.label].includes(visiblePostType)) {
          const postDate = moment(post.post_date);
          const month = postDate.format('MMMM');
          const year = postDate.format('YYYY');

          const matchingMonth = sections.find(
            section => section.month === month && section.year === year
          );

          if (matchingMonth) {
            matchingMonth.posts.push(post);
          } else {
            sections.push({
              month,
              year,
              posts: [post]
            });
          }
        }
      });

      return sections.map(section => renderMonthSection(section));
    }
    return <h4>No posts found for this investment.</h4>;
  };

  const handlePostTypeChange = value => {
    setVisiblePostType(value);
    if (window.innerWidth > 991) {
      const postsTop =
        postsRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: postsTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div ref={postsRef} className={styles.investmentPosts}>
      <Row>
        <Col lg={3}>
          <Card className={styles.sidebar}>
            <p>Filter by post type:</p>
            <Select
              id="post-type"
              name="post-type"
              onChange={value => handlePostTypeChange(value)}
              label="Post Type"
              options={postTypeOptions}
              value={visiblePostType}
              disabled={loading}
              className="margin-bottom-0"
            />
          </Card>
        </Col>
        <Col lg={9}>{loading ? <PostCard loading /> : renderPosts()}</Col>
      </Row>
    </div>
  );
};

export default InvestmentPosts;
