import { DateSelect, Input, Select } from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { setFieldProps } from 'utilities/formHelpers';
import {
  percentageMask,
  percentageMaskOptions,
  phoneMask,
  ssn,
  ssnMaskOptions
} from 'utilities/masks';

import { stateOptions } from '../contents';
import { BeneficialOwnerFields as BeneficialOwnerFieldsType } from '../types';

interface Props {
  control: Control<BeneficialOwnerFieldsType>;
  errors: FieldErrors<BeneficialOwnerFieldsType>;
  hasSsn: boolean;
}

const BeneficialOwnerFields = ({ control, errors, hasSsn }: Props) => {
  return (
    <>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            name="beneficial_owner.title"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Title" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="beneficial_owner.percentage_ownership"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Percentage Ownership"
                mask={percentageMask}
                inputMaskOptions={percentageMaskOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            name="beneficial_owner.first_name"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="First Name" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="beneficial_owner.last_name"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Last Name" />
            )}
          />
        </Col>
      </Row>
      <Row className="margin-row">
        <Col md={7} className="overflow-visible">
          <Controller
            name="beneficial_owner.dob"
            control={control}
            render={({ field }) => (
              <DateSelect
                {...setFieldProps(field, errors)}
                label="Date of Birth"
              />
            )}
          />
        </Col>
        <Col md={5}>
          <Controller
            name="beneficial_owner.ssn"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="SSN"
                disabled={hasSsn}
                mask={ssn}
                inputMaskOptions={ssnMaskOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            name="beneficial_owner.email"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Email" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="beneficial_owner.phone"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                mask={phoneMask}
                label="Phone Number"
              />
            )}
          />
        </Col>
      </Row>
      <h5>Address Information</h5>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            name="beneficial_owner.address"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Address" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="beneficial_owner.address2"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address 2"
                placeholder="Apartment number, etc."
              />
            )}
          />
        </Col>
      </Row>
      <Row className="margin-row">
        <Col md={4}>
          <Controller
            name="beneficial_owner.city"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="City" />
            )}
          />
        </Col>
        <Col md={4} className="overflow-visible">
          <Controller
            name="beneficial_owner.state"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="State"
                options={stateOptions}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            name="beneficial_owner.postal_code"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                mask="99999"
                label="Postal Code"
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default BeneficialOwnerFields;
