import { yupResolver } from '@hookform/resolvers/yup';
import { AccountHolder2Fields } from 'containers/Accounts/types';
import { jointSecondaryHolderSchema } from 'containers/Accounts/validation';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createInvestmentAccount } from 'redux/actions/account';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import AccountWrap from '../AccountWrap/AccountWrap';
import JointSecondaryHolderFields from './JointSecondaryHolderFields';

interface Props {
  dispatch: Dispatch;
}

const NewJointSecondaryHolder = ({ dispatch }: Props) => {
  const navigate = useNavigate();

  const defaultValues = {
    investment_account: {
      account_holder_2_address_is_same: false
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    watch
  } = useForm<AccountHolder2Fields>({
    resolver: yupResolver(jointSecondaryHolderSchema),
    defaultValues
  });

  const sameAddress = watch(
    'investment_account.account_holder_2_address_is_same'
  );

  const onSubmit = values => {
    values.investment_account.type = 'joint account';
    return dispatch(createInvestmentAccount(values))
      .then(res => {
        const referenceId = res.investment_account.reference_id;
        EmAnalytics.track('Submits Joint Profile Information', 'Onboarding');
        navigate(
          `/accounts/wizard/joint/secondary_account/identification_document/${referenceId}`
        );
      })
      .catch(error => throwReactHookFormSubmissionErrors(error, setError));
  };

  return (
    <AccountWrap>
      <form onSubmit={handleSubmit(onSubmit)}>
        <JointSecondaryHolderFields
          backLinkRoute={'/accounts/new'}
          sameAddress={sameAddress}
          submitting={isSubmitting}
          control={control}
          errors={errors}
        />
      </form>
    </AccountWrap>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect()(NewJointSecondaryHolder);
