import {
  Address,
  DocumentIdentification,
  Experience,
  Identity,
  Objectives,
  ProfileName,
  ProfileStart,
  Tolerance
} from 'containers/Profile';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PaymentActivityRedirect from 'routerHelpers/PaymentActivityRedirect';
import ReferralRedirect from 'routerHelpers/ReferralRedirect';
import SignUpRedirect from 'routerHelpers/SignUpRedirect';

import {
  Activities,
  App,
  CancelInvestment,
  CapitalCall,
  ConfidentialityAgreement,
  DebtFundElections,
  DistributionReinvestmentElections,
  ForgotPassword,
  Fund,
  IndicateInterest,
  Invest,
  Invite,
  MyDocuments,
  NotFound,
  Offering,
  Portfolio,
  ResetPassword,
  Review,
  Setting,
  Sign,
  SignIn,
  STNElections,
  UnlockInstructions,
  UserTaxTracker,
  UserUpdateAlert,
  VerifyAccreditation
} from './containers';
import accountRoutes from './containers/Accounts/routes';
import {
  Account as PortfolioAccount,
  Investment as PortfolioInvestment
} from './containers/Portfolio/containers';
import {
  Accreditation,
  Continue,
  Discover,
  EmailVerify,
  Name,
  Password,
  Phone,
  Start
} from './containers/SignUp';
import {
  RequireAccreditedUser,
  RequireCompletedSignup,
  RequireLogin
} from './routerHelpers';

export const routerOptions = {
  future: {
    v7_relativeSplatPath: true,
    v7_startTransition: true
  }
};

const AppRouter = () => (
  <BrowserRouter {...routerOptions}>
    <App>
      <Routes>
        {
          // Start public routes
        }
        <Route path="/users/invitation/accept" element={<Start />} />
        <Route path="/users/signup/start" element={<Start />} />
        <Route path="/users/signup/password" element={<Password />} />
        <Route path="/users/signup/name" element={<Name />} />
        <Route path="/users/signup/accreditation" element={<Accreditation />} />
        {
          // Start sign up routes that require login
        }
        <Route path="/users/signup/continue" element={<Continue />} />
        <Route path="/users/signup/phone" element={<Phone />} />
        <Route path="/users/signup/discover" element={<Discover />} />
        <Route path="/users/signup/email_verify" element={<EmailVerify />} />
        {
          // End sign up routes that require login
        }
        <Route path="/users/signup/:referral_token" element={<Start />} />
        <Route
          path="/users/sign_up/:referral_token"
          element={<ReferralRedirect />}
        />
        <Route path="/users/sign_up" element={<SignUpRedirect />} />
        <Route path="/users/sign_in" element={<SignIn />} />
        <Route path="/users/locked" element={<UnlockInstructions />} />
        <Route path="/users/password/new" element={<ForgotPassword />} />
        <Route path="/users/password/edit" element={<ResetPassword />} />
        {
          // End public routes
        }
        <Route element={<RequireLogin />}>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Invest />} />
          <Route path="/invest" element={<Navigate to="/" replace />} />
          <Route
            path="/invest/:offering_id/closings/:closing_id"
            element={<Offering />}
          />
          <Route path="/invest/:offering_id" element={<Offering />} />
          <Route path="/users/invitation/new" element={<Invite />} />
          <Route path="/users/update_account" element={<UserUpdateAlert />} />
          {
            // Start profile routes
          }
          <Route path="/users/profile/start" element={<ProfileStart />} />
          <Route path="/users/profile/experience" element={<Experience />} />
          <Route path="/users/profile/objectives" element={<Objectives />} />
          <Route path="/users/profile/tolerance" element={<Tolerance />} />
          <Route path="/users/profile/name" element={<ProfileName />} />
          <Route path="/users/profile/kyc" element={<Identity />} />
          <Route path="/users/profile/address" element={<Address />} />
          <Route
            path="/users/profile/document_identification"
            element={<DocumentIdentification />}
          />
          {
            // End profile routes
          }
          <Route element={<RequireCompletedSignup />}>
            <Route path="/activity" element={<Activities />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route
              path="/portfolio/account/:account_id"
              element={<PortfolioAccount />}
            />
            <Route
              path="/portfolio/account/:account_id/investment/:investment_id"
              element={<PortfolioInvestment />}
            />
            <Route
              path="/investments/:offering_id/:closing_id/cashflow/:investment_id"
              element={<PaymentActivityRedirect />}
            />
            <Route
              path="/invest/:closing_id/investment/new"
              element={<IndicateInterest />}
            />
            <Route
              path="/invest/:closing_id/investment/:investment_id/interest"
              element={<IndicateInterest />}
            />
            <Route
              path="/invest/:closing_id/investment/:investment_id/sign"
              element={<Sign />}
            />
            <Route
              path="/invest/:closing_id/investment/:investment_id/fund"
              element={<Fund />}
            />
            <Route
              path="/invest/:closing_id/investment/:investment_id/capital_call/:transaction_id/fund"
              element={<Fund />}
            />
            <Route
              path="/invest/:closing_id/investment/:investment_id/confirm"
              element={<Review />}
            />
            <Route
              path="/invest/:closing_id/investment/:investment_id/edit"
              element={<IndicateInterest />}
            />
            <Route
              path="/offerings/:offering_id/elections"
              element={<DebtFundElections />}
            />
            {
              // TODO: We aren't using the offering_id param and should remove it and update any external links
            }
            <Route
              path="/offerings/:offering_id/elections/:investment_id"
              element={<STNElections />}
            />
            <Route
              path="/offerings/:offering_id/distribution_reinvestment"
              element={<DistributionReinvestmentElections />}
            />
            <Route
              path="/invest/:closing_id/investment/:investment_id/cancel"
              element={<CancelInvestment />}
            />
            <Route
              path="/invest/:closing_id/investment/:investment_id/capital_call/interest"
              element={<CapitalCall />}
            />
            <Route
              path="/invest/:closing_id/investment/:investment_id/capital_call/allocation"
              element={<CapitalCall />}
            />
            <Route
              path="/invest/:closing_id/investment/:investment_id/capital_call/thanks"
              element={<CapitalCall />}
            />
            <Route
              path="/users/offerings/:offering_id/closings/:closing_id/confidentiality"
              element={<ConfidentialityAgreement />}
            />
            <Route
              path="/verify_accreditation/:reference_id"
              element={<VerifyAccreditation />}
            />
            <Route
              path="/accounts/*"
              element={
                <RequireAccreditedUser>{accountRoutes}</RequireAccreditedUser>
              }
            />
            <Route path="/tax_tracker" element={<UserTaxTracker />} />
            <Route path="/settings/:tab?" element={<Setting />} />
            <Route path="/documents" element={<MyDocuments />} />
          </Route>
          <Route path="/invest/:id/*" />
        </Route>
      </Routes>
    </App>
  </BrowserRouter>
);

export default AppRouter;
