import { Alert, Button, Card } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import useRedirectUserWIthInterestAscentInvestment from 'hooks/useRedirectUserWIthInterestAscentInvestment';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import LoadingForwardBackButtons from '../../../../components/LoadingForwardBackButtons/LoadingForwardBackButtons';
import ProgressBarBorder from '../../../../components/ProgressBarBorder/ProgressBarBorder';
import {
  clearInvestment,
  loadInvestment,
  signAgreement
} from '../../../../redux/actions/investments';
import { loadClosing } from '../../../../redux/actions/offerings';
import { Closing } from '../../../../types/api/closing';
import { Investment, SignatureStyle } from '../../../../types/api/investment';
import {
  ClosingOffering,
  OfferingDocument
} from '../../../../types/api/offering';
import { User } from '../../../../types/api/user';
import { Dispatch } from '../../../../types/redux';
import EmAnalytics from '../../../../utilities/em_analytics';
import { handleErrorResponse } from '../../../../utilities/errorHandlers';
import { throwReactHookFormSubmissionErrors } from '../../../../utilities/validation';
import InvestmentStatus from '../../components/InvestmentStatus/InvestmentStatus';
import InvestmentStatusMessage from '../../components/InvestmentStatusMessage';
import InvestmentTitle from '../../components/InvestmentTitle/InvestmentTitle';
import {
  redirectIfInvestmentIsClosed,
  redirectIfInvestmentOnWaitlist,
  redirectIfPendingRollover,
  redirectIfUserCannotInvest
} from '../../helpers';
import { signSchema } from '../../validations';
import * as styles from './../../Investment.module.scss';
import { AcknowledgeAndSign } from './components/AcknowledgeAndSign/AcknowledgeAndSign';
import DocumentDownload from './components/DocumentDownload/DocumentDownload';
import Signature from './components/Signature/Signature';
import { SignFormValues } from './types';

const LoadingSkeleton = () => (
  <div data-testid="loadingSkeleton">
    <h5>
      <Skeleton width="60%" />
    </h5>
    <p>
      <Skeleton width="90%" />
    </p>
    <p>
      <Skeleton />
    </p>
    <h5>
      <Skeleton width="50%" />
    </h5>
    <p>
      <Skeleton width="70%" />
    </p>
    <p>
      <Skeleton />
    </p>
    <LoadingForwardBackButtons />
  </div>
);

interface CompletedFormProps {
  backLink: string;
  documents: OfferingDocument[];
  forwardLink: string;
  offeringId: number;
  registeredName: string;
  signatureStyle: SignatureStyle;
}

const CompletedForm = ({
  backLink,
  forwardLink,
  documents,
  registeredName,
  offeringId,
  signatureStyle
}: CompletedFormProps) => (
  <>
    <Alert type="positive">All required documents have been downloaded</Alert>
    <h4>Documents</h4>
    {documents?.map(document => (
      <DocumentDownload
        key={document.name}
        document={document}
        downloaded={true}
        offeringId={offeringId}
      />
    ))}
    <h4 className="margin-top-xx">Signature</h4>
    <Signature completedName={registeredName} signatureStyle={signatureStyle} />
    <div
      className={`forwardBackButtonWrapCompact float-right ${styles.formButtons}`}
    >
      <Button
        variant="orange"
        className={styles.submitButton}
        wrapper={<Link to={forwardLink} />}
      >
        Next
      </Button>
      <Button
        wrapper={<Link data-testid="backButton" to={backLink} />}
        variant="outlined"
        className="arrowBackButton"
      >
        <Back />
      </Button>
    </div>
  </>
);

type Params = {
  closing_id: string;
  investment_id: string;
};

interface Props {
  closing: Closing;
  dispatch: Dispatch;
  investment: Investment;
  loading: boolean;
  offering: ClosingOffering;
  registeredName: string;
  user: User;
}

const Sign = ({
  closing,
  dispatch,
  investment,
  registeredName,
  loading,
  offering,
  user
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  useRedirectUserWIthInterestAscentInvestment(dispatch, offering);

  const defaultValues = {
    registeredName,
    signature_style: 'Kalam' as SignatureStyle
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isSubmitted },
    setError,
    setValue,
    watch
  } = useForm<SignFormValues>({
    resolver: yupResolver(signSchema),
    values: defaultValues
  });

  let stepCount = 4;
  if (investment?.investment_account?.accreditation_status === 'verified')
    stepCount = 3;

  const isComplete = ['Complete', 'In Progress'].includes(
    investment?.documentation_status
  );

  const backLink =
    !loading &&
    `/invest/${params.closing_id}/investment/${investment.id}/interest`;
  const forwardLink =
    !loading && `/invest/${params.closing_id}/investment/${investment.id}/fund`;

  const documents = watch('documents');

  const documentsNotDownloaded =
    documents && Object.keys(documents).some(document => !documents[document]);
  const showDocumentError = isSubmitted && documentsNotDownloaded;

  useEffect(() => {
    const newInvestmentRoute = `/invest/${params.closing_id}/investment/new`;

    redirectIfUserCannotInvest(navigate, user);

    dispatch(clearInvestment());

    document.title = 'Sign | EquityMultiple';

    if (!params.closing_id) {
      navigate('/');
    } else if (!params.investment_id) {
      navigate(newInvestmentRoute);
    } else {
      dispatch(loadClosing(params.closing_id))
        .then(res => {
          redirectIfInvestmentIsClosed(navigate, res.closing);
        })
        .catch(error => handleErrorResponse(navigate, error));
      dispatch(loadInvestment(params.investment_id))
        .then(res => {
          redirectIfPendingRollover(navigate, res, params.closing_id);
          redirectIfInvestmentOnWaitlist(navigate, res, params.closing_id);
        })
        .catch(error =>
          handleErrorResponse(navigate, error, newInvestmentRoute)
        );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isComplete) {
      offering.sign_required_documents?.forEach((_, index) => {
        setValue(`documents.doc${index}`, false);
      });
    }
  }, [dispatch, isComplete, offering.sign_required_documents, setValue]);

  const setDownloadedField = (documentIndex: number) => {
    if (!isComplete) setValue(`documents.doc${documentIndex}`, true);
  };

  const onSubmit = values => {
    if (!showDocumentError) {
      const submittedValues = {
        user_name: values.user_name,
        signature_style: values.signature_style,
        datetime: new Date().toISOString()
      };

      return dispatch(signAgreement(params.investment_id, submittedValues))
        .then(() => {
          EmAnalytics.track('Investment Signed', 'Investing', {
            action: 'Signed Offering Document',
            label: 'Amount',
            value: investment.amount,
            userId: investment.user_id,
            firstName: investment.user.first_name,
            lastName: investment.user.last_name,
            first_name: investment.user.first_name,
            last_name: investment.user.last_name,
            first_time_investment: investment.first_time_investment,
            offeringId: investment.offering_id,
            investmentId: investment.id,
            amount: investment.amount
          });
          navigate(
            `/invest/${params.closing_id}/investment/${params.investment_id}/fund`
          );
        })
        .catch(error => throwReactHookFormSubmissionErrors(error, setError));
    }
  };

  return (
    <Container className="container-narrow">
      <InvestmentTitle
        title={offering.title}
        stage={closing.stage}
        loading={loading}
      />
      <InvestmentStatusMessage closing={closing} investment={investment} />
      <InvestmentStatus
        step={2}
        hideCancelLink={isComplete}
        investment={investment}
        closing={closing}
        loading={loading}
      />
      <Card className="border-top-card">
        <ProgressBarBorder currentStep={2} steps={stepCount} />

        <h3 className="margin-top-0 margin-x">Sign</h3>
        <p className="margin-xx">
          Complete this step to secure your spot in the investment.
        </p>
        {loading ? (
          <LoadingSkeleton />
        ) : isComplete ? (
          <CompletedForm
            backLink={backLink}
            forwardLink={forwardLink}
            documents={offering.sign_required_documents}
            registeredName={registeredName}
            signatureStyle={
              investment?.subscription_agreement?.meta?.signature_style
            }
            offeringId={offering.id}
          />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} data-testid="signForm">
            <AcknowledgeAndSign<SignFormValues>
              isSubmitted={isSubmitted}
              setDownloadedField={setDownloadedField}
              formValues={watch()}
              offeringId={offering.id}
              signRequiredDocuments={offering?.sign_required_documents}
              documentsNotDownloaded={documentsNotDownloaded}
              control={control}
              errors={errors}
            />

            <FormError errors={errors} />

            <div
              className={`forwardBackButtonWrapCompact float-right ${styles.formButtons}`}
            >
              <Button
                type="submit"
                variant="orange"
                className={styles.submitButton}
                loading={isSubmitting}
              >
                Sign and Proceed
              </Button>
              <Button
                wrapper={<Link data-testid="backButton" to={backLink} />}
                variant="outlined"
                className="arrowBackButton"
              >
                <Back />
              </Button>
            </div>
          </form>
        )}
      </Card>
    </Container>
  );
};

function mapStateToProps(store) {
  const userRegisteredName = `${store.auth.user.first_name} ${store.auth.user.last_name}`;

  return {
    closing: store.offerings.closing,
    investment: store.investments.investment || store.offerings.investment,
    offering: store.offerings.offering,
    loading: store.offerings.loadingClosing || store.investments.loading,
    user: store.auth.user,
    registeredName: userRegisteredName
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Sign);
