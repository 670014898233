import { Card, Input } from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';

import { ClosingStage } from '../../../types/api/closing';
import { numberMaskOptions } from '../../../utilities/masks';

interface Props {
  closingStage: ClosingStage;
  investmentAmount: string;
}

const InterestOrWaitlistSummary = ({
  closingStage,
  investmentAmount
}: Props) => {
  const inInterest = closingStage === 'interest';

  const title = inInterest
    ? "You've indicated an interest in this offering"
    : 'You are on the waitlist for this offering';

  const copy = inInterest
    ? 'Thanks for indicating interest and pledging to invest in this offering. We will notify you when funding becomes available. Once you are notified, you will have the ability to sign the subscription agreement and complete your investment.'
    : 'A member of our Investor Relations team will reach out to you if a spot becomes available.';

  return (
    <>
      <Card>
        <h4 className="margin-top-0">{title}</h4>
        <p>{copy}</p>
        <Row>
          <Col md={6}>
            <Input
              label="Investment Amount"
              value={investmentAmount}
              dollarMask
              inputMaskOptions={numberMaskOptions}
              readOnly
              data-testid="investment-amount"
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default InterestOrWaitlistSummary;
