import { Card } from '@equitymultiple/react-eui';
import PuzzleIllustration from 'images/puzzle-illustration.svg';
import React from 'react';

import * as styles from './NotAccreditedCard.module.scss';

const NotAccreditedCard = () => {
  return (
    <Card className={styles.card}>
      <div className={styles.content}>
        <PuzzleIllustration />
        <h5>You're one step away from investments</h5>
        <p>
          There are a lot of investment opportunities waiting for you. Update
          your accreditation status and start exploring.
        </p>
      </div>
    </Card>
  );
};

export default NotAccreditedCard;
