export const LOAD_ACTIVE = 'invest/LOAD_ACTIVE';
export const LOAD_ACTIVE_SUCCESS = 'invest/LOAD_ACTIVE_SUCCESS';
export const LOAD_ACTIVE_FAIL = 'invest/LOAD_ACTIVE_FAIL';

export const LOAD_FUNDED = 'invest/LOAD_FUNDED';
export const LOAD_FUNDED_SUCCESS = 'invest/LOAD_FUNDED_SUCCESS';
export const LOAD_FUNDED_FAIL = 'invest/LOAD_FUNDED_FAIL';

export const LOAD_MARKETING_DATA = 'invest/LOAD_MARKETING_DATA';
export const LOAD_MARKETING_DATA_SUCCESS = 'invest/LOAD_MARKETING_DATA_SUCCESS';
export const LOAD_MARKETING_DATA_FAIL = 'invest/LOAD_MARKETING_DATA_FAIL';

export const LOAD_TRACK_RECORD_DATA = 'invest/LOAD_TRACK_RECORD_DATA';
export const LOAD_TRACK_RECORD_DATA_SUCCESS =
  'invest/LOAD_TRACK_RECORD_DATA_SUCCESS';
export const LOAD_TRACK_RECORD_DATA_FAIL = 'invest/LOAD_TRACK_RECORD_DATA_FAIL';

export function loadActiveOfferings() {
  return {
    types: [LOAD_ACTIVE, LOAD_ACTIVE_SUCCESS, LOAD_ACTIVE_FAIL],
    promise: client => client.get('/mkt/offerings?stage=active')
  };
}

export function loadFundedOfferings(page) {
  return {
    types: [LOAD_FUNDED, LOAD_FUNDED_SUCCESS, LOAD_FUNDED_FAIL],
    promise: client =>
      client.get(`/mkt/offerings?stage=funded&page_size=6&page=${page}`)
  };
}

export function loadMarketingData() {
  return {
    types: [
      LOAD_MARKETING_DATA,
      LOAD_MARKETING_DATA_SUCCESS,
      LOAD_MARKETING_DATA_FAIL
    ],
    promise: client =>
      client.get('https://cms.eqm-int.com/wp-json/acf/v3/pages/42363/', {
        excludeToken: true
      })
  };
}

export function loadTrackRecordData() {
  return {
    types: [
      LOAD_TRACK_RECORD_DATA,
      LOAD_TRACK_RECORD_DATA_SUCCESS,
      LOAD_TRACK_RECORD_DATA_FAIL
    ],
    promise: client => client.get('/mkt/offerings/track_records')
  };
}
