import { useEffect } from 'react';
import { loadRecaptchaScript, removeRecaptchaScript } from 'utilities/captcha';

const useRecaptcha = () => {
  useEffect(() => {
    loadRecaptchaScript();

    return () => {
      removeRecaptchaScript();
    };
  }, []);
};

export default useRecaptcha;
