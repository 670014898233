import * as yup from 'yup';

import { validations } from '../../../utilities/yupValidations';

const { fields } = validations;

const emailInviteModalSchema = yup.object().shape({
  email: fields.email
});

export default emailInviteModalSchema;
