import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import utils from 'utilities/utils';

import * as sharedStyles from '../../OfferingShared.module.scss';
import AmountSelect from '../AmountSelect/AmountSelect';
import * as styles from './CashFlow.module.scss';

interface Props {
  data: {
    distributions: {
      earnings: number;
      principal: number;
    }[];
    first_distribution_date: string;
    frequency: string;
    load_cumulative: boolean;
  };
  minInvestmentAmount: number;
}

const CashFlow = ({ data, minInvestmentAmount }: Props) => {
  const [cumulative, setCumulative] = useState(null);
  const [inputAmount, setInputAmount] = useState(null);
  const [earningsAmounts, setEarningsAmounts] = useState([]);
  const [months, setMonths] = useState([]);
  const [principalAmounts, setPrincipalAmounts] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState('');
  const [totalReturn, setTotalReturn] = useState('');

  const getChartOptions = (): Highcharts.Options => {
    return {
      accessibility: {
        enabled: false
      },
      chart: {
        type: 'column',
        alignTicks: false,
        backgroundColor: '#fff',
        animation: true,
        events: {
          load() {
            this.series.forEach(singleSeries => {
              singleSeries.data.forEach(singleSeriesData => {
                // There seems to be someissues with the type definitions for highcharts packages.
                // Any ignore comments in this file are ignoring TS flagging properties as nonexistent which do in fact exist in the browser
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (singleSeriesData.yBottom - singleSeriesData.plotY < 15)
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  singleSeriesData.dataLabel.element.style.display = 'none';
              });
            });
          }
        }
      },
      xAxis: {
        categories: months,
        tickWidth: 0,
        lineWidth: 0,
        offset: 20,
        labels: {
          style: {
            color: '#bdc3c6',
            fontFamily: 'Inter, Sans-Serif',
            fontSize: '16px'
          }
        }
      },
      yAxis: {
        labels: {
          formatter() {
            return '$' + this.axis.defaultLabelFormatter.call(this);
          },
          style: {
            fontFamily: 'Inter, Sans-Serif',
            fontSize: '14px'
          }
        },
        title: {
          text: ''
        }
      },
      tooltip: {
        positioner(labelWidth, _, point) {
          const tooltipX =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            point.plotX + this.chart.hoverSeries.xAxis.left - labelWidth / 2;
          const tooltipY = point.plotY + 50;
          return {
            x: tooltipX,
            y: tooltipY
          };
        },
        formatter() {
          return `<span class="${
            styles.chartTooltip
          }">$${Highcharts.numberFormat(
            this.points[0].total,
            0,
            '',
            ','
          )}</span>`;
        },
        useHTML: true,
        shared: true,
        shape: 'callout',
        borderRadius: 1,
        shadow: false,
        backgroundColor: 'rgba(42,43,48, 0.96)',
        borderColor: 'rgba(42,43,48, 0.96)',
        style: {
          color: '#fff',
          fontFamily: 'Inter, Sans-Serif',
          fontSize: '16px',
          position: 'relative'
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            color: '#fff',
            style: {
              textShadow: '0',
              fontSize: '14px',
              fontFamily: 'Inter, sans-serif'
            },
            formatter() {
              if (this.y !== 0)
                return '$' + Highcharts.numberFormat(this.y, 0, '', ',');
              return '';
            }
          }
        },
        area: {
          animation: true
        },
        series: {
          events: {
            legendItemClick: () => false
          }
        }
      },
      legend: {
        verticalAlign: 'top',
        padding: 30,
        itemStyle: {
          fontFamily: 'Inter, Sans-Serif',
          fontSize: '14px'
        }
      },
      series: [
        {
          name: 'Earnings',
          color: '#4caf50',
          data: earningsAmounts,
          borderWidth: 0,
          type: 'column'
        },
        {
          name: 'Principal',
          color: '#A0B3C2',
          data: principalAmounts,
          borderWidth: 0,
          type: 'column'
        }
      ],
      credits: { enabled: false },
      title: { text: null },
      exporting: {
        buttons: {
          contextButton: { enabled: false }
        }
      }
    };
  };

  useEffect(() => {
    setCumulative(data.load_cumulative);
    setInputAmount(minInvestmentAmount);
  }, [data.load_cumulative, minInvestmentAmount]);

  useEffect(() => {
    const setChartData = () => {
      const d = moment(data.first_distribution_date).format('YYYY-MM-DD');
      const totalMonths =
        Number(data.distributions.length) + Number(moment(d).format('M'));
      const startMonth = moment(d).format('M');
      const startQuarter = moment(d).quarter();
      let startYear = moment(d).year();
      let sumEarnings = 0;
      let sumPrincipal = 0;

      const chart = {
        earningsAmounts: [],
        months: [],
        principalAmounts: []
      };

      if (data.frequency === 'Annual') {
        for (let y = startQuarter; y <= totalMonths; y++)
          chart.months.push(startYear++);
      } else if (data.frequency === 'Quarterly') {
        for (let q = startQuarter; q <= totalMonths; q++) {
          chart.months.push(
            'Q' +
              moment().quarter(q).format('Q') +
              " '" +
              moment().year(startYear).quarter(q).format('YY')
          );
        }
      } else {
        for (let i = Number(startMonth); i <= totalMonths; i++) {
          chart.months.push(
            moment()
              .month(i - 1)
              .format('MMMM')
          );
        }
      }

      data.distributions.forEach((dist, index) => {
        const shares = inputAmount / Math.floor(minInvestmentAmount);
        let calcEarnings;
        let calcPrincipal;
        sumPrincipal += shares * dist.principal;
        sumEarnings += shares * dist.earnings;
        if (cumulative) {
          // Calculate the Cumulative Principal
          if (index === 0) calcPrincipal = shares * dist.principal;
          else
            calcPrincipal =
              chart.principalAmounts[index - 1] + shares * dist.principal;
          chart.principalAmounts.push(calcPrincipal);

          // Calculate the Cumulative Earnings
          if (index === 0) calcEarnings = shares * dist.earnings;
          else
            calcEarnings =
              chart.earningsAmounts[index - 1] + shares * dist.earnings;
          chart.earningsAmounts.push(calcEarnings);
        } else {
          // Calculate the Principal
          calcPrincipal = shares * dist.principal;
          chart.principalAmounts.push(calcPrincipal);

          // Calculate the Earnings
          calcEarnings = shares * dist.earnings;
          chart.earningsAmounts.push(calcEarnings);
        }
      });

      const newTotalEarnings = utils.numberWithCommas(Math.round(sumEarnings));
      const newTotalReturn = utils.numberWithCommas(
        Math.round(sumPrincipal + sumEarnings)
      );

      setEarningsAmounts(chart.earningsAmounts);
      setMonths(chart.months);
      setPrincipalAmounts(chart.principalAmounts);
      setTotalEarnings(newTotalEarnings);
      setTotalReturn(newTotalReturn);
    };

    setChartData();
  }, [data, inputAmount, cumulative, minInvestmentAmount]);

  const onAmountChange = newAmount => {
    setInputAmount(newAmount);
  };

  const onCumulativeChange = () => {
    setCumulative(!cumulative);
  };

  return data.distributions ? (
    <div className="margin-x" data-testid="cash-flow">
      <div className={styles.cashflowMeta}>
        <div className="chart-metadata">
          <Row>
            <Col md={3} sm={6} className="column">
              <div className="text-label">Investment Amount</div>
              <AmountSelect
                amountChange={onAmountChange}
                minAmount={Number(minInvestmentAmount)}
                name="amountSelect"
                defaultValue="0"
              />
            </Col>

            <Col md={3} sm={6} className="column">
              <div className="text-label">Cumulative</div>

              <button
                className={`${styles.toggle} ${
                  cumulative && styles.toggleSelected
                }`}
                onClick={onCumulativeChange}
                type="button"
              >
                <div className={styles.toggleSwitch} />
              </button>
            </Col>

            <Col md={3} sm={6} className="column">
              <div className="text-label">Total Earnings</div>
              <p className={sharedStyles.metaBlockData}>${totalEarnings} </p>
            </Col>

            <Col md={3} sm={6} className="column">
              <div className="text-label">Total Return</div>
              <p className={sharedStyles.metaBlockData}>${totalReturn}</p>
            </Col>
          </Row>
        </div>
      </div>

      <HighchartsReact
        highcharts={Highcharts}
        options={getChartOptions()}
        data-testid="cash-flow-highchart"
      />
    </div>
  ) : null;
};

export default CashFlow;
