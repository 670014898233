import * as yup from 'yup';

import { validations, yupString } from '../../utilities/yupValidations';

const { fields } = validations;

const signInSchema = yup.object().shape({
  captcha_response: yupString.when('captcha_required', {
    is: true,
    then: () => fields.captchaResponse
  }),
  email: fields.email,
  otp_attempt: yupString.test(
    'Required when value exists',
    'Verification code is required',
    function (value) {
      if (!this.parent.otp_required) return true;
      return value !== undefined && value.length > 0;
    }
  ),
  password: yupString.required('Password is required')
});

export default signInSchema;
