import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

type Params = {
  referral_token: string;
};

const ReferralRedirect = () => {
  const { referral_token: referralToken } = useParams<Params>();
  return <Navigate to={`/users/signup/${referralToken}`} replace />;
};

export default ReferralRedirect;
