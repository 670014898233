import { Button, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import FormError from 'components/FormError/FormError';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadAuth, resendConfirmationEmail } from 'redux/actions/auth';
import { updateUsername } from 'redux/actions/user-settings';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import { setFieldProps } from 'utilities/formHelpers';
import humane from 'utilities/humane';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import RequireLoginWrap from '../components/RequireLoginWrap';
import { emailVerifyChannel } from '../helpers';
import { emailVerifySchema } from '../validation';

interface FormFields {
  unconfirmed_email?: string;
}

export interface Props {
  dispatch: Dispatch;
  resendingConfirmation: boolean;
  user: User;
}

const EmailVerify = ({ dispatch, resendingConfirmation, user }: Props) => {
  const navigate = useNavigate();

  const [showChangeForm, setShowChangeForm] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError
  } = useForm<FormFields>({
    resolver: yupResolver(emailVerifySchema)
  });

  const email = user?.unconfirmed_email || user?.email;

  useEffect(() => {
    document.title = 'Complete Signup | EquityMultiple';

    dispatch(loadAuth()).then(userRes => {
      if (userRes?.confirmed)
        navigate('/users/profile/start', { replace: true });
    });

    // If a user verifies their email in another tab then redirect this one to the homepage
    emailVerifyChannel.onmessage = event => {
      if (event.data === 'verified')
        navigate('/users/profile/start', { replace: true });
    };
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = values => {
    return dispatch(updateUsername({ user: values }))
      .then(() => {
        dispatch(loadAuth());
        humane.notice('Email address successfully updated');
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  const handleResend = () => {
    return dispatch(resendConfirmationEmail({ user: { email } }))
      .then(() => {
        humane.notice(`Confirmation email resent to ${email}`);
      })
      .catch(err => {
        humane.error(err.body.message);
      });
  };

  const loading = resendingConfirmation || isSubmitting;

  return user ? (
    <RequireLoginWrap>
      <AccountProgressContainer signupStage="sign up">
        {showChangeForm ? (
          <form onSubmit={handleSubmit(onSubmit)} data-testid="changeEmailForm">
            <h3>Change Email</h3>
            <p className="margin-xx">
              This email is how we'll communicate details about your account,
              announce new investment opportunities, share new investor
              resources, and more.
            </p>

            <Controller
              name="unconfirmed_email"
              control={control}
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Email Address"
                  className="input-fixed-width"
                />
              )}
            />

            <FormError errors={errors} />

            <div className="forwardBackButtonWrapCompact">
              <Button
                type="submit"
                loading={isSubmitting}
                variant="orange"
                className="button-fixed-width margin-top-xx"
              >
                Continue
              </Button>
              <Button
                disabled={isSubmitting}
                variant="outlined"
                className="button-fixed-width margin-top-xx arrowBackButton"
                onClick={() => setShowChangeForm(false)}
                data-testid="backLink"
              >
                <Back />
              </Button>
            </div>
          </form>
        ) : (
          <>
            <h3>Verify your email</h3>
            <p data-testid="emailSentMessage">
              We have sent you an email with additional instructions. Please
              check your inbox for <span className="text-orange">{email}</span>
            </p>
            <p>
              <button
                type="button"
                className="text-link margin-xx"
                disabled={loading}
                onClick={() => setShowChangeForm(true)}
              >
                Change Email
              </button>
            </p>
            <p>
              Didn't get the email?{' '}
              <button
                type="button"
                className="text-link margin-xx"
                disabled={loading}
                onClick={handleResend}
              >
                Resend
              </button>
            </p>
          </>
        )}
      </AccountProgressContainer>
    </RequireLoginWrap>
  ) : null;
};

function mapStateToProps(store) {
  return {
    user: store.auth.user,
    resendingConfirmation: store.auth.resendingConfirmationEmail
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(EmailVerify);
