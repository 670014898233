import AccountProgress from 'components/AccountProgress/AccountProgress';
import { getFirstIncompleteStepRoute } from 'containers/Accounts/helpers';
import { setBackgroundColor } from 'containers/SignUp/helpers';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { SingleInvestmentAccount } from 'types/api/account';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import { hasCompletedProfile, hasCompletedSignup } from 'utilities/user';

import * as styles from './AccountProgressContainer.module.scss';
import { SignUpStage } from './types';

interface Props {
  account?: SingleInvestmentAccount;
  children: React.ReactNode;
  dispatch?: Dispatch;
  isComplete?: boolean;
  loaded?: boolean;
  loading?: boolean;
  showSkipLink?: boolean;
  signupStage?: SignUpStage;
  user: User;
}

const AccountProgressContainer = ({
  account,
  children,
  dispatch,
  isComplete,
  loaded,
  loading,
  signupStage,
  showSkipLink,
  user
}: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    setBackgroundColor();

    if (!loaded && !loading) {
      dispatch(loadAuth());
    }
  }, [dispatch, loaded, loading]);

  useEffect(() => {
    return () => {
      document.querySelector('body').style.backgroundColor = '#f8f8f8';
    };
  }, []);

  useEffect(() => {
    if (user) {
      // User is entering profile flow without completing sign up, we need to route them to the first incomplete step.
      // This will occur if a user confirms their email and is sent to /users/profile/start without having completed sign up
      if (signupStage === 'profile' && !hasCompletedSignup(user)) {
        const nextStepRoute = getFirstIncompleteStepRoute(user);
        navigate(nextStepRoute, { replace: true });
      } else if (
        // User has completed the first profile step and shouldn't be here
        (signupStage === 'sign up' && hasCompletedSignup(user)) ||
        // User has completed the last profile step and shouldn't be here
        (signupStage === 'profile' && hasCompletedProfile(user))
      ) {
        navigate('/', { replace: true });
      }
    }

    return () => {
      document.querySelector('body').style.backgroundColor = '#f8f8f8';
    };
    // We can't include user and signupStage here otherwise the user will be redirected to the homepage
    // after completing profile, before signupStage has changed to "investment account"
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showButton = !isComplete && showSkipLink;

  return (
    <Container className="container-wide">
      <Row className={styles.row}>
        <Col xl={3.5} lg={4}>
          <AccountProgress
            signupStage={signupStage}
            user={user}
            account={account}
            isComplete={isComplete}
            showSkipLink={showSkipLink}
          />
        </Col>
        <Col xl={8.5} lg={8} className={styles.contentColumn}>
          <div className={styles.content}>
            {children}
            {showButton && (
              <Link to="/" className={`${styles.skipLink} underline`}>
                Finish Later
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

function mapStateToProps(store) {
  return {
    loaded: store.auth.loaded,
    loading: store.auth.loading,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(AccountProgressContainer);
