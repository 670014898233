import React from 'react';
import Skeleton from 'react-loading-skeleton';

import * as styles from './LoadingForwardBackButtons.module.scss';

const LoadingForwardBackButtons = () => (
  <div className={styles.loadingButtonsWrap}>
    <Skeleton className={styles.loadingBackButton} />
    <Skeleton className={styles.loadingNextButton} />
  </div>
);

export default LoadingForwardBackButtons;
