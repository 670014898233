import { EMLoadingIcon } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import humane from 'utilities/humane';
import { redirectUsersWithIncompleteSignup } from 'utilities/user';

type Props = {
  children: React.ReactNode;
  dispatch?: Dispatch;
  loaded?: boolean;
  loading?: boolean;
  user?: User;
};

const RequireLoginWrap = ({
  children,
  dispatch,
  loading,
  loaded,
  user
}: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      dispatch(loadAuth());
    }
  }, [dispatch, loaded]);

  useEffect(() => {
    if (loaded && !user?.id) {
      humane.error('You need to sign in or sign up before continuing.');
      navigate('/users/sign_in');
    }

    redirectUsersWithIncompleteSignup(navigate, user);
  }, [loaded, user, navigate]);

  return (!loaded && loading) || !user ? <EMLoadingIcon /> : children;
};

function mapStateToProps(store) {
  return {
    loaded: store.auth.loaded,
    loading: store.auth.loading,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(RequireLoginWrap);
