import { validations, yupBool, yupString } from 'utilities/yupValidations';
import * as yup from 'yup';

const { fields } = validations;

export const residenceSchema = yup.object().shape({
  accepted_residency_requirements: yupBool
    .notRequired()
    .when('residence_status', {
      is: val => val !== 'U.S. Citizen',
      then: () =>
        yupBool
          .oneOf(
            [true],
            'Please confirm you intend to invest through a U.S. based entity'
          )
          .required(
            'Please confirm you intend to invest through a U.S. based entity'
          )
    }),
  accepted_tos: fields.requireTrue,
  country_of_citizenship: yupString.notRequired().when('residence_status', {
    is: val => val !== 'U.S. Citizen',
    then: () =>
      yupString.required(
        'Non-U.S. Citizens must select a country of citizenship'
      )
  }),
  residence_status: fields.residenceStatus
});
