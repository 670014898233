export const twitterShare = function (el) {
  const w = 600;
  const h = 300;
  const left = screen.width / 2 - w / 2;
  const top = screen.height / 2 - h / 2;
  const text = escape(
    'Join me on @equitymultiple, a #realstate #crowdfunding platform aligned with investor interests'
  );
  window.open(
    'https://twitter.com/share?url=' + escape(el.href) + '&text=' + text,
    '',
    'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=' +
      w +
      ', height=' +
      h +
      ', top=' +
      top +
      ', left=' +
      left
  );
  return false;
};

export const linkedInShare = function (el) {
  const w = 500;
  const h = 600;
  const left = screen.width / 2 - w / 2;
  const top = screen.height / 2 - h / 2;
  const summary = escape(
    'Join me on @equitymultiple, a #realstate #crowdfunding platform aligned with investor interests'
  );
  const title = 'EquityMultiple';
  window.open(
    'http://www.linkedin.com/shareArticle?mini=true&url=' +
      escape(el.href) +
      '&summary=' +
      summary +
      '&title=' +
      title,
    '',
    'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=' +
      w +
      ', height=' +
      h +
      ', top=' +
      top +
      ', left=' +
      left
  );
  return false;
};
