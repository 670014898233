import {
  Button,
  DateSelect,
  FileUploader,
  Input,
  Select
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import FormError from 'components/FormError/FormError';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import {
  updatePersonalIdentification,
  updateUserProfile
} from 'redux/actions/user-settings';
import { maxUploadSize } from 'utilities/constants';
import { setFieldProps } from 'utilities/formHelpers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { identificationDocumentOptions } from '../content';
import { getUserAttributes } from '../helpers';
import { ProfileValues, SharedProfileValues } from '../types';
import { documentIdentificationSchema } from '../validation';

const DocumentIdentification = ({ dispatch, user }: SharedProfileValues) => {
  const navigate = useNavigate();
  const [hasNewDoc, setHasNewDoc] = useState(false);

  const defaultValues = getUserAttributes(user);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    setValue,
    watch
  } = useForm<ProfileValues>({
    resolver: yupResolver(documentIdentificationSchema),
    values: defaultValues
  });

  const idType = watch('id_document.type');

  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  const handleRemove = () => {
    setHasNewDoc(true);
    setValue('id_document.attachment', null);
  };

  const handleUpload = file => {
    setHasNewDoc(true);
    setValue('id_document.attachment', file);
  };

  const onSubmit = values => {
    if (!(values.id_document.attachment instanceof File))
      delete values.id_document.attachment;

    const attachmentValues = {
      user: {
        id_document: values.id_document.attachment,
        id_expiration: values.id_document.expiry,
        id_type: values.id_document.type
      }
    };

    if (values.id_document.passport) {
      const submitValues = {
        investor_profile_attributes: {
          passport: values.id_document.passport
        }
      };
      dispatch(updateUserProfile(submitValues));
    }

    return dispatch(updatePersonalIdentification(attachmentValues))
      .then(() => {
        dispatch(loadAuth()).then(() => {
          navigate('/accounts/new');
        });
      })
      .catch(err => {
        throwReactHookFormSubmissionErrors(err, setError);
      });
  };

  return (
    <AccountProgressContainer signupStage="profile" showSkipLink>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-x">Identification</h3>
        <p className="margin-xx">
          Upload documents to confirm your identity securely. Documents are used
          solely for verification and kept confidential.
        </p>
        <Controller
          name="id_document.attachment"
          control={control}
          render={({ field }) => (
            <FileUploader
              {...setFieldProps(field, errors)}
              acceptedFileTypes={['JPG', 'PNG']}
              existingFile={!hasNewDoc && defaultValues.id_document?.attachment}
              onRemove={handleRemove}
              upload={handleUpload}
              uploading={isSubmitting}
              showImage
              maxSize={maxUploadSize}
            />
          )}
        />

        <Controller
          name="id_document.type"
          control={control}
          render={({ field }) => (
            <Select
              {...setFieldProps(field, errors)}
              label="Document Type"
              options={identificationDocumentOptions}
            />
          )}
        />

        {idType === 'passport' && (
          <Controller
            name="id_document.passport"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Passport Number"
              />
            )}
          />
        )}
        <Controller
          name="id_document.expiry"
          control={control}
          render={({ field }) => (
            <DateSelect
              {...setFieldProps(field, errors)}
              label="ID Expiration Date"
              futureDate
            />
          )}
        />

        <FormError errors={errors} />

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            loading={isSubmitting}
            variant="orange"
            className="button-fixed-width margin-top-xx"
          >
            Continue
          </Button>
          <Button
            disabled={isSubmitting}
            variant="outlined"
            data-testid="backLink"
            className="button-fixed-width margin-top-xx arrowBackButton"
            wrapper={<Link to="/users/profile/address" />}
          >
            <Back />
          </Button>
        </div>
      </form>
    </AccountProgressContainer>
  );
};

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(DocumentIdentification);
