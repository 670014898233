import { FileIcon, Rolling } from '@equitymultiple/react-eui';
import React, { useState } from 'react';

import iconCheck from '../../../../../../images/icons/check.svg?url';
import ApiClient, {
  ApiClientType
} from '../../../../../../redux/helpers/ApiClient';
import { OfferingDocument } from '../../../../../../types/api/offering';
import utils from '../../../../../../utilities/utils';
import * as styles from './DocumentDownload.module.scss';

const client = new ApiClient() as ApiClientType;

interface Props {
  document: OfferingDocument;
  documentIndex?: number;
  downloaded: boolean;
  offeringId: number;
  setDownloadedField?: (id: number) => void;
  showErrors?: boolean;
}

const DocumentDownload = ({
  document,
  documentIndex,
  downloaded,
  offeringId,
  setDownloadedField,
  showErrors
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const hasError = showErrors && !downloaded && !loading;

  const handleClick = () => {
    setLoading(true);
    setErrorMessage('');

    client
      .post(`/mkt/offerings/${offeringId}/download`, {
        data: {
          document_name: document.name
        },
        isBlob: true
      })
      .then(blob => {
        const filename = document.url.substring(
          document.url.lastIndexOf('/') + 1
        );

        utils.downloadFile(blob, filename);

        if (setDownloadedField) {
          setDownloadedField(documentIndex);
        }
      })
      .catch(error => {
        setErrorMessage(
          error?.body?.message ||
            'There was a problem downloading the file, please try again.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <button
        className={`${styles.documentDownload} ${hasError ? styles.error : ''}`}
        type="button"
        onClick={handleClick}
        disabled={loading}
        data-testid="documentDownload"
      >
        <div className={styles.documentName}>
          <FileIcon url={document.url} className={styles.documentIcon} />
          {document.name}
        </div>
        {loading ? (
          <Rolling className={styles.loader} data-testid="loadingIcon" />
        ) : downloaded ? (
          <div className={styles.downloadedText}>
            <img src={iconCheck} alt="" />
            Downloaded
          </div>
        ) : (
          <div className={styles.downloadText}>
            <i className="fa fa-download" />
            Download
          </div>
        )}
      </button>
      {errorMessage && <p className="text-red">{errorMessage}</p>}
    </>
  );
};

export default DocumentDownload;
