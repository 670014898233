import { AnyAction } from 'redux';

import * as actions from '../actions/posts';
const initialState = {
  posts: [],
  loading: true,
  loadingInBackground: false,
  loaded: false,
  eventStatus: {}
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.LOAD_POSTS:
      return {
        ...state,
        loading: true,
        loaded: false
      };

    case actions.LOAD_POSTS_BACKGROUND:
      return {
        ...state,
        loadingInBackground: true,
        loaded: false
      };

    case actions.LOAD_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        posts: action.result.posts
      };

    case actions.LOAD_POSTS_APPEND_SUCCESS:
      return {
        ...state,
        loadingInBackground: false,
        loaded: true,
        posts: [...state.posts, ...action.result.posts]
      };

    case actions.LOAD_POSTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error.body
      };

    case actions.READ_POSTS:
      return {
        ...state
      };

    default:
      return state;
  }
};
