// Helper function to recursively handle nested errors in server responses
const processErrors = (errors, setError, parentField = '') => {
  for (const field in errors) {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const errorMessages = errors[field];

      const fullFieldName = parentField ? `${parentField}.${field}` : field;

      if (typeof errorMessages === 'string') {
        // If it's a string, call SetError on the error message
        setError(fullFieldName, {
          type: 'server',
          message: errorMessages
        });
      } else if (Array.isArray(errorMessages) && errorMessages.length > 0) {
        // If it's an array, call setError on the first error message
        errorMessages.forEach(errorMessage => {
          setError(fullFieldName, {
            type: 'server',
            message: errorMessage
          });
        });
      } else if (typeof errorMessages === 'object' && errorMessages !== null) {
        // If it's a nested object, recursively process it
        processErrors(errorMessages, setError, fullFieldName);
      }
    }
  }
};

export const throwReactHookFormSubmissionErrors = (response, setError) => {
  const errors = response?.body?.errors;
  const singleErrorMessage = response?.body?.message;

  if (errors && Object.keys(errors).length > 0) {
    processErrors(errors, setError);
  } else if (singleErrorMessage) {
    setError('root.serverError', {
      type: 'server',
      message: singleErrorMessage
    });
  }
};

export const betweenMinMax = (minValue, maxValue) => (value, previousValue) =>
  value >= minValue && value <= maxValue ? value : previousValue;
