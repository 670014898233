import { Button } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  completeInvestmentAccount,
  loadInvestmentAccount
} from 'redux/actions/account';
import { loadAuth } from 'redux/actions/auth';
import { SingleInvestmentAccount } from 'types/api/account';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import utils from 'utilities/utils';

import { accountStatus } from '../contents';
import AccountWrap from './AccountWrap/AccountWrap';

type Params = {
  reference_id: string;
};

interface Props {
  dispatch: Dispatch;
  investmentAccount: SingleInvestmentAccount;
  user: User;
}

const AccountStatus = ({ investmentAccount, dispatch, user }: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  useEffect(() => {
    document.title = 'My Accounts | Confirmation';
    dispatch(loadAuth()).catch(err => handleErrorResponse(navigate, err));
    dispatch(loadInvestmentAccount(params.reference_id))
      .then(res => {
        if (res.investment_account.status !== accountStatus.created)
          dispatch(completeInvestmentAccount(res.investment_account.id)).then(
            () => {
              dispatch(loadAuth()).catch(err =>
                handleErrorResponse(navigate, err)
              );
            }
          );
      })
      .catch(err => handleErrorResponse(navigate, err));
  }, [dispatch, params, navigate]);

  const goToInvest = () => {
    let path = '/';

    const accountType =
      investmentAccount.account_type === 'ira'
        ? 'IRA'
        : utils.startCase(investmentAccount.account_type);

    EmAnalytics.track('Completes Opening Investment Account', 'Onboarding', {
      account_type: accountType
    });

    if (user.created_investment_accounts_count === 1) path += '?show=welcome';
    navigate(path);
  };

  const isFlagged = investmentAccount?.status === accountStatus.flagged;
  const cardHeader = investmentAccount && (
    <h2 className="margin-top-0">
      {investmentAccount.type !== 'ira'
        ? investmentAccount.title
        : 'Individual Retirement Account'}
    </h2>
  );

  return (
    <AccountWrap
      loading={!investmentAccount}
      account={investmentAccount}
      cardHeader={cardHeader}
      onCompletionPage
    >
      {investmentAccount && (
        <>
          <h4 className="margin-top-0">
            {isFlagged
              ? "You're almost ready to invest! We're just reviewing a few things."
              : 'You are ready to invest!'}
          </h4>
          <p>
            {!isFlagged &&
              'To get you started with investing, we have marked this account complete. '}
            On occasion we need to do extra verification of information that you
            submitted. If this is the case, our Investor Relations Team will
            reach out to you.
          </p>

          {investmentAccount.bank_accounts?.[0]?.status === 'unverified' && (
            <p>
              If you linked a bank account, you will need to take one more step
              in order to fund and receive distributions: return to the My
              Accounts page and verify the two small amounts we deposited into
              your bank account - this usually happens within 1-2 days.
            </p>
          )}

          <div className="forwardBackButtonWrapCompact">
            <Button
              variant="orange"
              onClick={goToInvest}
              className="button-fixed-width"
            >
              {isFlagged ? 'Start Investing' : 'Finish'}
            </Button>
          </div>
        </>
      )}
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    investmentAccount: state.account.investmentAccount,
    user: state.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(AccountStatus);
