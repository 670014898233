import { NavComponent } from 'containers/Offerings/types';
import React, { useEffect, useRef, useState } from 'react';

import utils from '../../../../utilities/utils';
import InvestButton from '../InvestButton/InvestButton';
import MobileSectionNav from '../MobileSectionNav/MobileSectionNav';
import * as styles from './MobileBar.module.scss';

interface Props {
  mustVerifyToInvest: boolean;
  navComponents: NavComponent[];
}

const MobileBar = ({ navComponents, mustVerifyToInvest }: Props) => {
  const [mobileBarVisible, setMobileBarVisible] = useState(false);
  const mobileBarVisibleRef = useRef(mobileBarVisible);

  const handleSetMobileBarVisible = value => {
    setMobileBarVisible(value);
    mobileBarVisibleRef.current = value;
  };

  const scrollHandler = utils.debounce(() => {
    if (window.innerWidth < 1200) {
      if (!mobileBarVisibleRef.current && window.scrollY > 700)
        handleSetMobileBarVisible(true);
      else if (mobileBarVisibleRef.current && window.scrollY < 700)
        handleSetMobileBarVisible(false);
    }
  }, 200);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`${styles.mobileBar} ${
        mobileBarVisible ? styles.visible : ''
      }`}
      data-testid="mobileBar"
    >
      <MobileSectionNav navComponents={navComponents} />

      <InvestButton
        className={styles.investButton}
        mustVerifyToInvest={mustVerifyToInvest}
      />
    </div>
  );
};

export default MobileBar;
