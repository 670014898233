import { RunCapitalCallsPayload } from 'types/actions/capital-call';

export const OPT_IN = 'capital-call/OPT_IN';
export const OPT_IN_SUCCESS = 'capital-call/OPT_IN_SUCCESS';
export const OPT_IN_FAIL = 'capital-call/OPT_IN_FAIL';
export const OPT_OUT = 'capital-call/OPT_OUT';
export const OPT_OUT_SUCCESS = 'capital-call/OPT_OUT_SUCCESS';
export const OPT_OUT_FAIL = 'capital-call/OPT_OUT_FAIL';
export const GET_ADDITIONAL_ALLOCATION =
  'capital-call/GET_ADDITIONAL_ALLOCATION';
export const GET_ADDITIONAL_ALLOCATION_SUCCESS =
  'capital-call/GET_ADDITIONAL_ALLOCATION_SUCCESS';
export const GET_ADDITIONAL_ALLOCATION_FAIL =
  'capital-call/GET_ADDITIONAL_ALLOCATION_FAIL';
export const CREATE_ADDITIONAL_INTEREST =
  'capital-call/CREATE_ADDITIONAL_INTEREST';
export const CREATE_ADDITIONAL_INTEREST_SUCCESS =
  'capital-call/CREATE_ADDITIONAL_INTEREST_SUCCESS';
export const CREATE_ADDITIONAL_INTEREST_FAIL =
  'capital-call/CREATE_ADDITIONAL_INTEREST_FAIL';
export const GET_BY_USER = 'capital-call/GET_BY_USER';
export const GET_BY_USER_SUCCESS = 'capital-call/GET_BY_USER_SUCCESS';
export const GET_BY_USER_FAIL = 'capital-call/GET_BY_USER_FAIL';
export const GET_BY_INVESTMENT = 'capital-call/GET_BY_INVESTMENT';
export const GET_BY_INVESTMENT_SUCCESS =
  'capital-call/GET_BY_INVESTMENT_SUCCESS';
export const GET_BY_INVESTMENT_FAIL = 'capital-call/GET_BY_INVESTMENT_FAIL';
export const RUN_CAPITAL_CALL = 'capital-call/RUN_CAPITAL_CALL';
export const RUN_CAPITAL_CALL_SUCCESS = 'capital-call/RUN_CAPITAL_CALL_SUCCESS';
export const RUN_CAPITAL_CALL_FAIL = 'capital-call/RUN_CAPITAL_CALL_FAIL';

export function optIn(investmentId) {
  return {
    types: [OPT_IN, OPT_IN_SUCCESS, OPT_IN_FAIL],
    promise: client => client.post(`/mkt/investments/${investmentId}/opt_in`)
  };
}

export function optOut(investmentId) {
  return {
    types: [OPT_OUT, OPT_OUT_SUCCESS, OPT_OUT_FAIL],
    promise: client => client.post(`/mkt/investments/${investmentId}/opt_out`)
  };
}

export function runCapitalCalls(investmentId, data: RunCapitalCallsPayload) {
  return {
    types: [RUN_CAPITAL_CALL, RUN_CAPITAL_CALL_SUCCESS, RUN_CAPITAL_CALL_FAIL],
    promise: client =>
      client.post(`/mkt/investments/${investmentId}/run_capital_call`, {
        data
      })
  };
}

export function createAdditionalInterest(investmentId, data) {
  return {
    types: [
      CREATE_ADDITIONAL_INTEREST,
      CREATE_ADDITIONAL_INTEREST_SUCCESS,
      CREATE_ADDITIONAL_INTEREST_FAIL
    ],
    promise: client =>
      client.post(`/mkt/investments/${investmentId}/additional_interest`, {
        data
      })
  };
}

export function getAdditionalAllocation(investmentId) {
  return {
    types: [
      GET_ADDITIONAL_ALLOCATION,
      GET_ADDITIONAL_ALLOCATION_SUCCESS,
      GET_ADDITIONAL_ALLOCATION_FAIL
    ],
    promise: client =>
      client.get(
        `/mkt/investments/${investmentId}/max_additional_capital_call_allocation`
      )
  };
}

export function getAllUserCapitalCalls() {
  return {
    types: [GET_BY_USER, GET_BY_USER_SUCCESS, GET_BY_USER_FAIL],
    promise: client => client.get('/mkt/transactions/capital_calls')
  };
}

export function getUserCapitalCallsForInvestment(investmentId) {
  return {
    types: [
      GET_BY_INVESTMENT,
      GET_BY_INVESTMENT_SUCCESS,
      GET_BY_INVESTMENT_FAIL
    ],
    promise: client =>
      client.get(`/mkt/investments/${investmentId}/capital_calls`)
  };
}
