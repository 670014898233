import { Button, Input, Select } from '@equitymultiple/react-eui';
import FormError from 'components/FormError/FormError';
import { stateOptions } from 'containers/Accounts/contents';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { UpdateUserProfilePayload } from 'types/actions/user-settings';
import { setFieldProps } from 'utilities/formHelpers';

interface Props {
  control: Control<UpdateUserProfilePayload>;
  errors: FieldErrors<UpdateUserProfilePayload>;
  isSubmitting?: boolean;
  residenceStatus?: string;
}

const AddressForm = ({
  control,
  errors,
  residenceStatus,
  isSubmitting
}: Props) => {
  return (
    <>
      <Row>
        <Col sm={6}>
          <Controller
            name="address_attributes.address"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address"
                placeholder="Street Address"
                maxLength={50}
              />
            )}
          />
        </Col>
        <Col sm={6}>
          <Controller
            name="address_attributes.address2"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address 2 (Optional)"
                placeholder="Apartment number, etc."
                maxLength={50}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Controller
            name="address_attributes.city"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="City" />
            )}
          />
        </Col>

        <Col sm={4}>
          {residenceStatus === 'Other' ? (
            <Controller
              name="address_attributes.state"
              control={control}
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="State / Province"
                />
              )}
            />
          ) : (
            <Controller
              name="address_attributes.state"
              control={control}
              render={({ field }) => (
                <Select
                  {...setFieldProps(field, errors)}
                  options={stateOptions}
                  label="State"
                />
              )}
            />
          )}
        </Col>
        <Col sm={4}>
          <Controller
            name="address_attributes.postal_code"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Postal Code"
                placeholder="Postal Code"
                mask="99999"
              />
            )}
          />
        </Col>
      </Row>
      <FormError errors={errors} />
      <Button
        data-testid="profileSubmit"
        style={{ width: '140px' }}
        className="float-right"
        loading={isSubmitting}
        name="submit"
        type="submit"
      >
        Update
      </Button>
    </>
  );
};

export default AddressForm;
