import { EMLoadingIcon } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import utils from 'utilities/utils';

const PlaidLinkOAuth = () => {
  const navigate = useNavigate();
  const token = utils.getLocalStorage('plaidLinkToken');

  useEffect(() => {
    if (!token) {
      navigate('/');
    } else {
      utils.setLocalStorage('oauthRedirectUri', window.location.href);
      navigate(utils.getLocalStorage('plaidLinkRedirect'));
    }
  }, [token, navigate]);

  return <EMLoadingIcon data-testid="EmLoadingIcon" />;
};

export default PlaidLinkOAuth;
