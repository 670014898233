import '../../faFiveLibrary';

import { Tooltip } from '@equitymultiple/react-eui';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-grid-system';

import * as sharedStyles from '../../OfferingShared.module.scss';
import * as styles from './KeyMetrics.module.scss';

interface Props {
  keyMetrics: {
    help_text: string;
    icon: IconProp;
    key: string;
  }[];
  offeringData: {
    content: string;
    key: string;
    label: string;
  }[];
}

const KeyMetrics = ({ keyMetrics, offeringData }: Props) => {
  const keyMetricBlocks = keyMetrics
    ? keyMetrics.map(km => {
        const metricData = offeringData.find(data => data.key === km.key);
        return (
          <Col md={4} xs={6} key={metricData.label}>
            <i className={`fa ${km.icon}`} />
            <FontAwesomeIcon icon={km.icon} />
            <div className={`text-label ${styles.textLabel}`}>
              {metricData.label}
              {km.help_text && (
                <Tooltip
                  tooltipContent={km.help_text}
                  className={styles.infoIcon}
                  infoIcon
                  placement="right"
                />
              )}
            </div>
            <p className={`${sharedStyles.metaData} ${styles.metaData}`}>
              {metricData.content}
            </p>
          </Col>
        );
      })
    : null;
  return keyMetricBlocks ? (
    <div className={styles.keyMetrics}>
      <Row>{keyMetricBlocks}</Row>
    </div>
  ) : null;
};

export default KeyMetrics;
