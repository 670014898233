import { Button, Card, Input, Tooltip } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import { HeaderSimple } from 'components/HeaderSimple';
import useRecaptcha from 'hooks/useRecaptcha/useRecaptcha';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPassword, setAuthSubmitting } from 'redux/actions/auth';
import { updateSignupValues } from 'redux/actions/onboarding';
import { Dispatch } from 'types/redux';
import { getRecaptchaToken } from 'utilities/captcha';
import { adBlockerIntercomTooltip } from 'utilities/constants';
import { setFieldProps } from 'utilities/formHelpers';
import humane from 'utilities/humane';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import * as styles from './ForgotPassword.module.scss';
import forgotPasswordSchema from './validation';

interface Props {
  dispatch: Dispatch;
  incompleteSignup: boolean;
  loading: boolean;
  sentForgotEmail: boolean;
  sentForgotEmailFailed: boolean;
}

interface FormFields {
  email: string;
}

const ForgotPassword = ({
  dispatch,
  incompleteSignup,
  sentForgotEmailFailed,
  sentForgotEmail,
  loading
}: Props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError
  } = useForm<FormFields>({
    resolver: yupResolver(forgotPasswordSchema)
  });

  useRecaptcha();

  useEffect(() => {
    document.title = 'Forgot Password | EquityMultiple';
  }, []);

  useEffect(() => {
    if (sentForgotEmail && !incompleteSignup)
      humane.notice(
        'We resent you an email with a reset password link. It may take a few minutes to arrive.'
      );
  }, [incompleteSignup, sentForgotEmail]);

  useEffect(() => {
    if (sentForgotEmailFailed)
      humane.error(
        'There was a problem resending the email, please try again.'
      );
  }, [sentForgotEmailFailed]);

  const onSubmit = async formData => {
    dispatch(setAuthSubmitting());

    const token = await getRecaptchaToken('forgotPassword');

    const data = {
      user: {
        ...formData,
        captcha_response: token
      }
    };

    setEmail(formData.email);

    return dispatch(forgotPassword(data))
      .then(res => {
        // For users who created an account but exited before setting their password
        if (res?.step === 2) {
          dispatch(
            updateSignupValues({
              email: data.user.email
            })
          );
          navigate('/users/signup/password', { replace: true });
        }
      })
      .catch(err => {
        throwReactHookFormSubmissionErrors(err, setError);
      });
  };

  const handleResend = async () => {
    const token = await getRecaptchaToken('forgotPassword');

    const data = {
      user: {
        email,
        captcha_response: token
      }
    };

    dispatch(forgotPassword(data));
  };

  const formNotSubmitted = !sentForgotEmail && !email;

  return (
    <div>
      <HeaderSimple firstPage />
      <Container>
        <Card>
          {formNotSubmitted ? (
            <div>
              <h4 className="margin-top-0">Forgot your password?</h4>
              <p>
                If you forgot your password, enter your email address below and
                clicking Reset Password. We'll send you an email with
                instructions to complete the password reset process.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <h6>Enter your email address</h6>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...setFieldProps(field, errors)}
                      label="Email"
                      className="input-fixed-width"
                      data-testid="forgotPassword"
                    />
                  )}
                />

                <div className={styles.description}>
                  *Note, if you're having trouble, you can talk to us by
                  clicking on the chat icon{' '}
                  <Tooltip tooltipContent={adBlockerIntercomTooltip} infoIcon />{' '}
                  in the bottom right.
                </div>

                <FormError errors={errors} />

                <Button
                  type="submit"
                  className="float-right"
                  loading={isSubmitting || loading}
                >
                  Reset Password
                </Button>
              </form>
            </div>
          ) : (
            <div className={styles.emailSent}>
              <h4 className="margin-top-0">
                Check your email for further instructions
              </h4>
              <p>
                We've sent you an email with a link to update your password.
              </p>
              <p>
                On occasion, email from us can get stuck in spam. If you don't
                receive an email within a few minutes, please check your spam
                folder or any other email filtering software you may have.
              </p>
              <h6 className={styles.h6}>Did not receive the email?</h6>
              <p>
                <button
                  className="text-link"
                  onClick={handleResend}
                  type="button"
                >
                  Click here
                </button>{' '}
                to resend the email to {email}
              </p>
            </div>
          )}
        </Card>
      </Container>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.auth.loading,
    sentForgotEmail: store.auth.sentForgotEmail,
    resentForgotEmail: store.auth.resentForgotEmail,
    sentForgotEmailFailed: store.auth.sentForgotEmailFailed,
    incompleteSignup: !!store.auth.signupValues.email
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(ForgotPassword);
