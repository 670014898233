import { Input, Select } from '@equitymultiple/react-eui';
import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path
} from 'react-hook-form';
import { User } from 'types/api/user';
import PHONE_CODES from 'utilities/countryCodes';
import { setFieldProps } from 'utilities/formHelpers';
import { phoneMask } from 'utilities/masks';

import * as styles from './PhoneFields.module.scss';

export interface PhoneFormFields {
  country_code: User['country_code'];
  phone: User['phone'];
}

interface Props<T extends FieldValues> {
  control: Control<T>;
  errors: FieldErrors<T>;
}

const PhoneFields = <T extends FieldValues>({ control, errors }: Props<T>) => (
  <div className={`${styles.phoneFieldWrap} input-fixed-width-wide`}>
    <Controller
      name={'country_code' as Path<T>}
      control={control}
      render={({ field }) => (
        <Select
          {...setFieldProps(field, errors)}
          label="Country Code"
          options={PHONE_CODES}
        />
      )}
    />
    <Controller
      name={'phone' as Path<T>}
      control={control}
      render={({ field }) => (
        <Input
          {...setFieldProps(field, errors)}
          label="Phone Number"
          mask={phoneMask}
        />
      )}
    />
  </div>
);

export default PhoneFields;
