export const LOAD = 'documents/LOAD';
export const LOAD_SUCCESS = 'documents/LOAD_SUCCESS';
export const LOAD_FAIL = 'documents/LOAD_FAIL';

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get('/mkt/documents')
  };
}
