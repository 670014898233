import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAsterisk,
  faBan,
  faBars,
  faBolt,
  faBookmark,
  faBriefcase,
  faBuilding,
  faCalendar,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheckSquare,
  faClock,
  faCloud,
  faCog,
  faComment,
  faCompass,
  faCubes,
  faDollarSign,
  faEnvelope,
  faExchangeAlt,
  faGem,
  faGlobe,
  faGlobeAmericas,
  faHistory,
  faHome,
  faKey,
  faMinus,
  faPlus,
  faUniversity,
  faUsers
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faCalendar,
  faBan,
  faChartArea,
  faAsterisk,
  faUniversity,
  faBars,
  faBolt,
  faBriefcase,
  faBuilding,
  faBookmark,
  faCheckSquare,
  faCog,
  faClock,
  faCloud,
  faComment,
  faGem,
  faCubes,
  faCompass,
  faEnvelope,
  faExchangeAlt,
  faUsers,
  faGlobe,
  faGlobeAmericas,
  faHistory,
  faHome,
  faKey,
  faChartLine,
  faPlus,
  faMinus,
  faChartBar,
  faChartPie,
  faDollarSign
);
