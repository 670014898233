import { Button, FileUploader } from '@equitymultiple/react-eui';
import FormError from 'components/FormError/FormError';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  loadInvestmentAccount,
  loadInvestmentAccountDocuments,
  uploadInvestmentAccountDocuments
} from 'redux/actions/account';
import { AccountDocuments, SingleInvestmentAccount } from 'types/api/account';
import { Dispatch } from 'types/redux';
import { maxUploadSize } from 'utilities/constants';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { isComplete, supportsBeneficialOwners } from '../helpers';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';
import DocumentPreview from './DocumentPreview/DocumentPreview';

type Params = {
  reference_id: string;
};

interface Props {
  dispatch: Dispatch;
  documents: AccountDocuments;
  investmentAccount: SingleInvestmentAccount;
  uploading: boolean;
}

const TaxDocument = ({
  dispatch,
  documents,
  investmentAccount,
  uploading
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const {
    formState: { errors },
    setError
  } = useForm();

  useEffect(() => {
    document.title = 'My Accounts | Tax Document';
    dispatch(loadInvestmentAccount(params.reference_id))
      .then(() =>
        dispatch(loadInvestmentAccountDocuments(params.reference_id)).catch(
          error => handleErrorResponse(navigate, error)
        )
      )
      .catch(error => handleErrorResponse(navigate, error));
  }, [dispatch, params, navigate]);

  const handleUpload = file => {
    const values = {
      investment_account: {
        id: investmentAccount.id,
        id_type: 'ein_evidence_attachment',
        file
      }
    };

    return dispatch(uploadInvestmentAccountDocuments(values))
      .then(res => {
        if (res.documents.ein_evidence_attachment?.name)
          dispatch(loadInvestmentAccountDocuments(params.reference_id));
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  const handleContinue = () => {
    if (isComplete(investmentAccount)) {
      navigate(`/accounts/update/entity/documents/${params.reference_id}`);
    } else {
      EmAnalytics.track('Submits Entity Account Tax Doc', 'Onboarding', {
        account_type: 'entity'
      });
      if (supportsBeneficialOwners(investmentAccount)) {
        navigate(
          `/accounts/wizard/entity/beneficial_owners/${params.reference_id}`
        );
      } else {
        navigate(`/accounts/wizard/entity/w9/${params.reference_id}`);
      }
    }
  };

  const completeAccount =
    investmentAccount &&
    isComplete(investmentAccount) &&
    !location.pathname.includes('/accounts/wizard');
  const einVerificationOptional =
    investmentAccount?.business_type === 'trust_revocable' ||
    investmentAccount?.business_type === 'soleproprietorship';
  const documentUploaded = !!documents?.ein_evidence_attachment?.name;
  const buttonDisabled =
    uploading || (!einVerificationOptional && !documentUploaded);

  return (
    <AccountWrap loading={!investmentAccount} account={investmentAccount}>
      {investmentAccount && (
        <>
          <h4 className="margin-top-0">
            Now we&apos;ll upload your Tax Document
          </h4>
          {einVerificationOptional ? (
            investmentAccount.business_type === 'soleproprietorship' ? (
              <p>
                Please upload your EIN verification letter to verify your Sole
                Proprietorship entity account. If you do not have your EIN
                verification letter, we accept the following tax document
                alternatives: Fictitious Business Name Statement, Certificate of
                Assumed Name; Business License, Sales/Use Tax License,
                Registration of Trade Name.
              </p>
            ) : (
              <p>
                Please upload your EIN verification letter to verify your entity
                account. If you do not have your EIN verification letter please
                skip step.
              </p>
            )
          ) : (
            <p>
              Please upload your EIN verification letter. This is your
              IRS-issued SS4 confirmation letter.
            </p>
          )}

          {documents?.ein_evidence_attachment?.name && (
            <DocumentPreview
              document={documents.ein_evidence_attachment}
              documentType="Tax Document"
            />
          )}
          <h5>Upload New Tax Document</h5>

          <form className="margin-xx">
            <FileUploader
              uploading={uploading}
              upload={handleUpload}
              maxSize={maxUploadSize}
              onRemove={() => {}}
            />
            <FormError errors={errors} />
          </form>

          <div className="forwardBackButtonWrapCompact">
            <Button
              onClick={handleContinue}
              disabled={buttonDisabled}
              style={{ width: 160 }}
              variant="orange"
            >
              {einVerificationOptional && !documentUploaded
                ? 'Skip'
                : completeAccount
                  ? 'Update'
                  : 'Continue'}
            </Button>
            <BackLink
              investmentAccount={investmentAccount}
              page="Tax Document"
            />
          </div>
        </>
      )}
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    documents: state.account.documents,
    investmentAccount: state.account.investmentAccount,
    uploading: state.account.uploading
  };
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(TaxDocument);
