import { Checkbox } from '@equitymultiple/react-eui';
import RichText from 'components/RichText';
import { FundFormFields } from 'containers/Investment/types';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { setCheckboxFieldProps } from 'utilities/formHelpers';

interface Props {
  control: Control<FundFormFields>;
  errors: FieldErrors<FundFormFields>;
  instructions: string;
}

const CustomFundingInstructions = ({
  instructions,
  control,
  errors
}: Props) => {
  return (
    <>
      <h6>Funding Instructions</h6>
      <RichText text={instructions} sanitize />

      <Controller
        name="payment_method"
        control={control}
        render={({ field }) => (
          <Checkbox
            {...setCheckboxFieldProps(field, errors)}
            label="I understand that in order to participate in this offering, I must make accommodations to fund according to the funding instructions above."
          />
        )}
      />
    </>
  );
};

export default CustomFundingInstructions;
