// extracted by mini-css-extract-plugin
var _1 = "QNzic3TdCqRkoPixKpM3";
var _2 = "O6kXbkCTnldzq7_p3GPP";
var _3 = "m2aKehJYswqIxRxSPRaE";
var _4 = "AQ2TvIohih0c9UMgxEEz";
var _5 = "sd1gboZTK8FU2nkyMdCV";
var _6 = "gkOAflI2glioH1l6c8LL";
var _7 = "uPyVna1Mz_LNegF4BdwN";
var _8 = "x0itkO5Z9z0gLHzfZuuX";
export { _1 as "createdInviteImg", _2 as "investing", _3 as "inviteMetaDetails", _4 as "labelGreen", _5 as "metaBlock", _6 as "metaTitle", _7 as "smallText", _8 as "userCreditData" }
