import React from 'react';
import { Link } from 'react-router-dom';

const TaxTrackerBanner = () => {
  const lastYear = new Date().getFullYear() - 1;

  return (
    <>
      Track your {lastYear} Tax Documents{' '}
      <Link className="text-link-light underline" to="/tax_tracker">
        here
      </Link>
      .
    </>
  );
};

export default TaxTrackerBanner;
