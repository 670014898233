import { Button } from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';

import * as sharedStyles from '../../SettingsShared.module.scss';
import * as styles from './PreferenceCenter.module.scss';

type Props = {
  email: string;
};

const PreferenceCenter = ({ email }: Props) => {
  return (
    <div className="margin-top-xxx" data-testid="preferencesTab">
      <Row className="margin-xxxx">
        <Col lg={3}>
          <h3 className={sharedStyles.h3}>Communication Preferences</h3>
        </Col>
        <Col lg={9}>
          <div className={styles.preferenceCopyWrap}>
            <p>
              How should we stay in touch with you? You may return here to
              update your communication preferences at any time.
            </p>
            <Button
              href={`${process.env.REACT_APP_COMMUNICATION_PREFERENCE_URL}/?email=${email}`}
              target="_blank"
            >
              Edit Communication Preferences
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="margin-xxxx">
        <Col lg={3}>
          <h3 className={sharedStyles.h3}>Investment Preferences</h3>
        </Col>
        <Col lg={9}>
          <div className={styles.preferenceCopyWrap}>
            <p>
              What type of real estate investments best fit your strategy? You
              may return here to update your investment preferences at any time.
            </p>
            <Button
              href={`${process.env.REACT_APP_INVESTMENT_PREFERENCE_URL}/?email=${email}`}
              target="_blank"
            >
              Edit Investment Preferences
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PreferenceCenter;
