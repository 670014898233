import {
  Alert,
  Card,
  Checkbox,
  Select,
  Table
} from '@equitymultiple/react-eui';
import { SingleInvestment } from 'containers/Portfolio/types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import utils from '../../../../utilities/utils';
import DownloadPopUp from '../../../MyDocuments/TaxDocuments/DownloadPopUp';
import { documentColumns, documentOptions } from '../../constants';
import { documentDownload } from '../../helpers';
import * as styles from './InvestmentDocuments.module.scss';

interface Props {
  investment: SingleInvestment;
}

const InvestmentDocuments = ({ investment }: Props) => {
  const [documents, setDocuments] = useState([]);
  const [documentType, setDocumentType] = useState('all');
  const [loading, setLoading] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const setSortedDocuments = () => {
    const { documents: unsortedDocuments } = investment;

    const selectedUnsortedDocuments =
      documentType === 'all'
        ? [
            ...unsortedDocuments.investment_documents,
            ...unsortedDocuments.tax_documents
          ]
        : [...unsortedDocuments[documentType]];

    const sortedDocuments = selectedUnsortedDocuments.sort((a, b) =>
      a.created_at > b.created_at ? -1 : 1
    );

    setDocuments(sortedDocuments);
  };

  const changeDocumentType = value => {
    setDocumentType(value);
    setSelectedDocuments([]);
  };

  useEffect(() => {
    setSortedDocuments();
  }, [documentType]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectDocument = id => {
    const newSelectedDocuments = [...selectedDocuments];

    if (selectedDocuments.includes(id)) {
      const docIndex = selectedDocuments.indexOf(id);
      newSelectedDocuments.splice(docIndex, 1);
    } else {
      newSelectedDocuments.push(id);
    }

    setSelectedDocuments(newSelectedDocuments);
  };

  const handleToggleAllDocuments = () => {
    if (selectedDocuments.length === documents.length) {
      setSelectedDocuments([]);
    } else {
      const newSelectedDocuments = documents.map(
        doc => doc.document_id || doc.id
      );
      setSelectedDocuments(newSelectedDocuments);
    }
  };

  const downloadDocuments = () => {
    setLoading(true);
    documentDownload(selectedDocuments)
      .then(res => {
        utils.downloadResponse(res);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getRows = () => {
    return documents.map(document => {
      let documentTitle = document.title;

      if (
        document.type === 'Subscription Agreement' &&
        !document.meta?.is_complete
      )
        documentTitle = (
          <>
            <strong>Pending Countersignature - </strong>
            {document.title}
          </>
        );

      return {
        cells: [
          <Checkbox
            id={`select-document-${document.id}`}
            key={document.id}
            label=""
            onChange={() => handleSelectDocument(document.id)}
            checked={selectedDocuments.includes(document.id)}
          />,
          utils.dateFormat(document.created_at),
          documentTitle,
          document.type,
          document.year,
          <a
            key="download"
            href={`/mkt/documents/download?ids=${document.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-external-link" />
          </a>
        ]
      };
    });
  };

  const getColumnHeaders = () => {
    const headers: (string | React.ReactNode)[] = [...documentColumns];
    headers[0] = (
      <Checkbox
        id="select-all-documents"
        key="select-all"
        label=""
        onChange={handleToggleAllDocuments}
        checked={documents.length === selectedDocuments.length}
      />
    );

    return headers;
  };

  const taxStatus = investment.offering_tax_status;
  const taxDocumentAvailable = taxStatus?.step === 'tax_document_available';

  return (
    <div className={styles.investmentDocuments}>
      {taxStatus && (
        <Alert
          className={styles.inlineMessage}
          type={taxDocumentAvailable ? 'positive' : 'neutral'}
        >
          <div>
            <span>{`Your ${taxStatus.document_type} tax document is ${
              taxDocumentAvailable ? 'available' : 'processing'
            }.`}</span>
            {!taxDocumentAvailable && (
              <>
                {' '}
                <Link
                  className="underline"
                  to="/tax_tracker"
                  target="_blank"
                  rel="noreferrer"
                >
                  View your tax tracker
                </Link>{' '}
                to learn more.
              </>
            )}
          </div>
        </Alert>
      )}
      <Card className="padding0">
        <div className="padding20">
          <p>Filter by document type:</p>
          <Select
            id="document-type"
            className="input-fixed-width"
            name="document-type"
            onChange={value => changeDocumentType(value)}
            label="Document Type"
            options={documentOptions}
            value={documentType}
          />
        </div>

        {documents.length === 0 ? (
          <h4 className="margin-top-0 padding20">No documents found.</h4>
        ) : (
          <Table columnHeaders={getColumnHeaders()} rows={getRows()} />
        )}
        {selectedDocuments.length > 0 && (
          <DownloadPopUp
            downloadDocuments={downloadDocuments}
            loading={loading}
            selectedCount={selectedDocuments.length}
          />
        )}
      </Card>
    </div>
  );
};

export default InvestmentDocuments;
