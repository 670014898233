import { Alert, EMLoadingIcon } from '@equitymultiple/react-eui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { loadInvestmentAccounts } from 'redux/actions/account';
import { InvestmentAccount } from 'types/api/account';
import { Closing } from 'types/api/closing';
import { Offering as OfferingType } from 'types/api/offering';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import { canInvest, isAccredited } from 'utilities/user';

import {
  confidentialityAgreementAccepted,
  fetchOffering
} from '../../redux/actions/offerings';
import EmAnalytics from '../../utilities/em_analytics';
import humane from '../../utilities/humane';
import {
  Canvas,
  Header,
  Infobar,
  MobileBar,
  SideNavigation
} from './components';
import * as styles from './Offering.module.scss';

type Params = {
  closing_id: string;
  id: string;
  offering_id: string;
};

interface Props {
  closing: Closing;
  currentUser: User;
  dispatch: Dispatch;
  fetching: boolean;
  investmentAccounts: InvestmentAccount[];
  offering: OfferingType;
}

const sortComponentsByVpos = components =>
  components.sort((a, b) => {
    if (a.vpos < b.vpos) return -1;
    if (a.vpos > b.vpos) return 1;
    return 0;
  });

const Offering = ({
  closing,
  currentUser,
  dispatch,
  fetching,
  investmentAccounts,
  offering
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const [canvasComponents, setCanvasComponents] = useState([]);
  const [hero, setHero] = useState(null);
  const [images, setImages] = useState([]);
  const [navComponents, setNavComponents] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [mustVerifyToInvest, setMustVerifyToInvest] = useState(false);

  const handleError = () => {
    humane.error('An error occured while loading this offering.');
    navigate('/');
  };

  const handleSetImages = offeringRes => {
    // Iterate through all components with type 'image' or 'hero' and add their images to an aggregate array
    const newImages = [];
    const imageComponents = offeringRes.omcms.components.filter(
      component => component.type === 'image' || component.type === 'hero'
    );
    if (imageComponents) {
      imageComponents.forEach(imageComponent => {
        if (imageComponent.data.omcms && imageComponent.data.omcms.images) {
          imageComponent.data.omcms.images.forEach(image =>
            newImages.push(image)
          );
          setImages(newImages);
        }
      });
    }
  };

  const handleSetHero = offeringRes => {
    // Find the first hero component and put its data in state
    const heroComponents = offeringRes.omcms.components.filter(
      component => component.type === 'hero'
    );
    if (heroComponents) setHero(heroComponents[0]);
  };

  const setCanvasNavData = offeringRes => {
    // Clean and sort components, set nav and canvas component arrays

    // Remove hero components
    let newCanvasComponents = offeringRes.omcms.components.filter(
      component => component.type !== 'hero'
    );

    // Add children property to sections
    newCanvasComponents.forEach(component => {
      if (component.type === 'section' || component.type === 'section-parallel')
        component.children = [];
    });

    // Find children and insert into parent
    newCanvasComponents.forEach(component => {
      if (component.parent_id) {
        const parentComponent = newCanvasComponents.find(
          parentComp => parentComp.id === component.parent_id
        );
        parentComponent.children.push(component);
      }
    });

    // Remove children
    newCanvasComponents = newCanvasComponents.filter(
      component => !component.parent_id
    );

    // Sort outer components
    sortComponentsByVpos(newCanvasComponents);

    // Iterate through each component's children and sort
    const sortChildren = component => {
      if (component.children?.length > 0) {
        component.children = sortComponentsByVpos(component.children);
        component.children.forEach(child => sortChildren(child));
      }
    };
    newCanvasComponents.forEach(component => sortChildren(component));

    const newNavComponents = newCanvasComponents.filter(
      component =>
        component.type === 'section' || component.type === 'section-parallel'
    );

    // Set canvas and nav components in state
    setCanvasComponents(newCanvasComponents);
    setNavComponents(newNavComponents);
  };

  const setOfferingData = offeringRes => {
    if (offeringRes.omcms.components?.length > 0) {
      // Set hero and image data
      handleSetHero(offeringRes);
      handleSetImages(offeringRes);

      // Sort offerings and set canvas and nav component arrays
      setCanvasNavData(offeringRes);
    }
  };

  useEffect(() => {
    const offeringId = params.offering_id;
    const closingId = params.closing_id;

    if (!isAccredited(currentUser)) navigate('/');

    dispatch(fetchOffering(offeringId, closingId))
      .then(res => {
        if (res.offering) {
          if (res.offering.confidentiality_required) {
            dispatch(confidentialityAgreementAccepted(params.offering_id)).then(
              accepted => {
                if (!accepted)
                  navigate(
                    `/users/offerings/${offeringId}/closings/${closingId}/confidentiality`
                  );
              }
            );
          }
          if (res.offering.omcms && !res.offering.omcms.error)
            setOfferingData(res.offering);
          else handleError();
        } else {
          handleError();
        }
      })
      .catch(() => {
        handleError();
      });
    document.title = 'Invest | EquityMultiple';

    dispatch(loadInvestmentAccounts()).catch(() => {
      handleError();
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      canInvest(currentUser) &&
      moment(new Date()).isAfter(closing?.funding_end_date, 'day')
    ) {
      const accreditationVerified = investmentAccounts?.some(
        account => account.accreditation_status === 'verified'
      );
      setMustVerifyToInvest(!accreditationVerified);
    }
  }, [closing, investmentAccounts, currentUser]);

  const toggleGalleryModal = () => {
    setShowGallery(!showGallery);
  };

  const trackSideNavigationLinkClick = linkText => {
    const data = {
      investmentExperience: currentUser.track.level_of_investing_experience,
      offeringName: offering.title,
      link: linkText,
      stage: currentUser.track.stage
    };
    EmAnalytics.track('Clicks OM side navigation', 'Navigation', data);
  };

  const investmentMinimumEnabled = offering?.investment_minimum_enabled;
  const loading = fetching || !offering || !closing || !investmentAccounts;

  return (
    <div>
      {loading ? (
        <EMLoadingIcon data-testid="loadingIcon" />
      ) : (
        <div className={styles.offering}>
          <Header
            hero={hero}
            images={images}
            offering={offering}
            navComponents={navComponents}
            showGallery={showGallery}
            trackSideNavigationLinkClick={trackSideNavigationLinkClick}
            toggleGallery={toggleGalleryModal}
            mustVerifyToInvest={mustVerifyToInvest}
          />
          <div className={styles.offeringContent}>
            <Container>
              <Row>
                <Col xl={10}>
                  {investmentMinimumEnabled && (
                    <Alert type="positive">
                      You can now invest a lower investment amount. The minimum
                      has been fulfilled.
                    </Alert>
                  )}
                  <Infobar
                    offering={offering}
                    closing={closing}
                    mustVerifyToInvest={mustVerifyToInvest}
                  />
                  <Canvas
                    components={canvasComponents}
                    offering={offering}
                    user={currentUser}
                  />
                </Col>
                <Col xl={2} className={styles.sideNavColumn}>
                  <SideNavigation
                    navComponents={navComponents}
                    type="bottom"
                    trackLinkClick={trackSideNavigationLinkClick}
                    toggleGallery={toggleGalleryModal}
                    mustVerifyToInvest={mustVerifyToInvest}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <MobileBar
            navComponents={navComponents}
            mustVerifyToInvest={mustVerifyToInvest}
          />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(store) {
  return {
    fetching: store.offerings.fetching,
    offering: store.offerings.offering,
    closing: store.offerings.closing,
    currentUser: store.auth.user,
    investmentAccounts: store.account.investmentAccounts
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Offering);
