export const updateAlertDescriptions = {
  tax_address_verification: 'Verify Account Information',
  individual_joint_tax_filing_status_update: 'Verify Tax Filing Status',
  privacy_policy_and_terms_of_service_opt_in:
    'Terms of Service and Privacy Updates',
  residence_status_update: 'Residence Status Update'
};

export const updateAlertStatuses = {
  active: 'present',
  upcoming: 'future',
  completed: 'complete'
};
