// Max file size for uploads, currently 50mb
export const maxUploadSize = 1024 * 1024 * 50;

// File extensions and their associated formats
// Some file exentions such as DOC or XLS have multiple accepted formats, in these cases we just use the extension
export const fileTypes = [
  {
    extension: 'JPG',
    format: 'image/jpeg'
  },
  {
    extension: 'PDF',
    format: 'application/pdf'
  },
  {
    extension: 'PNG',
    format: 'image/png'
  },
  {
    extension: 'ZIP',
    format: 'zip,application/zip,application/x-zip,application/x-zip-compressed'
  },
  {
    extension: 'DOC',
    format: '.doc'
  },
  {
    extension: 'DOCX',
    format: '.docx'
  },
  {
    extension: 'XLS',
    format: '.xls'
  },
  {
    extension: 'XLSX',
    format: '.xlsx'
  }
];

// Routes that should never be accessed by unauthenticated users
export const loggedOutPaths = [
  '/users/sign_in',
  '/users/signup/start',
  '/users/signup/password',
  '/users/invitation/accept',
  '/users/locked',
  '/users/password/new',
  '/users/password/edit'
];

// Routes that shouldn't show the full header
export const noHeaderPaths = [
  '/users/sign_in',
  '/users/signup/start',
  '/users/signup/password',
  '/users/signup/name',
  '/users/signup/accreditation',
  '/users/signup/continue',
  '/users/invitation/accept',
  '/users/update_account',
  '/users/password/edit'
];

export const adBlockerIntercomMessage =
  'Ad blocking plug-in detected - Messenger unable to launch. While our Messenger does not include third-party advertisements, the Messenger powered by Intercom does offer helpful articles, news, tooltips, and provides you with direct connection to one of our dedicated Investor Relations Specialists. To access the Messenger, please remove EquityMultiple from your block list.';

export const adBlockerIntercomTooltip =
  'If you are unable to see the chat box launcher, please disable any ad-blocking software in use.';

export const ascentOfferingId = 957;
