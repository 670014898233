import { Select } from '@equitymultiple/react-eui';
import {
  experienceOptions,
  objectivesOptions,
  reasonOptions,
  riskToleranceOptions
} from 'containers/Profile/content';
import { accreditationOptions } from 'containers/SignUp/content';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { UpdateUserProfilePayload } from 'types/actions/user-settings';
import { UserStage } from 'types/api/user';
import { setFieldProps } from 'utilities/formHelpers';

interface Props {
  control: Control<UpdateUserProfilePayload>;
  errors: FieldErrors<UpdateUserProfilePayload>;
  userStage: UserStage;
}

const InvestorProfileForm = ({ control, errors, userStage }: Props) => {
  return (
    <div className="clearfix">
      <p>My primary real-estate investing objective is:</p>
      <Controller
        name="investor_profile_attributes.investment_objectives"
        control={control}
        render={({ field }) => (
          <Select
            {...setFieldProps(field, errors)}
            options={objectivesOptions}
            className="input-fixed-width-wide margin-xx"
            placeholder="Select"
          />
        )}
      />

      <p>Investment Experience</p>
      <Controller
        name="investor_profile_attributes.investment_experience"
        control={control}
        render={({ field }) => (
          <Select
            {...setFieldProps(field, errors)}
            options={experienceOptions}
            multi
            showMultiLabelsBelow
            className="input-fixed-width-wide margin-xx"
            placeholder="Select"
          />
        )}
      />

      <p>What are you investing for?</p>
      <Controller
        name="investor_profile_attributes.investment_reason"
        control={control}
        render={({ field }) => (
          <Select
            {...setFieldProps(field, errors)}
            options={reasonOptions}
            multi
            showMultiLabelsBelow
            className="input-fixed-width-wide margin-xx"
            placeholder="Select"
          />
        )}
      />

      <p>My risk tolerance is:</p>
      <Controller
        name="investor_profile_attributes.risk_tolerance"
        control={control}
        render={({ field }) => (
          <Select
            {...setFieldProps(field, errors)}
            options={riskToleranceOptions}
            className="input-fixed-width-wide margin-xx"
            placeholder="Select"
          />
        )}
      />

      {userStage === 'activate' && (
        <>
          <p>Select what describes you best</p>
          <Controller
            name="investor_profile_attributes.accreditation"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={accreditationOptions}
                multi
                showMultiLabelsBelow
                className="input-fixed-width-wide margin-xx"
                placeholder="Select"
              />
            )}
          />
        </>
      )}
    </div>
  );
};

export default InvestorProfileForm;
