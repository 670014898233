import * as yup from 'yup';

import { validations } from '../../utilities/yupValidations';

const { fields } = validations;

const forgotPasswordSchema = yup.object().shape({
  email: fields.email
});

export default forgotPasswordSchema;
