// extracted by mini-css-extract-plugin
var _1 = "JUDb1vnxuFMcbli70MkB";
var _2 = "XcJysYms93LXlkkUe_Iu";
var _3 = "zNRLal7OkGN8aIFNlZVA";
var _4 = "VFMZSSYLtH57RP0O026W";
var _5 = "ilEFRdTgjTgK7rldzzeH";
var _6 = "Aj_ZC43OR2a2gIJINk3E";
var _7 = "ey04CJOc0ixAF06YfLLF";
var _8 = "BlJ6l_OMO7z7a6ZCH2Ir";
export { _1 as "accountDetails", _2 as "accountName", _3 as "accountsCard", _4 as "accountsTable", _5 as "bottom", _6 as "newAccount", _7 as "rowLink", _8 as "top" }
