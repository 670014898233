import { Tooltip } from '@equitymultiple/react-eui';
import React from 'react';

import utils from '../../utilities/utils';
import * as styles from './containers/AccountShared.module.scss';

export const tooltips = {
  titles: {
    keep: 'Alternative to savings. Compelling rates, shortest term.',
    earn: 'Investments focused on near-term income.',
    grow: 'Investments focused on longer-term growth and upside potential.',
    exited: 'Your exited investments. No further payments are anticipated.'
  },
  performanceStatus:
    '"Performance Status” represents the status of overall performance of the Investment. Investments are categorized as Over-Performing, Performing, Underperforming or Non-Performing. Performance status is based on EquityMultiple\'s subjective assessment of performance and is generally updated on an annual basis. Performance categories are primarily driven by i) "Key Quantitative Performance Metrics" - revenue, NOI, distribution yield, occupancy, renovation/construction spend vs. completion timing, ii) forecasted returns relative to original underwriting and iii) events of defaults and other qualitative factors.',
  performanceStatuses: {
    overperforming:
      'Over-performing - Investment is materially over-performing expectations.',
    performing:
      'Performing - Investment is performing generally in line with expectations.',
    underperforming:
      'Underperforming - Investment is meaningfully underperforming expectations but is not considered at significant risk of material loss.',
    nonperforming:
      'Non-performing - Investment is in material default and/or at substantial risk of material loss.'
  },
  exitedPerformanceStatuses: {
    overperforming:
      'Overperformed - Investment materially over-performed expectations.',
    performing:
      'Performed - Investment performed generally in line with expectations.',
    underperforming:
      'Underperformed - Investment meaningfully underperformed expectations.',
    nonperforming:
      'Did not perform - Investment defaulted and/or resulted in substantial risk of material loss.'
  },
  growPerformanceStatuses: {
    nonperforming:
      'Non-performing - Investment is in material default and/or at substantial risk of material loss. A dash (“-”) listed under Projected Investor IRR is used to represent the performance state of an investment where there will be a loss of capital.'
  },
  dateInvested:
    'This is the date on which your funds were invested in the underlying project. It is the starting date used to calculate returns.',
  remainingCapitalBalance:
    'The remaining capital amount on your investment that is currently accruing interest.',
  maturityDate: {
    keep: 'The maturity date of your investment, subject to any applicable extension options.',
    earn: (
      <>
        This is the maturity date of the underlying investment. For debt and
        preferred equity investments this is typically the initial contractual
        initial maturity date. Such initial maturity dates are subject to
        extension options and extension by agreement of the parties. If the
        maturity date is extended, this date will be updated. Investments are
        generally prepayable before maturity.
        <br />
        <br />
        Certain investments included in the Earn category - funds and equity -
        do not have fixed contractual exit dates and such dates represent the
        estimated exit date only.
      </>
    )
  },
  exitDateAnticipated: (
    <>
      The target exit date of your investment is based on the Sponsor's business
      plan. This is an estimate only and actual exit date may vary significantly
      based on performance, business plan changes and market condition.
      <br />
      <br />
      The actual term of the Investment is impacted by a number of factors,
      including loan maturity, extension options, business plan and market
      conditions. Generally the actual timing of sale will be at the discretion
      of the Sponsor.
    </>
  ),
  effectiveInterestRate:
    'The annualized rate for this investment plus any increases from referral credits.',
  exitDate: 'The date that your investment is fully exited.',
  interestEarned:
    'The total amount of interest earned and accrued for your investment.',
  interestAccruing:
    'The Interest Earned is what will be paid at maturity should the investment be redeemed into another offering on the platform. Interest accruing will continue to accrue until the maturity date is reached.',
  // Also used for projected return
  rateOfReturn: (
    <>
      The forecasted annualized rate of return of this investment, inclusive of
      any distributions received to date, any accrued interest and preferred
      returns and any profit sharing.
      <br />
      <br />
      For debt and preferred equity investment this is typically a contractual
      (ie, fixed) rate of return. In certain cases, preferred equity investments
      may include a share of profits. Unpaid contractual returns will accrue and
      be paid from cash available after the payment of expenses and any senior
      creditors.
      <br />
      <br />
      For equity investments this is a forecast only and returns will fluctuate
      more significantly.
    </>
  ),
  return:
    'The Return on Investment (or ROI) which shows the total earned over your cost basis.',
  distributions:
    "The sum of all the distributions you've received for this investment, inclusive of both principal and any interest, preferred returns or dividends.",
  annualizedReturn:
    'The annualized return received on your investment. The annualized return is calculated using XIRR, a measure of the internal rate of return accounting for the timing of payments. The effective date of each payment is utilized in this calculation.'
};

export const tableHeaders = {
  annualizedReturn: (
    <span key="annualized-return" className={styles.infoIconWrap}>
      <span className={styles.th}>Annualized Return</span>
      <Tooltip
        className="info-icon-margin-left"
        infoIcon
        tooltipContent={tooltips.annualizedReturn}
        placement="top"
      />
    </span>
  ),
  dateInvested: (
    <span key="date-invested" className={styles.infoIconWrap}>
      <span className={styles.th}>Date Invested</span>
      <Tooltip
        className="info-icon-margin-left"
        infoIcon
        tooltipContent={tooltips.dateInvested}
        placement="top"
      />
    </span>
  ),
  distributions: (
    <span key="distributions" className={styles.infoIconWrap}>
      <span className={styles.th}>Distributions</span>
      <Tooltip
        className="info-icon-margin-left"
        infoIcon
        tooltipContent={tooltips.distributions}
        placement="top"
      />
    </span>
  ),
  effectiveInterestRate: (
    <span key="interest-rate" className={styles.infoIconWrap}>
      <span className={styles.th}>Effective Interest Rate</span>
      <Tooltip
        className="info-icon-margin-left"
        infoIcon
        tooltipContent={tooltips.effectiveInterestRate}
        placement="top"
      />
    </span>
  ),
  investment: 'Investment',
  investmentAmount: 'Investment Amount',
  performanceStatus: (
    <span key="performance-status" className={styles.infoIconWrap}>
      <span className={styles.th}>Performance Status</span>
      <Tooltip
        className="info-icon-margin-left"
        infoIcon
        tooltipContent={tooltips.performanceStatus}
        placement="top"
      />
    </span>
  )
};

const createDateRangeOptions = () => {
  const options = [
    {
      label: 'All Time',
      value: 'all'
    }
  ];
  const currentYear = new Date().getFullYear();
  const lastYear = currentYear - 1;
  const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);

  // Add quarters for the current year
  let quarterIndex = currentQuarter;
  while (quarterIndex > 0) {
    const startEndDates = utils.getQuarterStartEndDates(
      quarterIndex,
      currentYear
    );
    options.push({
      label: `Q${quarterIndex} ${currentYear}`,
      value: JSON.stringify({
        startDate: startEndDates[0],
        endDate: startEndDates[1]
      })
    });
    quarterIndex--;
  }

  // Add quarters from last year
  let lastYearQuarterIndex = 4;
  while (lastYearQuarterIndex > 0) {
    const startEndDates = utils.getQuarterStartEndDates(
      lastYearQuarterIndex,
      lastYear
    );
    options.push({
      label: `Q${lastYearQuarterIndex} ${lastYear}`,
      value: JSON.stringify({
        startDate: startEndDates[0],
        endDate: startEndDates[1]
      })
    });
    lastYearQuarterIndex--;
  }

  // Add remaining years going back to 2015 (first EM investment date)
  let fullYearIndex = lastYear;
  while (fullYearIndex >= 2015) {
    options.push({
      label: fullYearIndex.toString(),
      value: JSON.stringify({
        startDate: `${fullYearIndex}-01-01`,
        endDate: `${fullYearIndex}-12-31`
      })
    });
    fullYearIndex--;
  }

  return options;
};

export const dateRangeOptions = createDateRangeOptions();

export const effectiveDateDescription = (
  <>
    Effective Date
    <div className="subtext">
      Filter transactions by the date they are effective.
      <br />
      Recommended for performance calculations.
    </div>
  </>
);

export const transferDateDescription = (
  <>
    Transfer Date
    <div className="subtext">
      Filter transactions by the date they were transferred.
      <br />
      Recommended for seeing activity on a cash basis.
    </div>
  </>
);

export const baseColors = [
  '#00A9DF',
  '#694BCC',
  '#EB5424',
  '#FDB309',
  '#7CB6C8',
  '#DBF3FB',
  '#062164',
  '#006633'
];

export const offeringTypePlaceholderDataPoints = [
  {
    name: 'Preferred Equity',
    value: 0,
    percentage: 0,
    fill: '#ccc'
  },
  {
    name: 'Debt',
    value: 0,
    percentage: 0,
    fill: '#ccc'
  },
  {
    name: 'Equity',
    value: 0,
    percentage: 0,
    fill: '#ccc'
  },
  {
    name: 'Funds',
    value: 0,
    percentage: 0,
    fill: '#ccc'
  },
  {
    name: 'Short-term Note',
    value: 0,
    percentage: 0,
    fill: '#ccc'
  }
];

export const propertyTypePlaceholderDataPoints = [
  {
    name: 'Multifamily',
    value: 0,
    percentage: 0,
    fill: '#ccc'
  },
  {
    name: 'Hotel',
    value: 0,
    percentage: 0,
    fill: '#ccc'
  },
  {
    name: 'Office',
    value: 0,
    percentage: 0,
    fill: '#ccc'
  },
  {
    name: 'Mixed-Use',
    value: 0,
    percentage: 0,
    fill: '#ccc'
  }
];

export const placeholderDonutChartData = [
  {
    name: '',
    value: 1,
    fill: '#ccc',
    stroke: '#ccc'
  }
];

export const documentColumns = ['', 'Date', 'Document', 'Type', 'Year', 'View'];

export const documentOptions = [
  {
    label: 'All Documents',
    value: 'all'
  },
  {
    label: 'Tax Documents',
    value: 'tax_documents'
  },
  {
    label: 'Investment Documents',
    value: 'investment_documents'
  }
];

export const debtOfferingTypes = [
  'Senior Debt',
  'Mezzanine Debt',
  'Debt + Equity',
  'Debt + JV Equity'
];
export const prefEquityOfferingTypes = ['Preferred Equity', 'Senior Equity'];
