import { Button } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  loadBeneficialOwner,
  loadInvestmentAccount,
  updateBeneficialOwner
} from 'redux/actions/account';
import {
  BeneficialOwner,
  BeneficialOwnerDocument,
  SingleInvestmentAccount
} from 'types/api/account';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { formatDate, isComplete } from '../helpers';
import { BeneficialOwnerFields as BeneficialOwnerFieldsType } from '../types';
import { beneficialOwnerSchema } from '../validation';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';
import BeneficialOwnerFields from './BeneficialOwnerFields';
import BeneficialOwnerDocumentFields from './BeneficialOwnersDocumentFields';
import DocumentPreview from './DocumentPreview/DocumentPreview';

type Params = {
  id: string;
  reference_id: string;
};

interface Props {
  beneficialOwner: BeneficialOwner;
  beneficialOwners: BeneficialOwner[];
  dispatch: Dispatch;
  investmentAccount: SingleInvestmentAccount;
}

const BeneficialEdit = ({
  beneficialOwner,
  beneficialOwners,
  dispatch,
  investmentAccount
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const [idFieldsVisible, setIdFieldsVisible] = useState(false);

  const defaultValues = {
    beneficial_owner: beneficialOwner
      ? {
          ...beneficialOwner,
          beneficialOwners,
          currentBeneficialOwner: beneficialOwner,
          dob: formatDate(beneficialOwner.dob),
          percentage_ownership: beneficialOwner?.percentage_ownership
            ? parseFloat(beneficialOwner.percentage_ownership).toFixed(2)
            : ''
        }
      : {}
  };

  const hasSsn = beneficialOwner?.ssn !== null;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    setValue
  } = useForm<BeneficialOwnerFieldsType>({
    resolver: yupResolver(beneficialOwnerSchema),
    values: defaultValues
  });

  useEffect(() => {
    dispatch(loadInvestmentAccount(params.reference_id)).catch(error =>
      handleErrorResponse(navigate, error)
    );
    dispatch(loadBeneficialOwner(params.id))
      .then(res => {
        if (!res.beneficial_owner.document) setIdFieldsVisible(true);
      })
      .catch(error => handleErrorResponse(navigate, error));
  }, [dispatch, params, navigate]);

  const onSubmit = values => {
    if (!idFieldsVisible) delete values.beneficial_owner.document;

    return dispatch(updateBeneficialOwner(values))
      .then(() => {
        if (isComplete(investmentAccount)) {
          navigate(
            `/accounts/update/${investmentAccount.account_type}/${params.reference_id}`
          );
        } else {
          navigate(
            `/accounts/wizard/${investmentAccount.account_type}/beneficial_owners/${params.reference_id}`
          );
        }
      })
      .catch(error => throwReactHookFormSubmissionErrors(error, setError));
  };

  const toggleIdFieldVisibility = () => {
    if (idFieldsVisible)
      setValue(
        'beneficial_owner.document',
        (defaultValues.beneficial_owner as BeneficialOwner).document
      );
    else setValue('beneficial_owner.document', null);

    setIdFieldsVisible(!idFieldsVisible);
  };

  const isLoading = !investmentAccount || !beneficialOwner;

  return (
    <AccountWrap loading={isLoading} account={investmentAccount}>
      {!isLoading && (
        <>
          <h4 className="margin-top-0">Update Beneficial Owner</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BeneficialOwnerFields
              control={control}
              errors={errors}
              hasSsn={hasSsn}
            />
            <h5>Identification Document</h5>
            {beneficialOwner.document && beneficialOwner.document.name && (
              <div>
                <div className="text-label margin-x">
                  Identification Document
                </div>
                <DocumentPreview
                  document={beneficialOwner.document as BeneficialOwnerDocument}
                  documentType="Identification Document"
                />
              </div>
            )}
            {idFieldsVisible && (
              <BeneficialOwnerDocumentFields
                control={control}
                errors={errors}
                setValue={setValue}
                uploading={isSubmitting}
              />
            )}
            <button
              className="text-link"
              onClick={toggleIdFieldVisibility}
              type="button"
            >
              {idFieldsVisible
                ? 'Cancel Identification Document Update'
                : 'Update Identification Document'}
            </button>

            <div className="forwardBackButtonWrapCompact">
              <Button
                type="submit"
                className="float-right"
                style={{ width: 160 }}
                loading={isSubmitting}
              >
                Update
              </Button>
              <BackLink
                investmentAccount={investmentAccount}
                page="Edit Beneficial Owner"
              />
            </div>
          </form>
        </>
      )}
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    investmentAccount: state.account.investmentAccount,
    beneficialOwner: state.account.beneficialOwner,
    beneficialOwners: state.account.investmentAccount?.beneficial_owners
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(BeneficialEdit);
