export const GET_PAYMENTS = 'payments/GET_PAYMENTS';
export const GET_PAYMENTS_SUCCESS = 'payments/GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAIL = 'payments/GET_PAYMENTS_FAIL';
export const RESET_PAYMENTS = 'payments/RESET_PAYMENTS';

export function getPayments(params) {
  const queryString = new URLSearchParams(params).toString();
  return {
    types: [GET_PAYMENTS, GET_PAYMENTS_SUCCESS, GET_PAYMENTS_FAIL],
    promise: client =>
      client.get(`/mkt/transactions/payment_activity?${queryString}`)
  };
}

export function resetPayments() {
  return {
    type: RESET_PAYMENTS
  };
}
