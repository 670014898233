// extracted by mini-css-extract-plugin
var _1 = "DZtQGsjNk40YyEL3Plow";
var _2 = "J1maEGhPRFDnxKOOq8bw";
var _3 = "dzjivvvrIYsP1B0aOjIJ";
var _4 = "bLP7jSuJ2H3METrLRu2w";
var _5 = "sGnInOvzXuzTukjzwgrK";
var _6 = "_QFakRG0B0ctfjwfBd6K";
var _7 = "dMkFiy24XNwFYbV8cqUm";
var _8 = "zoEhsfVdsYOdJPZAzisx";
export { _1 as "currentYear", _2 as "docsCard", _3 as "documentsTab", _4 as "fileIcon", _5 as "noDocuments", _6 as "table", _7 as "titleLink", _8 as "white" }
