import {
  SubmitInviteSignupFormPayload,
  SubmitSignupFormPayload,
  UpdateSignupValuesPayload
} from 'types/actions/onboarding';

export const SUBMIT_SIGNUP_FORM = 'onboarding/SUBMIT_SIGNUP_FORM';
export const SUBMIT_SIGNUP_FORM_SUCCESS =
  'onboarding/SUBMIT_SIGNUP_FORM_SUCCESS';
export const SUBMIT_SIGNUP_FORM_FAIL = 'onboarding/SUBMIT_SIGNUP_FORM_FAIL';

export const INVITE_FORM = 'onboarding/INVITE_FORM';
export const INVITE_FORM_SUCCESS = 'onboarding/INVITE_FORM_SUCCESS';
export const INVITE_FORM_FAIL = 'onboarding/INVITE_FORM_FAIL';

export const INVITEE_DETAIL = 'onboarding/INVITEE_DETAIL';
export const INVITEE_DETAIL_SUCCESS = 'onboarding/INVITEE_DETAIL_SUCCESS';
export const INVITEE_DETAIL_FAIL = 'onboarding/INVITEE_DETAIL_FAIL';

export const UPDATE_SIGNUP_VALUES = 'onboarding/UPDATE_SIGNUP_VALUES';

export function invitationInit(token) {
  return {
    types: [INVITE_FORM, INVITE_FORM_SUCCESS, INVITE_FORM_FAIL],
    promise: client =>
      client.get(`/mkt/users/invitation/accept?invitation_token=${token}`)
  };
}

export function getInviteeData(token) {
  return {
    types: [INVITEE_DETAIL, INVITEE_DETAIL_SUCCESS, INVITEE_DETAIL_FAIL],
    promise: client => client.get(`/mkt/users/token_detail?id=${token}`)
  };
}

export function submitSignupForm(data: SubmitSignupFormPayload) {
  return {
    types: [
      SUBMIT_SIGNUP_FORM,
      SUBMIT_SIGNUP_FORM_SUCCESS,
      SUBMIT_SIGNUP_FORM_FAIL
    ],
    promise: client => client.post('/mkt/users/sign_up', { data })
  };
}

export function submitInviteSignupForm(data: SubmitInviteSignupFormPayload) {
  return {
    types: [
      SUBMIT_SIGNUP_FORM,
      SUBMIT_SIGNUP_FORM_SUCCESS,
      SUBMIT_SIGNUP_FORM_FAIL
    ],
    promise: client => client.put('/mkt/users/invitation', { data })
  };
}

export function updateSignupValues(values: UpdateSignupValuesPayload) {
  return {
    type: UPDATE_SIGNUP_VALUES,
    result: values
  };
}
