import { AnyAction } from 'redux';

import * as actions from '../actions/invest';
const initialState = {
  articles: {
    earn: [],
    grow: [],
    keep: []
    // taxDeferred: [],
  },
  fundedPagination: {},
  loadingActive: false,
  loadingFunded: false,
  loadingTrackRecord: false,
  offerings: {
    active: {
      earn: [],
      grow: [],
      keep: []
      // taxDeferred: [],
    },
    funded: [],
    // showTaxDeferredApproach: true,
    pillarOrder: []
  },
  trackRecord: {},
  trackRecordSummary: ''
};

export const setArticleData = response => {
  const pillars = ['earn', 'grow', 'keep'];
  const articles = { ...initialState.articles };

  pillars.forEach(pillar => {
    if (response.acf[pillar]) {
      const approachArticles = response.acf[pillar].map(articleField => {
        const cardType = articleField.card_type;

        // Custom URL and title
        if (cardType === 'link') {
          return {
            title: articleField.link_title,
            url: articleField.url
          };
        }
        // WordPress post object
        return {
          title:
            articleField.article_title || articleField?.article?.post_title,
          url: articleField?.article?.guid
        };
      });
      articles[pillar] = approachArticles;
    }
  });

  return articles;
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.LOAD_ACTIVE:
      return {
        ...state,
        loadingActive: true
      };

    case actions.LOAD_ACTIVE_SUCCESS:
      return {
        ...state,
        loadingActive: false,
        offerings: {
          ...state.offerings,
          active: {
            keep: action.result.active.keep || [],
            earn: action.result.active.earn || [],
            grow: action.result.active.grow || []
            // taxDeferred: action.result.active['Tax-Deferred'] || [],
          },
          // showTaxDeferredApproach: action.result.show_tax_deferred_approach,
          pillarOrder: action.result.pillar_order.split(',')
        }
      };

    case actions.LOAD_ACTIVE_FAIL:
      return {
        ...state,
        loadingActive: false
      };

    case actions.LOAD_FUNDED:
      return {
        ...state,
        loadingFunded: true
      };

    case actions.LOAD_FUNDED_SUCCESS:
      return {
        ...state,
        loadingFunded: false,
        offerings: {
          ...state.offerings,
          funded: action.result.funded
        },
        fundedPagination: {
          total: action.result.total,
          page: action.result.page,
          pageSize: action.result.page_size
        }
      };

    case actions.LOAD_FUNDED_FAIL:
      return {
        ...state,
        loadingFunded: false
      };

    case actions.LOAD_MARKETING_DATA:
      return {
        ...state,
        loadingArticles: true
      };

    case actions.LOAD_MARKETING_DATA_SUCCESS:
      return {
        ...state,
        loadingArticles: false,
        articles: setArticleData(action.result),
        trackRecordSummary: action.result.acf.track_record_summary
      };

    case actions.LOAD_MARKETING_DATA_FAIL:
      return {
        ...state,
        loadingArticles: false
      };

    case actions.LOAD_TRACK_RECORD_DATA:
      return {
        ...state,
        loadingTrackRecord: true
      };

    case actions.LOAD_TRACK_RECORD_DATA_SUCCESS:
      return {
        ...state,
        loadingTrackRecord: false,
        trackRecord: action.result
      };

    case actions.LOAD_TRACK_RECORD_DATA_FAIL:
      return {
        ...state,
        loadingTrackRecord: false
      };
    default:
      return state;
  }
};
