import { AnyAction } from 'redux';

import utils from '../../utilities/utils';
import * as actions from '../actions/bank-account';

const initialState = {
  loading: false,
  loadingToken: false,
  linkToken: null,
  submitting: false
};
export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.PROCESS_LINK_RESULTS:
    case actions.MICRO_DEPOSIT_VERIFICATION_FAILED:
      return {
        ...state,
        loading: true
      };

    case actions.LOAD_LINK_TOKEN:
    case actions.LOAD_LINK_TOKEN_UPDATE:
      return {
        ...state,
        loadingToken: true
      };

    case actions.LOAD_LINK_TOKEN_SUCCESS:
    case actions.LOAD_LINK_TOKEN_UPDATE_SUCCESS:
      utils.setLocalStorage('plaidLinkToken', action.result.link_token);
      return {
        ...state,
        loadingToken: false,
        linkToken: action.result.link_token
      };

    case actions.PROCESS_LINK_RESULTS_SUCCESS:
    case actions.MICRO_DEPOSIT_VERIFICATION_FAILED_SUCCESS:
    case actions.PROCESS_LINK_RESULTS_FAIL:
    case actions.MICRO_DEPOSIT_VERIFICATION_FAILED_FAIL:
      return {
        ...state,
        loading: false
      };

    case actions.LOAD_LINK_TOKEN_FAIL:
    case actions.LOAD_LINK_TOKEN_UPDATE_FAIL:
      return {
        ...state,
        loadingToken: false
      };

    case actions.CLEAR_LINK_TOKEN:
      return {
        ...state,
        linkToken: null
      };

    default:
      return state;
  }
};
