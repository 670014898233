import utils from './utils';

export const segmentTrackData = (offering, currentUser) => {
  if (offering && currentUser) {
    return {
      action: null,
      cardPosition: null,
      Title: offering.title,
      'Minimum Investment Amount': offering.minimum_investment_amount,
      'Offering Approach': offering.offering_approach,
      Pillar: offering.pillar,
      'Asset Class': offering.asset_class,
      'Asset Type': offering.asset_type,
      'Term Period': offering.term_period,
      Location: offering.alternate_location,
      'First Name': currentUser.track.first_name,
      'Last Name': currentUser.track.last_name,
      Stage: currentUser.track.stage,
      'Risk Tolerance': currentUser.track.risk_tolerance,
      'Level of Investing Experience':
        currentUser.track.level_of_investing_experience
    };
  }
  return {};
};

export const campaignParams = () => {
  const utmParams = utils.getCookie('utm_params');
  let utmParamsObject = null;

  if (utmParams && typeof utmParams === 'string')
    utmParamsObject = JSON.parse(unescape(utmParams));

  if (utmParamsObject) {
    return {
      name: utmParamsObject.utm_campaign,
      source: utmParamsObject.utm_source,
      medium: utmParamsObject.utm_medium,
      term: utmParamsObject.utm_term,
      content: utmParamsObject.utm_content
    };
  }

  return null;
};
