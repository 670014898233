import { AnyAction } from 'redux';

import * as actions from '../actions/investments';

const initialState = {
  sending: false,
  loading: false,
  loadingReinvestment: true,
  investment: null,
  reinvestment: null
};

export default (
  state = initialState,
  action: AnyAction = {
    type: null
  }
) => {
  switch (action.type) {
    case actions.SEND_PLEDGE_SUCCESS:
    case actions.DRIP_OPT_SUCCESS:
      return {
        ...state,
        investment: action.result,
        sending: false
      };

    case actions.FUND_INVESTMENT_SUCCESS:
    case actions.DRIP_OPT_FAIL:
    case actions.SEND_PLEDGE_FAIL:
    case actions.FUND_INVESTMENT_FAIL:
      return {
        ...state,
        sending: false
      };

    case actions.LOAD_REINVESTMENT:
      return {
        ...state,
        loadingReinvestment: true
      };

    case actions.LOAD_INVESTMENT:
    case actions.LOAD_FUNDING_OPTIONS:
      return {
        ...state,
        loading: true
      };

    case actions.LOAD_INVESTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        investment: action.result
      };

    case actions.LOAD_REINVESTMENT_SUCCESS:
      return {
        ...state,
        loadingReinvestment: false,
        reinvestment: action.result
      };

    case actions.LOAD_INVESTMENT_FAIL:
      return {
        ...state,
        loading: false
      };

    case actions.LOAD_REINVESTMENT_FAIL:
      return {
        ...state,
        loadingReinvestment: false
      };

    case actions.LOAD_FUNDING_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        fundingOptions: action.result
      };

    case actions.LOAD_FUNDING_OPTIONS_FAIL:
      return {
        ...state,
        loading: false
      };

    case actions.UPDATE_INVESTMENT:
    case actions.SEND_PLEDGE:
    case actions.SUBMIT_SIGN_AGREEMENT:
    case actions.FUND_INVESTMENT:
    case actions.DRIP_OPT:
      return {
        ...state,
        sending: true
      };

    case actions.UPDATE_INVESTMENT_FAIL:
    case actions.UPDATE_INVESTMENT_SUCCESS:
      return {
        ...state,
        sending: false,
        subscriptionUrl: action.result
      };

    case actions.SUBMIT_SIGN_AGREEMENT_SUCCESS:
    case actions.SUBMIT_SIGN_AGREEMENT_FAIL:
      return {
        ...state,
        sending: false
      };

    case actions.CLEAR_INVESTMENT:
      return {
        ...state,
        investment: null
      };

    default:
      return {
        ...state
      };
  }
};
