import { Image } from 'containers/Offerings/types';
import React from 'react';

import { getImageUrl } from '../../helpers';
import * as sharedStyles from '../../OfferingShared.module.scss';
import * as styles from './SingleImage.module.scss';

interface Props {
  image: Image;
}

const SingleImage = ({ image }: Props) => {
  const imageUrl = getImageUrl(image);

  return image ? (
    <div className={styles.imageWrap}>
      <img
        className={styles.image}
        src={imageUrl}
        alt={image.description}
        width={image.custom_width}
      />
      {!image.description_hidden && (
        <div className={sharedStyles.mediaDescription}>{image.description}</div>
      )}
    </div>
  ) : null;
};

export default SingleImage;
