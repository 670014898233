import { Button, Card } from '@equitymultiple/react-eui';
import IrImages from 'components/IrImages/IrImages';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';

import { setAdBlockerModalContent } from '../../redux/actions/modals';
import { Dispatch } from '../../types/redux';
import { adBlockerIntercomMessage } from '../../utilities/constants';
import * as styles from './Contact.module.scss';

interface Props {
  dispatch: Dispatch;
}

const Contact = ({ dispatch }: Props) => (
  <Card className={styles.contact}>
    <Row>
      <Col md={5}>
        <IrImages />
      </Col>
      <Col md={7}>
        <h3 className="margin-top-0 margin-x">Connect With Us</h3>
        <p>
          Our dedicated Investor Relations Team is standing by to help simplify
          your real estate investing process.
        </p>
        <Button
          onClick={() => {
            if (window.Intercom) window.Intercom('show');
            else dispatch(setAdBlockerModalContent(adBlockerIntercomMessage));
          }}
          style={{ width: 180 }}
          variant="outlined"
        >
          Send Message
        </Button>
      </Col>
    </Row>
  </Card>
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect()(Contact);
