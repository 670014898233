import {
  Button,
  EMLoadingIcon,
  ErrorBoundary
} from '@equitymultiple/react-eui';
import ArticleCard from 'components/ArticleCard/ArticleCard';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import {
  InvestArticles,
  InvestOfferings,
  InvestTrackRecord
} from 'types/api/offering';
import { User } from 'types/api/user';
import utils from 'utilities/utils';

import * as sharedStyles from '../../InvestShared.module.scss';
import { FundraiserOfferingCard, NoDealsCard, OfferingCard } from '..';
import * as styles from './OfferingList.module.scss';

export type Props = {
  articles: InvestArticles;
  loadMoreFundedOfferings?: () => void;
  loadingActive: boolean;
  loadingFunded: boolean;
  loadingTrackRecord: boolean;
  offerings: InvestOfferings;
  pillarOrder: Array<string>;
  showLoadMoreFundedOfferingsButton: boolean;
  trackRecord: InvestTrackRecord;
  trackRecordSummary: string;
  user: User;
};

const OfferingList = ({
  articles,
  loadingActive,
  loadingFunded,
  loadingTrackRecord,
  loadMoreFundedOfferings,
  offerings,
  pillarOrder,
  showLoadMoreFundedOfferingsButton,
  trackRecord,
  trackRecordSummary,
  user
}: Props) => {
  const [totalDistributions, setTotalDistributions] = useState<number>(0);
  const [totalOfferings, setTotalOfferings] = useState<number>(0);

  useEffect(() => {
    const calculateTotalDistributions = () => {
      const distributions = trackRecord.total_distributions;
      const newTotalDistributions = Object.keys(distributions || {}).reduce(
        (total: number, quarter: string) =>
          total + parseFloat(distributions[quarter]),
        0
      );
      setTotalDistributions(newTotalDistributions);
    };

    const calculateTotalOfferings = () => {
      const offeringsCount = Object.values(trackRecord.offerings || {}).reduce(
        (total, count) => total + count,
        0
      );
      setTotalOfferings(offeringsCount);
    };

    calculateTotalDistributions();
    calculateTotalOfferings();
  }, [trackRecord]);

  const getTotalCardLength = (pillar: string) => {
    const pillarOfferings = offerings.active[pillar] || [];
    const pillarArticles = articles[pillar] || [];
    return pillarOfferings.length + pillarArticles.length;
  };

  const getPillarOrder = () => {
    const finalPillarOrder = pillarOrder
      ? [...pillarOrder]
      : ['keep', 'earn', 'grow'];

    pillarOrder?.forEach(pillar => {
      if ((offerings.active[pillar]?.length || 0) === 0) {
        const pillarIndex = finalPillarOrder.indexOf(pillar);
        finalPillarOrder.push(finalPillarOrder.splice(pillarIndex, 1)[0]);
      }
    });

    return finalPillarOrder;
  };

  const getOfferingCardIndex = (pillar: string, indexWithinPillar: number) => {
    const localPillarOrder = getPillarOrder();
    const pillarIndex = localPillarOrder.indexOf(pillar);
    let totalIndex = indexWithinPillar;

    for (let i = 0; i < localPillarOrder.length; i++) {
      if (pillarIndex > i) {
        totalIndex += getTotalCardLength(localPillarOrder[i]);
      }
    }

    return totalIndex;
  };

  const renderPillar = (pillar: string) => {
    const hasOfferings = offerings.active[pillar]?.length > 0;
    const hasArticles = articles[pillar]?.length > 0;
    let heading = '';
    let subheading = '';

    switch (pillar) {
      case 'keep':
        heading = 'Keep';
        subheading =
          'A flexible cash management tool. Compelling rates, shortest terms.';
        break;
      case 'earn':
        heading = 'Earn';
        subheading = 'Investments focused on near-term income.';
        break;
      case 'grow':
        heading = 'Grow';
        subheading =
          'Investments focused on long-term growth and upside potential.';
        break;
      default:
        break;
    }

    return (
      <div className={styles.pillarSection} key={pillar}>
        <div className={`${styles.pillarHeading} ${styles[pillar]}`}>
          <h2>{heading}</h2>
          <div className={`${styles.subheading} subheading`}>{subheading}</div>
        </div>
        <ErrorBoundary name={`${heading} Offerings`}>
          <div className={styles.cardList}>
            <Row className={styles.offeringCardRow}>
              {process.env.REACT_APP_WEFUNDER_OFFERING_CARD === 'true' &&
                pillar === 'grow' && (
                  <Col lg={4} md={6} className={styles.offeringCardColumn}>
                    <FundraiserOfferingCard currentUser={user} />
                  </Col>
                )}
              {hasOfferings ? (
                offerings.active[pillar]?.map((closing, index) => (
                  <Col
                    lg={4}
                    md={6}
                    key={closing.id}
                    className={styles.offeringCardColumn}
                  >
                    <OfferingCard
                      currentUser={user}
                      closingData={closing.attributes}
                      closingId={closing.id}
                      cardIndex={getOfferingCardIndex(pillar, index + 1)}
                    />
                  </Col>
                ))
              ) : (
                <Col lg={4} md={6} className={styles.offeringCardColumn}>
                  <NoDealsCard approach={heading} />
                </Col>
              )}
              {hasArticles &&
                articles[pillar]?.map(article => (
                  <Col
                    lg={4}
                    md={6}
                    key={article.title}
                    className={styles.offeringCardColumn}
                  >
                    <ArticleCard
                      className={sharedStyles.investPageCard}
                      link={article.url}
                      title={article.title}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        </ErrorBoundary>
      </div>
    );
  };

  const renderActiveOfferings = () => {
    const pillars = getPillarOrder();
    return pillars.map(pillar => renderPillar(pillar));
  };

  const renderFundedLoadingCards = () => {
    const fundedCards = [];
    for (let i = 0; i < 6; i++) {
      fundedCards.push(
        <Col
          lg={4}
          md={6}
          key={`loading-funded-${i}`}
          className={styles.offeringCardColumn}
        >
          <OfferingCard loading />
        </Col>
      );
    }
    return fundedCards;
  };

  return (
    <div>
      <section className={styles.offeringsList}>
        {loadingActive ? (
          <EMLoadingIcon className={styles.cardLoader} />
        ) : (
          renderActiveOfferings()
        )}

        {!loadingTrackRecord && trackRecord.offerings && (
          <>
            <h2 className="margin-x margin-top-xxxx">Track Record</h2>
            <div
              className={`section-subheading ${styles.sectionSubheading} ${sharedStyles.sectionSubheading}`}
            >
              The cumulative performance of the EquityMultiple portfolio.
            </div>
            <div className={styles.cardList}>
              <Row>
                <Col xl={6} lg={7} md={6}>
                  <div
                    className={`${styles.trackRecordCard} ${sharedStyles.investPageCard}`}
                  >
                    <div className={styles.tag}>Track Record Overview</div>
                    <h3 className={styles.cardHeading}>By the Numbers</h3>
                    <div className={styles.metricsRow}>
                      <div>
                        <Row>
                          <Col lg={12} xs={6}>
                            <div className={styles.metric}>Total Raised</div>
                          </Col>
                          <Col lg={12} xs={6}>
                            <div className={styles.value}>
                              {utils.formatCurrency(
                                trackRecord.total_raised,
                                0
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col lg={12} xs={6}>
                            <div className={styles.metric}>Offerings</div>
                          </Col>
                          <Col lg={12} xs={6}>
                            <div className={styles.value}>{totalOfferings}</div>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col lg={12} xs={6}>
                            <div className={styles.metric}>
                              Total Distributions
                            </div>
                          </Col>
                          <Col lg={12} xs={6}>
                            <div className={styles.value}>
                              {utils.formatCurrency(totalDistributions, 0)}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={6}>
                  <ArticleCard
                    className={sharedStyles.investPageCard}
                    link={trackRecordSummary}
                    title="Track Record Summary"
                  />
                </Col>
              </Row>
            </div>
          </>
        )}

        <div className={styles.cardList}>
          <div className={sharedStyles.sectionHeadingBlock}>
            <div className={styles.fundedHeadingWrap}>
              <div>
                <h3 className="margin-top-xxx margin-x">
                  Recently Funded Offerings
                </h3>
                <div className={`${styles.subheading} subheading`}>
                  Recently funded offerings that are funded, cashflowing, or
                  exited.
                </div>
              </div>
              {showLoadMoreFundedOfferingsButton && (
                <Button
                  className={styles.loadMoreButton}
                  disabled={loadingFunded}
                  variant="outlined"
                  style={{ width: 'auto' }}
                  onClick={loadMoreFundedOfferings}
                >
                  {
                    <span>
                      <i className="fa fa-refresh" /> Refresh
                    </span>
                  }
                </Button>
              )}
            </div>
            <hr />
          </div>

          <ErrorBoundary name="Funded Offerings">
            <Row className={styles.offeringCardRow}>
              {loadingFunded ? (
                renderFundedLoadingCards()
              ) : (
                <>
                  {offerings.funded?.map(closing => (
                    <Col
                      lg={4}
                      md={6}
                      key={closing.id}
                      className={styles.offeringCardColumn}
                    >
                      <OfferingCard
                        inactive
                        closingId={closing.id}
                        closingData={closing.attributes}
                      />
                    </Col>
                  ))}
                </>
              )}
            </Row>
          </ErrorBoundary>
        </div>
      </section>
    </div>
  );
};

export default OfferingList;
