import './appSetup';
import '@equitymultiple/react-eui/dist/index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import createStore from './redux/create';
import AppRoutes from './routes';

const store = createStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store} key="provider">
    <AppRoutes store={store} />
  </Provider>
);
