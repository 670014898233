export default [
  'Accountant',
  'Actor',
  'Actuary',
  'Administrator',
  'Advertising Manager',
  'Aerospace Engineer',
  'Agent',
  'Agricultural Worker',
  'Air Traffic Controller',
  'Ambulance Officer',
  'Anesthesiologist',
  'Architect',
  'Art Director',
  'Artist',
  'Asset Manager',
  'Athlete',
  'Attorney',
  'Auditor',
  'Baker',
  'Barista',
  'Bartender',
  'Biotech Professional',
  'Board Member',
  'Broker',
  'Business Analyst',
  'Business Developer',
  'Business Manager',
  'Business Owner',
  'Cardiologist',
  'Chef',
  'Chemical Engineer',
  'Childcare Worker',
  'Chiropractor',
  'Civil Engineer',
  'Civil Servant',
  'Cleaner',
  'Commercial Banker',
  'Company Worker',
  'Computer Scientist',
  'Construction Manager',
  'Construction Worker',
  'Consultant',
  'Copywriter',
  'Courier',
  'Craftsperson',
  'Curator',
  'Customer Service Representative',
  'Data Analyst',
  'Data Scientist',
  'Dentist',
  'Dermatologist',
  'Designer',
  'Diplomat',
  'Driver',
  'Economist',
  'Electrical Engineer',
  'Electrician',
  'Emergency Physician',
  'Engineer',
  'Entrepreneur',
  'Equipment Operator',
  'Executive',
  'Executive Assistant',
  'Executive Manager',
  'Farmer',
  'Film Maker',
  'Financial Analyst',
  'Financial Consultant',
  'Firefighter',
  'Fisherman',
  'Fitness Trainer',
  'Flight Attendant',
  'Florist',
  'Freelancer',
  'Gardener',
  'Gastroenterologist',
  'General Contractor',
  'General Counsel',
  'Hair Stylist',
  'Home Care Aid',
  'Hospitality Worker',
  'Housekeeper',
  'Human Resources Professional',
  'Information Security Engineer',
  'Information Technology Professional',
  'Insurance Broker',
  'Investment Analyst',
  'Investment Banker',
  'Investment Manager',
  'Investor',
  'Investor Relationship Manager',
  'Journalist',
  'Laborer',
  'Librarian',
  'Lobbyist',
  'Maintenance Worker',
  'Manager',
  'Manufacturing Manager',
  'Manufacturing Worker',
  'Marketing Professional',
  'Mathematician',
  'Mechanic',
  'Mechanical Engineer',
  'Medical Doctor',
  'Medical Professional',
  'Medical Sales Professional',
  'Merchant',
  'Midwife',
  'Military Personnel',
  'Musician',
  'Nanny',
  'Network Engineer',
  'Nurse',
  'Office Clerk',
  'Office Worker',
  'Operations Manager',
  'Ophthalmologist',
  'Optician',
  'Optometrist',
  'Pediatrician',
  'Performance Artist',
  'Pharmacist',
  'Photographer',
  'Physical Therapist',
  'Pilot',
  'Podiatrist',
  'Police Officer',
  'Politician',
  'Portfolio Manager',
  'Product Manager',
  'Professor',
  'Project Manager',
  'Property Manager',
  'Psychiatrist',
  'Psychologist',
  'Psychotherapist',
  'Radiologist',
  'Railroad Engineer',
  'Real Estate Analyst',
  'Real Estate Broker',
  'Real Estate Developer',
  'Real Estate Professional',
  'Researcher',
  'Retail Banker',
  'Retail Employee',
  'Retiree',
  'Rigger',
  'Sailor',
  'Sales Director',
  'Sales Manager',
  'Salesperson',
  'Scientist',
  'Sea Captain',
  'Secretary',
  'Self-Employed',
  'Social Worker',
  'Software Architect',
  'Software Developer',
  'Software Sales',
  'Spokesperson',
  'Statistician',
  'Stay-At-Home Parent',
  'Student',
  'Surgeon',
  'Surveyor',
  'Systems Engineer',
  'Tailor',
  'Teacher',
  'Technician',
  'Trader',
  'Tradesperson',
  'Trainer',
  'Translator',
  'Truck Driver',
  'Tutor',
  'Unemployed',
  'Urologist',
  'Veterinarian',
  'Videographer',
  'Waiter',
  'Waste Collector',
  'Wealth Manager',
  'Writer',
  'Zoologist'
];
