import {
  Alert,
  Button,
  Card,
  RadioButton,
  Select
} from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { dripOpt } from 'redux/actions/investments';
import {
  loadInvestmentsInOffering,
  loadOffering
} from 'redux/actions/offerings';
import { Investment } from 'types/api/investment';
import { Offering } from 'types/api/offering';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';
import humane from 'utilities/humane';
import utils from 'utilities/utils';

import * as styles from './DebtFundElections.module.scss';

const LoadingSkeleton = () => (
  <div data-testid="loadingSkeleton">
    <h3 className={styles.heading}>
      <Skeleton width="60%" />
    </h3>
    <Row>
      <Col md={6}>
        <p>
          <Skeleton width="80%" />
        </p>
      </Col>
      <Col md={6}>
        <p>
          <Skeleton width="80%" />
        </p>
      </Col>
    </Row>
    <h5 className="margin-top-xx margin-xxx">
      <Skeleton width="50%" />
    </h5>
    <p className="margin-xxx">
      <Skeleton width={200} />
    </p>
    <p className="margin-xxx">
      <Skeleton width={200} />
    </p>
    <Skeleton className={styles.loadingButton} />
  </div>
);

const getInvestmentsEligibleForUpdate = investments =>
  investments.filter(
    investment =>
      investment.status === 'funded' &&
      ['active', 'funded', 'cashflowing'].includes(investment.closing.stage)
  );

const getDateInvestedText = investment =>
  investment.date_invested
    ? utils.dateFormat(investment.date_invested)
    : 'Coming Soon';

type Params = {
  offering_id: string;
};

type Props = {
  dispatch: Dispatch;
  loading: boolean;
  offering: Offering;
  submitting: false;
};

const DebtFundElections = ({
  dispatch,
  loading,
  offering,
  submitting
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const [optIn, setOptIn] = useState(true);
  const [step, setStep] = useState(1);
  const [updatableInvestments, setUpdatableInvestments] = useState([]);
  const [selectedInvestment, setSelectedInvestment] = useState<Investment>();

  const investmentOptions = updatableInvestments?.map(investment => ({
    label: `${utils.formatCurrency(
      investment.amount,
      0
    )} - (Date Invested: ${getDateInvestedText(investment)})`,
    value: investment.id
  }));

  let currentlyOptedIn;
  let nextPaymentOn;
  let dateInvested;

  if (selectedInvestment) {
    currentlyOptedIn = selectedInvestment.drip_opt_in;
    nextPaymentOn = utils.dateFormat(
      selectedInvestment.closing.next_payment_on
    );
    dateInvested = getDateInvestedText(selectedInvestment);
  }

  useEffect(() => {
    document.title = 'Investment Elections | EquityMultiple';

    if (!params.offering_id) {
      navigate('/');
    }

    dispatch(loadOffering(params.offering_id))
      .then(offeringRes => {
        if (!offeringRes.has_drip) navigate('/');
      })
      .catch(error => handleErrorResponse(navigate, error));

    dispatch(loadInvestmentsInOffering(params.offering_id))
      .then(investmentsRes => {
        const eligibleInvestments =
          getInvestmentsEligibleForUpdate(investmentsRes);

        if (!eligibleInvestments.length) {
          navigate('/');
        }

        setUpdatableInvestments(eligibleInvestments);
      })
      .catch(error => handleErrorResponse(navigate, error));
  }, [dispatch, params.offering_id, navigate]);

  useEffect(() => {
    if (selectedInvestment) setOptIn(selectedInvestment.drip_opt_in);
  }, [selectedInvestment]);

  const handleSubmit = () => {
    dispatch(dripOpt(selectedInvestment.id, { drip_opt_in: optIn }))
      .then(() => {
        setStep(2);
      })
      .catch(err => {
        humane.error(err.body.message);
      });
  };

  return (
    <Container className="container-narrow">
      <Card>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <h3 className={styles.heading}>
              Your Investment in {offering.title}
            </h3>
            <Row className="margin-xx">
              <Col md={8}>
                <Select
                  id="investmentSelect"
                  options={investmentOptions}
                  onChange={value => {
                    setSelectedInvestment(
                      updatableInvestments.find(
                        investment => investment.id === value
                      )
                    );
                  }}
                  value={selectedInvestment?.id}
                  label="Select Investment"
                  data-testid="investmentSelect"
                />
              </Col>
            </Row>

            {selectedInvestment && (
              <>
                {step === 1 ? (
                  <>
                    <Row>
                      <Col md={6}>
                        <div className="text-label">Date Invested</div>
                        <p>{dateInvested}</p>
                      </Col>
                      {!currentlyOptedIn && (
                        <Col md={6}>
                          <div className="text-label">
                            Current Distribution Period
                          </div>
                          <p>{nextPaymentOn}</p>
                        </Col>
                      )}
                    </Row>
                    <h5 className="margin-top-xx">
                      You are currently{' '}
                      {currentlyOptedIn ? 'opted in to' : 'opted out of'} DRIP
                    </h5>
                    <button
                      type="button"
                      onClick={() => {
                        setOptIn(true);
                      }}
                      className={`${styles.radioButton} ${styles.optIn} ${
                        optIn ? styles.selected : ''
                      }`}
                    >
                      <RadioButton
                        name="optIn"
                        id="optInTrue"
                        label="Opt In"
                        checked={optIn}
                        onChange={() => {}}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setOptIn(false);
                      }}
                      className={`${styles.radioButton} ${styles.optOut}`}
                    >
                      <RadioButton
                        name="optOut"
                        id="optInFalse"
                        label="Opt Out"
                        checked={!optIn}
                        onChange={() => {}}
                      />
                    </button>
                    <Button
                      className={styles.submitButton}
                      loading={submitting}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </>
                ) : (
                  <>
                    {optIn ? (
                      <Alert type="positive">
                        Thanks for opting in to the Dividend Reinvestment Plan
                        (DRIP). Your election has been recorded, and your
                        distribution payment scheduled for {nextPaymentOn} will
                        be reinvested into the fund.
                      </Alert>
                    ) : (
                      <>
                        <Alert type="neutral">
                          You've successfully opted out of the Dividend
                          Reinvestment Plan (DRIP).
                        </Alert>
                        <p>
                          If your election was made 60 days after the previous
                          distribution period, you will receive a distribution
                          payment on {nextPaymentOn}.{' '}
                          <strong>
                            If your election was made 30 days or less prior to{' '}
                            {nextPaymentOn}, then you will receive a
                            distribution payment next quarter.
                          </strong>{' '}
                          You may opt back into the DRIP program at any time. To
                          reinvest your distribution payment for the current
                          distribution period, you may opt back in prior to{' '}
                          {nextPaymentOn}.
                        </p>
                        <p>
                          As a reminder, in order to opt out of DRIP for the
                          current distribution period, you must make your
                          election at least 30 days prior to that period's
                          payment date.
                        </p>
                      </>
                    )}

                    <div className={styles.buttonWrap}>
                      <Button
                        variant="ghost"
                        onClick={() => {
                          setStep(1);
                          setSelectedInvestment(null);
                        }}
                      >
                        Back
                      </Button>
                      <Button wrapper={<Link to="/" />}>Okay</Button>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};

function mapStateToProps(store) {
  return {
    offering: store.offerings.offeringData,
    loading: store.offerings.loading || store.offerings.loadingOffering,
    submitting: store.investments.sending
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(DebtFundElections);
