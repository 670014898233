import React, { useEffect } from 'react';
import { PlaidLinkOptionsWithLinkToken, usePlaidLink } from 'react-plaid-link';

interface Props {
  onReady: () => void;
  options: PlaidLinkOptionsWithLinkToken;
}

const PlaidLinkCore = ({ options, onReady }: Props) => {
  const { token, onExit, onSuccess, receivedRedirectUri } = options;
  const { open, ready } = usePlaidLink({
    token,
    onExit,
    onSuccess,
    receivedRedirectUri
  });

  useEffect(() => {
    if (ready) {
      onReady();
      open();
    }
  }, [ready, open, onReady]);

  return <></>;
};

export default PlaidLinkCore;
