export const labels = [
  { label: 'Investment Update', value: 'investment_update' },
  { label: 'Tax', value: 'tax' },
  { label: 'General', value: 'general' }
];

export const statusOptions = [
  { label: 'Drafts', value: 'draft' },
  { label: 'Published', value: 'published' }
];
