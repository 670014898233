import {
  Button,
  Card,
  Checkbox,
  MultiSelectFilter,
  Table,
  Tooltip
} from '@equitymultiple/react-eui';
import jpg from 'images/icons/jpg.svg?url';
import pdf from 'images/icons/pdf.svg?url';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TaxDocument } from 'types/api/document';
import utils from 'utilities/utils';

import * as styles from './TaxDocuments.module.scss';
import {
  compileMetricsForTaxDocumentsPage,
  constructTaxDocumentSelection,
  filterDocuments,
  getDocumentsByOfferingIdAndYear,
  sortAndFilterDocuments,
  taxDocumentTableHeaders
} from './TaxDocumentsHelpers';

interface Props {
  documents: TaxDocument[];
  listOfSelectedDocuments: number[];
  metrics: {
    available: number;
    totalDocs: number;
  };
  setSelectedDocumentsForDownload: (input: number | number[]) => void;
  userHasOfferingTaxStatuses: boolean;
}

const TaxDocuments = ({
  documents,
  listOfSelectedDocuments,
  setSelectedDocumentsForDownload,
  userHasOfferingTaxStatuses,
  metrics
}: Props) => {
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    const newSelectOptions = constructTaxDocumentSelection(documents);
    const newSelectedYears = [];

    const selectedOfferingId = window.location.href.split('offering_id=')[1];

    if (selectedOfferingId) {
      // Default to most recent year if coming from tax tracker
      newSelectedYears.push(newSelectOptions[0].value);
      const selectedDocuments = getDocumentsByOfferingIdAndYear(
        documents,
        selectedOfferingId,
        newSelectedYears
      );
      const seletectedDocumentIds = selectedDocuments.map(
        document => document.id
      );
      setSelectedDocumentsForDownload(seletectedDocumentIds);
    }

    setSelectOptions(newSelectOptions);
    setSelectedYears(newSelectedYears);

    return () => {
      setSelectedDocumentsForDownload([]);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const manageDocumentSelection = inputId => {
    setSelectedDocumentsForDownload(inputId);
  };

  const organizeAndSortDocuments = () =>
    sortAndFilterDocuments([...documents], selectedYears);

  const checkIfAllDocsAreSelected = () => {
    const selectedDocsLength = listOfSelectedDocuments.length;
    const docsInYearLength = filterDocuments(documents, selectedYears).length;
    return docsInYearLength !== 0 && docsInYearLength === selectedDocsLength;
  };

  const selectByYear = filter => {
    const years = filter[0];
    if (!utils.arrayEquals(years, selectedYears)) {
      setSelectedDocumentsForDownload([]);
      setSelectedYears(years);
    }
  };

  const getColumnHeaders = () => {
    return taxDocumentTableHeaders.map(column => (
      <div
        key={column.value}
        className={column.value === 'download' ? styles.downloadHeading : ''}
      >
        {column.value === 'select' ? (
          <Checkbox
            data-testid="selectAllForDownload"
            id={column.value}
            label=""
            onChange={() => {
              manageDocumentSelection(
                filterDocuments(documents, selectedYears).map(
                  document => document.id
                )
              );
            }}
            checked={checkIfAllDocsAreSelected()}
          />
        ) : (
          column.label
        )}
      </div>
    ));
  };

  const getRows = documentsToDisplay => {
    return documentsToDisplay.map(document => {
      return {
        cells: [
          <div className={styles.selectDoc} key={document.id}>
            <Checkbox
              id={document.id}
              label=""
              onChange={() => {
                manageDocumentSelection(document.id);
              }}
              checked={listOfSelectedDocuments.indexOf(document.id) !== -1}
            />
          </div>,
          {
            value: (
              <div key="offering">
                <div className={styles.offeringName}>{document.title}</div>
                {!document.is_stn && (
                  <div className={styles.entityName}>
                    {document.offering_entity_name}
                  </div>
                )}
              </div>
            ),
            sortableValue: document.title
          },
          <div key="account">{document.investment_account_name}</div>,
          <div key="type">{document.type}</div>,
          <div key="year">{document.year}</div>,
          <span key="warning">
            <>
              <Tooltip
                disableHoverListener={
                  !document.offering_tax_status_under_review
                }
                disableFocusListener={
                  !document.offering_tax_status_under_review
                }
                disableTouchListener={
                  !document.offering_tax_status_under_review
                }
                tooltipContent={
                  <span>
                    An error was identified in this tax document.{' '}
                    <Link key="activity" to="/activity">
                      View Activity to learn more.
                    </Link>
                  </span>
                }
                placement="top"
              >
                <a
                  href={`/mkt/documents/download?ids=${document.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt=""
                    src={document.image ? jpg : pdf}
                    className={styles.fileIcon}
                  />
                </a>
              </Tooltip>
              <div className={styles.mobile}>
                <Button
                  href={`/mkt/documents/download?ids=${document.id}`}
                  style={{ width: 160 }}
                  className={
                    document.offering_tax_status_under_review
                      ? styles.error
                      : ''
                  }
                >
                  Download
                </Button>
              </div>
            </>
          </span>
        ]
      };
    });
  };

  const showTaxYearFilters = selectOptions.length > 1;

  return (
    <>
      <Row>
        {showTaxYearFilters && (
          <Col lg={2}>
            <div className="stickySidebarWrap">
              <MultiSelectFilter
                filterGroups={[
                  {
                    options: selectOptions,
                    selectedOptions: [...selectedYears],
                    hasClearAll: true,
                    title: 'Tax Year'
                  }
                ]}
                onChange={selectByYear}
                mobileLayoutEnabled={false}
              />
            </div>
          </Col>
        )}
        <Col lg={showTaxYearFilters ? 10 : 12}>
          <Card className={styles.card}>
            <div>
              <div>
                <h4 className="margin-top-0 margin-xx">Tax Documents</h4>
                {userHasOfferingTaxStatuses &&
                  utils.arrayEquals(
                    [selectOptions[0]?.value],
                    selectedYears
                  ) && (
                    <div className={styles.taxSummaryInfo}>
                      <span className="content">
                        {metrics.available} of {metrics.totalDocs} investments
                        have tax documents available for {selectedYears[0]}.{' '}
                        <br />
                        To learn more about the statuses and activity of your{' '}
                        {selectedYears[0]} documents view your{' '}
                        <Link to="/tax_tracker">Tax Tracker</Link>.
                      </span>
                    </div>
                  )}
              </div>
              <Table
                data-testid="taxDocumentsTable"
                columnHeaders={getColumnHeaders()}
                rows={getRows(organizeAndSortDocuments())}
                allowSorting={[1, 2, 3, 4]}
                className={styles.table}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(store) {
  return {
    taxDocumentLabels: store.documents.documents.tax_document_labels,
    userHasOfferingTaxStatuses:
      store.offeringTaxStatuses.offeringTaxStatuses.length > 0,
    metrics: compileMetricsForTaxDocumentsPage(
      store.offeringTaxStatuses.offeringTaxStatuses
    )
  };
}
export default connect(mapStateToProps)(TaxDocuments);
