import React from 'react';
import EmAnalytics from 'utilities/em_analytics';

import * as styles from './RecoveryCodes.module.scss';

interface Props {
  recoveryCodes: string[];
  showHeading?: boolean;
}

const RecoveryCodes = ({ recoveryCodes, showHeading }: Props) => {
  const downloadRecoveryCodes = () => {
    let file = '';

    recoveryCodes.forEach(code => {
      file += code + '\r\n';
    });

    const link = document.createElement('a');

    link.href = URL.createObjectURL(
      new Blob([file], {
        type: 'text/plain'
      })
    );

    link.download = 'equitymultiple-recovery-codes.txt';
    link.click();

    EmAnalytics.track('SMS 2FA Recovery Token Download', 'Onboarding');
  };

  return (
    <>
      {showHeading && <h3>Recovery Codes</h3>}
      <p className="margin-xx">
        To ensure you're always in control, we recommend downloading your
        recovery codes. These unique codes act as a digital lifeline, allowing
        you to regain access to your account in case you lose your device or
        face any authentication issues.
      </p>
      <div className={styles.recoveryCodes}>
        {recoveryCodes?.map(code => <div key={code}>{code}</div>)}
      </div>
      <button
        type="button"
        className={`text-link ${styles.downloadButton}`}
        onClick={downloadRecoveryCodes}
      >
        <i className="fa fa-download" />
        Download
      </button>
    </>
  );
};

export default RecoveryCodes;
