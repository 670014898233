import { Button, Checkbox, Input, Select } from '@equitymultiple/react-eui';
import { stateOptions } from 'containers/Accounts/contents';
import { isComplete } from 'containers/Accounts/helpers';
import { AccountHolder2Fields } from 'containers/Accounts/types';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { SingleInvestmentAccount } from 'types/api/account';
import { setCheckboxFieldProps, setFieldProps } from 'utilities/formHelpers';
import { phoneMask } from 'utilities/masks';

import BackLink from '../BackLink/BackLink';

interface Props {
  backLinkRoute?: string;
  control: Control<AccountHolder2Fields>;
  errors: FieldErrors<AccountHolder2Fields>;
  investmentAccount?: SingleInvestmentAccount;
  sameAddress: boolean;
  submitting: boolean;
}

const JointSecondaryHolderFields = ({
  backLinkRoute,
  investmentAccount,
  sameAddress,
  submitting,
  control,
  errors
}: Props) => {
  useEffect(() => {
    document.title = 'My Accounts | Joint Account Secondary Account Holder';
  }, []);

  return (
    <>
      <h4 className="margin-top-0">
        Tell Us About Your Secondary Account Holder
      </h4>
      <p className="margin-xx">
        We'll collect your secondary account holder's personal information. All
        of the information you provide is kept private and secure.
      </p>
      <Row className="margin-row">
        <Col md={6}>
          <Controller
            name="investment_account.account_holder_2_first_name"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Legal First Name"
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="investment_account.account_holder_2_last_name"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Legal Last Name"
              />
            )}
          />
        </Col>
      </Row>

      <Row className="margin-row">
        <Col md={6}>
          <Controller
            name="investment_account.account_holder_2_phone"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                mask={phoneMask}
                label="Phone"
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="investment_account.account_holder_2_email"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Email" />
            )}
          />
        </Col>
      </Row>

      <h5 className="margin-x">
        What is the secondary account holder's address?
      </h5>

      <Controller
        name="investment_account.account_holder_2_address_is_same"
        control={control}
        render={({ field }) => (
          <Checkbox
            {...setCheckboxFieldProps(field, errors)}
            label="The secondary account holder associated with this joint account has the same address"
            className="margin-x"
          />
        )}
      />

      {!sameAddress && (
        <div>
          <Row className="margin-row">
            <Col md={6}>
              <Controller
                name="investment_account.account_holder_2_address"
                control={control}
                render={({ field }) => (
                  <Input {...setFieldProps(field, errors)} label="Address" />
                )}
              />
            </Col>
            <Col md={6}>
              <Controller
                name="investment_account.account_holder_2_address2"
                control={control}
                render={({ field }) => (
                  <Input {...setFieldProps(field, errors)} label="Address 2" />
                )}
              />
            </Col>
          </Row>
          <Row className="margin-row">
            <Col md={4}>
              <Controller
                name="investment_account.account_holder_2_city"
                control={control}
                render={({ field }) => (
                  <Input {...setFieldProps(field, errors)} label="City" />
                )}
              />
            </Col>
            <Col md={4} className="overflow-visible">
              <Controller
                name="investment_account.account_holder_2_region"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    label="State"
                    options={stateOptions}
                  />
                )}
              />
            </Col>
            <Col md={4}>
              <Controller
                name="investment_account.account_holder_2_postal_code"
                control={control}
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    mask="99999"
                    label="Postal Code"
                  />
                )}
              />
            </Col>
          </Row>
        </div>
      )}

      <div className="forwardBackButtonWrapCompact">
        <Button
          type="submit"
          loading={submitting}
          style={{ width: 160 }}
          variant="orange"
        >
          {isComplete(investmentAccount) ? 'Update' : 'Continue'}
        </Button>
        <BackLink
          route={backLinkRoute}
          investmentAccount={investmentAccount}
          page="Secondary Holder"
        />
      </div>
    </>
  );
};

export default JointSecondaryHolderFields;
