import { loggedOutPaths } from './constants';

interface RemainingSessionTimeResponse {
  time_left: number;
}

let countDownInterval: NodeJS.Timeout;

export const isAuthenticatedPath = (path: string) => {
  if (loggedOutPaths.includes(path)) {
    return false;
  }

  if (path.indexOf('/users/signup') > -1) {
    return ['continue', 'phone', 'discover', 'email_verify'].some(subPath =>
      path.includes(subPath)
    );
  }

  return true;
};

const closeSessionModal = () => {
  document.getElementById('session-modal').classList.remove('open');
  document.getElementById('session-time').innerHTML = '5:00';
};

const openSessionModal = () => {
  const sessionModal = document.getElementById('session-modal');
  if (!sessionModal.classList.contains('open')) {
    sessionModal.classList.add('open');
  }
};

export const signOutUser = () => {
  if (window.Intercom) window.Intercom('shutdown');
  location.replace('/mkt/users/sign_out?session=timeout');
};

window.makeActiveSessionRequest = () => {
  fetch('/mkt/users/me')
    .then(res => res.json)
    .then(() => {
      closeSessionModal();
      if (countDownInterval) {
        clearInterval(countDownInterval);
      }
    })
    .catch(() => {
      signOutUser();
    });
};

const startSessionTimer = (duration: number) => {
  openSessionModal();

  if (countDownInterval) {
    clearInterval(countDownInterval);
  }

  let timer = duration - 5;

  countDownInterval = setInterval(function () {
    const minutes = Math.floor(timer / 60);
    let seconds = (timer % 60).toString();
    seconds = parseInt(seconds) < 10 ? `0${seconds}` : seconds.toString();

    if (--timer >= 0) {
      document.getElementById('session-time').innerHTML =
        minutes + ':' + seconds;
    } else {
      closeSessionModal();
      signOutUser();
    }
  }, 1000);
};

export const updateSessionTimer = (data: string | number, path: string) => {
  if (!isAuthenticatedPath(path)) {
    if (countDownInterval) {
      clearInterval(countDownInterval);
    }
    return;
  }

  if (data === 'signOutUser') {
    closeSessionModal();
    signOutUser();
  } else if (data === 'activeSession') {
    closeSessionModal();
  } else {
    startSessionTimer(data as number);
  }
};

const worker = () => {
  let timerInterval: NodeJS.Timeout;

  self.onmessage = ({ data }) => {
    const appHost = data.host;

    if (timerInterval) {
      clearInterval(timerInterval);
    }

    timerInterval = setInterval(function () {
      fetch(appHost + '/mkt/users/remaining_session_time')
        .then(res => res.json())
        .then((res: RemainingSessionTimeResponse) => {
          const { time_left: timeLeft } = res;
          if (timeLeft < 5) {
            self.postMessage('signOutUser');
            clearInterval(timerInterval);
          } else if (timeLeft <= 300) {
            self.postMessage(timeLeft);
          } else {
            self.postMessage('activeSession');
          }
        });
    }, 60000);
  };
};

let code = worker.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });
export const workerScript = URL.createObjectURL(blob);
