import React from 'react';

import GoogleMap from '../../../components/GoogleMap';

interface Props {
  locations: {
    color: string;
    label: string;
    lat: number;
    lng: number;
    name: string;
  }[];
  zoom: number;
}

const Map = ({ locations, zoom }: Props) => {
  const points = [];

  if (locations.length > 0) {
    locations.forEach(loc => {
      points.push({
        latitude: loc.lat,
        longitude: loc.lng,
        title: loc.name,
        pin_color: loc.color,
        pin_label: loc.label
      });
    });
  }

  return locations.length > 0 ? (
    <div>
      <GoogleMap
        latitude={locations[0].lat}
        longitude={locations[0].lng}
        zoom={zoom || 10}
        width="100%"
        height={500}
        points={points}
      />
    </div>
  ) : null;
};

export default Map;
