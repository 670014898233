import { ChartDataPoint } from 'containers/Portfolio/types';
import React from 'react';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';

interface Props {
  dataPoints: ChartDataPoint[];
  width?: string | number;
}

const DonutChart = ({ dataPoints, width }: Props) => {
  return (
    <ResponsiveContainer width={width || '100%'} aspect={1}>
      <PieChart>
        <Pie
          data={dataPoints}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius="50%"
          outerRadius="100%"
          isAnimationActive={false}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
