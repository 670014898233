export const LOAD_LINK_TOKEN = 'bank-account/LOAD_LINK_TOKEN';
export const LOAD_LINK_TOKEN_SUCCESS = 'bank-account/LOAD_LINK_TOKEN_SUCCESS';
export const LOAD_LINK_TOKEN_FAIL = 'bank-account/LOAD_LINK_TOKEN_FAIL';
export const LOAD_LINK_TOKEN_UPDATE = 'bank-account/LOAD_LINK_TOKEN_UPDATE';
export const LOAD_LINK_TOKEN_UPDATE_SUCCESS =
  'bank-account/LOAD_LINK_TOKEN_UPDATE_SUCCESS';
export const LOAD_LINK_TOKEN_UPDATE_FAIL =
  'bank-account/LOAD_LINK_TOKEN_UPDATE_FAIL';
export const CLEAR_LINK_TOKEN = 'bank-account/CLEAR_LINK_TOKEN';
export const PROCESS_LINK_RESULTS = 'bank-account/PROCESS_LINK_RESULTS';
export const PROCESS_LINK_RESULTS_SUCCESS =
  'bank-account/PROCESS_LINK_RESULTS_SUCCESS';
export const PROCESS_LINK_RESULTS_FAIL =
  'bank-account/PROCESS_LINK_RESULTS_FAIL';
export const MICRO_DEPOSIT_VERIFICATION_FAILED =
  'bank-account/MICRO_DEPOSIT_VERIFICATION_FAILED';
export const MICRO_DEPOSIT_VERIFICATION_FAILED_SUCCESS =
  'bank-account/MICRO_DEPOSIT_VERIFICATION_FAILED_SUCCESS';
export const MICRO_DEPOSIT_VERIFICATION_FAILED_FAIL =
  'bank-account/MICRO_DEPOSIT_VERIFICATION_FAILED_FAIL';

export function loadLinkToken(investmentAccountId) {
  return {
    types: [LOAD_LINK_TOKEN, LOAD_LINK_TOKEN_SUCCESS, LOAD_LINK_TOKEN_FAIL],
    promise: client =>
      client.get(`/mkt/accounts/${investmentAccountId}/link_token`)
  };
}

export function loadLinkTokenUpdate(investmentAccountId, bankAccountId) {
  return {
    types: [
      LOAD_LINK_TOKEN_UPDATE,
      LOAD_LINK_TOKEN_UPDATE_SUCCESS,
      LOAD_LINK_TOKEN_UPDATE_FAIL
    ],
    promise: client =>
      client.post(`/mkt/accounts/${investmentAccountId}/link_token_update`, {
        data: {
          bank_account_id: bankAccountId
        }
      })
  };
}

export function clearLinkToken() {
  return {
    type: CLEAR_LINK_TOKEN
  };
}

export function processLinkResults(investmentAccountId, data) {
  return {
    types: [
      PROCESS_LINK_RESULTS,
      PROCESS_LINK_RESULTS_SUCCESS,
      PROCESS_LINK_RESULTS_FAIL
    ],
    promise: client =>
      client.post(`/mkt/accounts/${investmentAccountId}/process_link_results`, {
        data
      })
  };
}

export function microDepositVerificationFailed(investmentAccountId, data) {
  return {
    types: [
      MICRO_DEPOSIT_VERIFICATION_FAILED,
      MICRO_DEPOSIT_VERIFICATION_FAILED_SUCCESS,
      MICRO_DEPOSIT_VERIFICATION_FAILED_FAIL
    ],
    promise: client =>
      client.post(
        `/mkt/accounts/${investmentAccountId}/micro_deposit_verification_failed`,
        { data }
      )
  };
}
